import * as React from "react";
import {BusinessDto} from "../../gen/client";
import "./OrdersDetailsDrawer.scss";

interface OrdersProps {
  business: BusinessDto;
}

export default function DashboardOrders({business}: OrdersProps) {
  // const {t} = useTranslation();
  //
  // const [tab, setTab] = useQueryString<OrderListTab>("tab", TODAY);
  //
  // const newOrderCount = useSelector((state: RootState) => state.app.newOrderCount);
  // const inProgressOrderCount = useSelector((state: RootState) => state.app.inProgressOrderCount);
  // const [isLoading, setLoading] = useState(false);
  //
  // const [orders, setOrders] = useState([] as OrderDto[]);
  // const [active, setActive] = useState({} as OrderDto);
  //
  // const [isOrderLoading, setOrderLoading] = useState(false);
  // const [isAcceptOrderModal, setAcceptOrderModal] = useState(false);
  // const [isEstimateOrderModal, setEstimateOrderModal] = useState(false);
  // const [isDetails, setDetails] = useState(false);
  //
  // const fetchOrders = useCallback((tab: OrderListTab) => {
  //   if (!business.id) return;
  //
  //   setLoading(true);
  //   BusinessApi.getOrders(business.id, tab).then((resp) => {
  //     setOrders(resp.data);
  //     setLoading(false);
  //   }).catch((err: AxiosError) => {
  //     setLoading(false);
  //     handleServerError(err);
  //   });
  // }, [business]);
  //
  // useEffect(() => stopNewOrderSound());
  //
  // useEffect(() => {
  //   setPageTitle(t("orders"), newOrderCount);
  // }, [t, newOrderCount]);
  //
  // useEffect(() => fetchOrders(tab as OrderListTab), [tab, fetchOrders]);
  //
  // function doAction(order: OrderDto, estimatedTime: string = "") {
  //   if (isAcceptOrderModal) {
  //     setAcceptOrderModal(false);
  //   }
  //
  //   const next = getNextStatus(order.status);
  //   const old = order.status;
  //
  //   const status = wrapStatusDto(next);
  //   OrderApi.updateOrderStatus(order.id, {status, estimatedTime}).then(() => {
  //     order.status = next;
  //     onStatusChange(order.id, next, old, estimatedTime);
  //   }).catch(handleServerError);
  // }
  //
  // function onAction(order: OrderDto) {
  //   if (order.status === OrderDtoStatusEnum.NEW) {
  //     setAcceptOrderModal(true);
  //     fetchOrder(order.id);
  //     return;
  //   }
  //   doAction(order);
  // }
  //
  // function fetchOrder(id: string) {
  //   if (active && active.id === id && active.items && active.items.length > 0)
  //     return;
  //
  //   OrderApi.getOrder(id).then((resp) => {
  //     setOrderLoading(false);
  //     setActive(resp.data);
  //   }).catch((err: AxiosError) => {
  //     setOrderLoading(false);
  //     setDetails(false);
  //     handleServerError(err);
  //   });
  // }
  //
  // function viewOrder(order: OrderDto) {
  //   setOrderLoading(true);
  //   setDetails(true);
  //   fetchOrder(order.id);
  // }
  //
  // function estimateOrder(order: OrderDto) {
  //   setOrderLoading(true);
  //   setEstimateOrderModal(true);
  //   setActive(order);
  // }
  //
  // function onStatusChange(
  //   id: string,
  //   status: OrderDtoStatusEnum,
  //   old: OrderDtoStatusEnum,
  //   estimatedTime?: string
  // ) {
  //   let list = replaceEntry(orders, id, "status", status);
  //   if (estimatedTime) {
  //     list = replaceEntry(list, id, "estimatedTime", estimatedTime);
  //   }
  //
  //   setOrders(list);
  //   setDetails(false);
  //   if (newOrderCount > 0 && old === OrderDtoStatusEnum.NEW) {
  //     dispatch(setNewOrderCount(newOrderCount - 1));
  //   }
  //   if (inProgressOrderCount > 0 && old === OrderDtoStatusEnum.PROGRESS) {
  //     dispatch(setInProgressOrderCount(inProgressOrderCount - 1));
  //   }
  // }
  //
  // function doDecline() {
  //   if (isAcceptOrderModal) {
  //     setAcceptOrderModal(false);
  //   }
  //   const status = OrderDtoStatusEnum.CANCELED;
  //   OrderApi.updateOrderStatus(active.id, {status: wrapStatusDto(status)}).then(() => {
  //     onStatusChange(active.id, status, active.status);
  //   }).catch(handleServerError);
  // }
  //
  // function onEstimateChange(order: OrderDto, minutes: string) {
  //   setOrders(replaceEntry(orders, order.id, "estimatedTime", minutes));
  //   setEstimateOrderModal(false);
  // }
  //
  // function updateOrder(order: OrderDto) {
  //   OrderApi.patchOrder(order.id, order).then(() => {
  //     message.success(t("Saved successfully"), 2);
  //     if (active.status !== order.status) {
  //       OrderApi.updateOrderStatus(active.id, {status: wrapStatusDto(order.status)}).then(() => {
  //         setDetails(false);
  //         setActive({});
  //         fetchOrders(tab);
  //       }).catch(handleServerError);
  //     } else {
  //       setDetails(false);
  //       setActive({});
  //       fetchOrders(tab);
  //     }
  //   }).catch(handleServerError);
  // }
  //
  // function onOrderEdit(order: OrderDto) {
  //   if (order.total !== active.total) {
  //     confirm({
  //       title: t("Edit order"),
  //       content: t("editOrderMessage"),
  //       okText: t("yes"),
  //       okType: "danger",
  //       cancelText: t("no"),
  //       onOk() {
  //         updateOrder(order);
  //       },
  //     });
  //   } else if (order.status !== active.status) {
  //     confirm({
  //       title: t("Edit order"),
  //       content: t("editStatusOrderMessage"),
  //       okText: t("yes"),
  //       okType: "danger",
  //       cancelText: t("no"),
  //       onOk() {
  //         updateOrder(order);
  //       },
  //     })
  //   }
  // }
  //
  // return (
  //   <div className={styles.orders}>
  //     <aside>
  //       <OrderTabs restaurant={business} tab={tab as OrderListTab} setTab={(tab: OrderListTab) => setTab(tab)}/>
  //     </aside>
  //
  //     <main>
  //       {isLoading && <div style={{padding: "24px"}}><Skeleton active/></div>}
  //
  //       {!isLoading && tab === TODAY && (
  //         <OrderTodayStats className={styles.stats} business={business} orders={orders}/>
  //       )}
  //
  //       {!isLoading && (
  //         <div className={styles.list}>
  //           {orders.length === 0 && (
  //             <p style={{margin: 0}}>
  //               <span>{t("No orders")}.</span>
  //             </p>
  //           )}
  //
  //           {orders.length > 0 && (
  //             <>
  //               <header className={styles.header}>
  //                 <ul>
  //                   <li/>
  //                   <li>{t("Customer")}</li>
  //                   <li>{t("Placed on")}</li>
  //                   <li>{t("Due")}</li>
  //                   <li>{t("Paid")}</li>
  //                   <li/>
  //                   <li>{t("Delivery")}</li>
  //                   <li>{t("Status")}</li>
  //                   <li/>
  //                   <li className={styles.total}>{t("Total")}</li>
  //                   <li/>
  //                 </ul>
  //               </header>
  //               <ul>
  //                 {orders.map((it) =>
  //                   tab === TODAY &&
  //                   it.status === OrderDtoStatusEnum.CANCELED ? null : (
  //                     <li key={it.id}>
  //                       <OrderLine order={it} restaurant={business} onOrderView={viewOrder} onOrderAction={onAction} onOrderEstimate={estimateOrder} fullDate={tab !== TODAY}/>
  //                     </li>
  //                   )
  //                 )}
  //               </ul>
  //             </>
  //           )}
  //         </div>
  //       )}
  //     </main>
  //
  //     <AcceptOrderModal order={active} isVisible={isAcceptOrderModal} onDecline={doDecline} onAccept={doAction} onCancel={() => setAcceptOrderModal(false)}/>
  //
  //     <EstimateOrderModal isVisible={isEstimateOrderModal} order={active} onSuccess={onEstimateChange} onCancel={() => setEstimateOrderModal(false)}/>
  //
  //     <Drawer className={"order-details-drawer"} placement="right" onClose={() => setDetails(false)} visible={isDetails}>
  //       <OrderDetails order={active} isLoading={isOrderLoading} business={business} onEdit={onOrderEdit} editMode={!isDetails}/>
  //     </Drawer>
  //   </div>
  return <></>;
}
