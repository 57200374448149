import * as React from 'react';
import './StaticMenuLine.scss';
import {getImagePath} from "../../services/ImageService";
import {MenuNode} from "../../domain/Menu";
import Price from "../Price";
import {BusinessDto} from '../../gen/client';
import {menuTextColor} from "../../services/StylesService";
import {isOOImage} from "../../services/MenuService";
import { getIconUrl, getUploadedIconUrl } from '../../util/Utils';

interface StaticMenuLineProps {
  isPreview: boolean;
  node: MenuNode;
  business: BusinessDto;
  onClick: (item: MenuNode) => void;
}

/*
  This is should be used for Online Menus without Online Ordering.
  For the Online Ordering version always use the <MenuLine /> component.
 */
export default function StaticMenuLine({isPreview, node, business, onClick}: StaticMenuLineProps) {
  return (
    <div className={`static-menu-line ${!node.preview ? 'no-prev one-column ' : ''} ${!isPreview ? 'cursor-not-preview' : ''} `}>
      <div id={'staticMenuLineId'} className={'data'} style={menuTextColor(business)} onClick={() => onClick(node)}>
        <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
          <h3 className="content two-lines" style={menuTextColor(business)}>{node.name}</h3>
          {node.icons && <ul style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '5px', marginLeft: '5px'}}>
            {node.icons.map(i => {
              return <li key={i.path || i.iconKey}>
                {i.path ? 
                <img className="icon-entry" src={getUploadedIconUrl(i)} alt={i.altText}></img>
                :
                <svg width="1.25em" height="1.25em" fill="currentColor" className="icon-entry">
                  <use xlinkHref={getIconUrl(i.iconKey)}></use>
                </svg>}
              </li>
            })}
          </ul>}
        </div>
        {node.description || node.prices[0] || node.extras ?
          <p>
            <span className="content four-lines">{node.description}</span>
            <span className="content one-line">
              {node.prices.filter(it => !!it).map((price, idx) => (
                <span key={idx}>
                  {node.sizes && node.sizes[idx] && node.prices.length > 1 && (<span>{node.sizes[idx]}&nbsp;</span>) }
                  <Price business={business} currency={business.config?.currency} value={price}/>
                  {idx < node.prices.length - 1 && ' | '}
                </span>))
              }
            </span>
            <span className="content two-lines">{node.extras}</span>
          </p> : null}
      </div>
      {node.preview && 
      <div 
        className="preview" 
        style={{backgroundImage: `url(${getImagePath(node.preview, isOOImage(node.previewSource))})`}} 
        role="img"
        aria-label={node.previewAltText ? node.previewAltText : ''}
        onClick={() => onClick(node)}
      />}
    </div>
  );
}
