export const env = {
  debug: false,

  basePath: 'https://www.musthavemenus.com',
  contextPath: '/oo',
  apiPath: '/oo/api',
  wsPath: 'https://web5.mhmfun.com/oo/api',
  subDomainRestaurantUrl: false,

  autoSaveDebounce: 250,

  googleTrack: true,
  googleAnalyticsTag: 'UA-164117115-1',
  googleAddTag: 'AW-646319637',
  s3Path: 'https://mhm-oo.s3.amazonaws.com/',
  mhmS3Path: 'https://mhm-timber.s3.amazonaws.com/',

  paymentToken: 'pk_live_3jFG7eVBWkg2y1XTQJbMSYzw',

  intercomAppId: 'rmer2hwk',

  timberAppPrefix: '/x',
  rendererPath: 'https://renderer.mhmcdn.com',
  qrPath: 'https://ohbz.com',
  autoLogoutMins: 20,
  accountContextPath: "https://www.musthavemenus.com/account",
};
