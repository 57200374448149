import * as React from 'react';
import './MakeFirstMenuActiveModal.scss';
import phoneDeliveryImg from '../../../img/phone-delivery.svg';
import {Modal} from "antd";

export interface MakeFirstMenuActiveModalProps {
  isVisible: boolean,
  onOk: (e: React.MouseEvent<HTMLElement>) => void,
  onCancel: () => void
}

export default function MakeFirstMenuActiveModal({ isVisible, onOk, onCancel }: MakeFirstMenuActiveModalProps) {
  return (
    <Modal
      className={'ready-to-active'}
      okButtonProps={{ size: 'large' }}
      okText={'Publish'}
      cancelText={'later'}
      visible={isVisible}
      onOk={ onOk }
      onCancel={ onCancel }
    >
      <h2>{ 'menuAlmostReady' }</h2>
      <img src={phoneDeliveryImg} alt={'Ready for delivery'} />

      <p>One last step - mark your menu as active!<br />Your customers will have access to it right away.</p>
    </Modal>
  );
};
