import * as React from 'react';
import {Modal} from "antd";
import './PublishModal.scss';

export interface PublishModalProps {
  unpublish?: boolean;
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export default function PublishModal({visible, unpublish, onConfirm, onCancel}: PublishModalProps) {

  return (
    <Modal className={'publish-modal'} okText={unpublish ? 'Unpublish' : 'Publish'} cancelText={'Cancel'} visible={visible} onOk={onConfirm} onCancel={onCancel}>
      <h2>{unpublish ? 'Are you sure?' : 'Ready to publish?'}</h2>
      <p style={{margin: 0}}>
        {unpublish ? 'Your link will be disabled.' : 'Your current changes will go live.'}
      </p>
    </Modal>
  );
};
