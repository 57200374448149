import {AxiosPromise} from "axios";
import {MenuDto, MenuResourceApi, MenuSequenceDto, TimberItemDto, TimberMenuMoResponse} from "../gen/client";
import {apiConfig, ops} from "./ApiConfig";

const api = new MenuResourceApi(apiConfig);

const MenuApi = {
  getMenu(menuId: string): AxiosPromise<MenuDto> {
    return api.getMenu(menuId, ops());
  },

  saveMenu(menu: MenuDto): AxiosPromise<MenuDto> {
    return api.createMenu(menu, ops());
  },

  saveMenus(menus: MenuDto[]): AxiosPromise<MenuDto[]> {
    return api.createMenus(menus, ops());
  },

  getTimberMoMenus(businessId: number = 0, key: string = "", page: number = 1): AxiosPromise<TimberMenuMoResponse> {
    return api.getTimberMoMenus(businessId, key, page, ops());
  },

  getDesignMenuObjects(designId: string): AxiosPromise<TimberItemDto[]> {
    return api.getDesignMenuObjects(designId, ops());
  },

  updateMenu(menu: MenuDto): AxiosPromise<void> {
    return api.updateMenu(menu, ops());
  },

  removeMenu(id: string): AxiosPromise<void> {
    return api.removeMenu(id, ops());
  },

  restoreMenu(id: string): AxiosPromise<void> {
    return api.restoreMenu(id, ops());
  },

  makeActive(id: string): AxiosPromise<boolean> {
    return api.makeActive(id, ops());
  },

  makeInactive(id: string): AxiosPromise<boolean> {
    return api.makeInactive(id, ops());
  },

  updateSequences(dtos: MenuSequenceDto[], businessId: string = ''): AxiosPromise<void> {
    return api.updateSequences(dtos, businessId, ops());
  },

  increaseViewCount(id: string) {
    return api.increaseViewCount(id, ops());
  }
};

export default MenuApi;
