import * as React from "react";
import "./DashboardHeader.scss";
import {useHistory} from "react-router-dom";
import {BusinessDto, MenuDto} from "../../gen/client";
import {Button, Drawer, message} from "antd";
import {RoutesEnum} from "../../RoutesEnum";
import EnableOrderingModal from "./EnableOrderingModal";
import {useState} from "react";
import DashboardNav from "./DashboardNav";
import PublishButton from "../PublishButton";
import logo_path from '../../img/om_logo.svg';
import menu_icon_path from '../../img/sidenav_icon.svg';
import BackArrow from "../misc/BackArrow";
import {goToMainApp} from "../../util/Utils";
import Loader from "../misc/Loader";
import {useRecoilState, useRecoilValue} from "recoil";
import {isRestoringState, revisionState} from "../../store/atoms";
import {saveRevisionSubject} from "../../store/Subjects";

export interface DashboardHeaderProps {
  business: BusinessDto;
  menus: MenuDto[];
  memberStatus: number;
  onBusinessUpdate: (business: BusinessDto) => void;
  saving: boolean;
  publishing: boolean;
  isPublished: boolean;
  onPublish: (published: boolean) => void;
  checkPublishingPossibility: () => void;
}

function DashboardHeader({business, saving, menus, memberStatus, publishing, isPublished, onBusinessUpdate, onPublish, checkPublishingPossibility}: DashboardHeaderProps) {
  const history = useHistory();

  const [isEnableModal, setEnableModal] = useState(false);
  const [isDrawerMenu, setDrawerMenu] = useState(false);

  const [isExiting, setIsExiting] = useState(false);
  const [revision, setRevision] = useRecoilState(revisionState);
  const isRestoring = useRecoilValue(isRestoringState);

  function onOrderingEnabled() {
    setEnableModal(false);
    onBusinessUpdate({...business, orderingEnabled: true});
    history.push(RoutesEnum.DashboardSettingsOrdering);
    message.success('Online ordering was enabled!', 3);
  }

  function goHome() {
    setIsExiting(true);
    goToMainApp();
  }

  function showMenuDrawer() {
    setDrawerMenu(true)
  }

  return (<> 
    {isExiting && <div className={"no-click-overlay"}></div>}
    <header className={'dashboard-header'}>
      <div className={"logo"} onClick={goHome}>
        {!isExiting && <>
        <BackArrow/>
        <img src={logo_path} className={'oo-logo'} draggable={'false'} alt={''} />
        <label>Online<br/>Menu</label>
        </>}
        {isExiting && <Loader/>}
      </div>

      {!revision.id && <>
          <DashboardNav menus={menus} business={business} detailed={false} />
          <PublishButton business={business} saving={saving} publishing={publishing} isPublished={isPublished} memberStatus={memberStatus} checkPublishingPossibility = {checkPublishingPossibility} />
      </>}

      {revision.id && <>
          <span className={'version-preview'}>Version Preview</span>
          <div className={'publish revision'}>
              <Button type={'link'} onClick={() => setRevision({})} disabled={isRestoring}>Cancel</Button>
              <Button type={'link'} onClick={() => saveRevisionSubject.next(revision)} disabled={isRestoring}>Restore this Version</Button>
          </div>
      </>}

      <Button type={'link'} className={'drawer-toggle'} onClick={showMenuDrawer}>
        <img src={menu_icon_path} alt={''}/>
      </Button>

      <Drawer className={'drawer-menu'} placement="right" width={"67vw"} onClose={() => setDrawerMenu(false)} visible={isDrawerMenu}>
        <DashboardNav menus={menus} business={business} detailed={true} onClick={() => setDrawerMenu(false)} />
      </Drawer>

      <EnableOrderingModal isVisible={isEnableModal} onCancel={() => setEnableModal(false)} onSuccess={onOrderingEnabled} businessId={business.id}/>
    </header></>
  );
}

export default DashboardHeader;
