import * as React from 'react';
import './DragHandleIcon.scss'

export type DragHandlePosition = 'top-right' | 'left' | 'left-center' | 'left-top'
export type DragHandleSize = 'small' | 'large'

export interface DragHandleIconProps {
  listeners: any;
  attributes: any;
  position: DragHandlePosition,
  size: DragHandleSize,
  visible: boolean;
}

export default function DragHandleIcon({listeners, attributes, position, size, visible}: DragHandleIconProps) {
  return <svg className={`drag-icon drag-handle ${position} ${size} ${visible ? 'visible' : ''}`} {...listeners} {...attributes}  xmlns="http://www.w3.org/2000/svg" x="0" y="0" enableBackground="new 0 0 384 384" version="1.1" width="9" height="15" viewBox="0 0 9 15" xmlSpace="preserve">
    <g id="Group 5">
      <circle id="Ellipse 1" cx="1.3125" cy="1.8125" r="1.3125" fill="#777777"/>
      <circle id="Ellipse 4" cx="7.14551" cy="1.8125" r="1.3125" fill="#777777"/>
      <circle id="Ellipse 2" cx="1.3125" cy="7.0625" r="1.3125" fill="#777777"/>
      <circle id="Ellipse 5" cx="7.14551" cy="7.0625" r="1.3125" fill="#777777"/>
      <circle id="Ellipse 3" cx="1.3125" cy="13.1875" r="1.3125" fill="#777777"/>
      <circle id="Ellipse 6" cx="7.14551" cy="13.1875" r="1.3125" fill="#777777"/>
    </g>
  </svg>
}
