import * as React from 'react';
import {Card, Switch} from "antd";
import {BusinessHoursDto, Day} from "../../../domain/BusinessHours";
import BusinessDay from "./BusinessDay";
import {useEffect, useState} from 'react';
import {HourFormat} from '../../misc/HourFormat';
import {HourFormatType} from '../../../util/types';
import styles from './BusinessHours.module.scss';
import {BusinessCfg, BusinessDto} from "../../../gen/client";
import PhoneInput from "../../misc/PhoneInput";

interface SettingsBusinessHoursProps {
  hours: BusinessHoursDto;
  business: BusinessDto;
  onConfigChange: (values: Partial<BusinessCfg>) => void;
}

export default function BusinessHours({business, hours, onConfigChange}: SettingsBusinessHoursProps) {
  const [hourFormat, setHourFormat] = useState(business.config.use24HourFormat ? HourFormatType._24 : HourFormatType.AM_PM);
  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (!business) return;
    setPhone(business?.config?.orderNowPhone ?? business?.config?.phone ?? '')
  }, [business]);

  function onHoursChange(day: string, data: Day) {
    onConfigChange({hours: JSON.stringify({...hours, [day]: data})});
  }

  function onHourFormatChange(value: HourFormatType) {
    setHourFormat(value);
    onConfigChange({use24HourFormat: value === HourFormatType._24});
  }

  return (
    <div className={'biz-hrs'}>
      <Card>
        <header>
          <h3>Business Hours</h3>

          <div className={styles.hours}>
            <HourFormat onChange={onHourFormatChange} value={hourFormat}/>
          </div>
        </header>
        <BusinessDay data={hours.monday || new Day()} day={'monday'} hourFormat={hourFormat} onChange={onHoursChange}/>
        <BusinessDay data={hours.tuesday || new Day()} day={'tuesday'} hourFormat={hourFormat} onChange={onHoursChange}/>
        <BusinessDay data={hours.wednesday || new Day()} day={'wednesday'} hourFormat={hourFormat} onChange={onHoursChange}/>
        <BusinessDay data={hours.thursday || new Day()} day={'thursday'} hourFormat={hourFormat} onChange={onHoursChange}/>
        <BusinessDay data={hours.friday || new Day()} day={'friday'} hourFormat={hourFormat} onChange={onHoursChange}/>
        <BusinessDay data={hours.saturday || new Day()} day={'saturday'} hourFormat={hourFormat} onChange={onHoursChange}/>
        <BusinessDay data={hours.sunday || new Day()} day={'sunday'} hourFormat={hourFormat} onChange={onHoursChange}/>
      </Card>

      <Card>
        <header className={'c'}>
          <h3>
            Order Now Button
            <Switch checked={business?.config?.orderNowButton} onChange={orderNowButton => onConfigChange({orderNowButton})} style={{marginLeft: '5px'}}/>
          </h3>
          <h5>Display a click-to-call button during open hours</h5>
        </header>
        <div className={business?.config?.orderNowButton && !phone ? 'ant-form-item-has-error' : ''}>
          <PhoneInput value={phone} onChange={(phone) => onConfigChange({orderNowPhone: phone})} country={business.config.country}/>

          {business?.config?.orderNowButton && !phone &&
          <div className="ant-form-item-explain ant-form-item-explain-error">
            Please enter a phone number to receive orders.
          </div>}
        </div>
      </Card>
    </div>
  );
};
