import * as React from 'react';
import './EmptyItemModal.scss';
import {Modal} from "antd";

export interface EmptyItemModalProps {
  count: number,
  isVisible: boolean,
  onOk: () => void,
  onCancel: () => void
}

export default function EmptyItemModal({isVisible, onOk, onCancel, count}: EmptyItemModalProps) {

  return (
    <Modal
      className={'emp-itm-mdl'}
      okButtonProps={{ size: 'large' }}
      okText={'yesSave'}
      cancelText={'Cancel'}
      visible={isVisible}
      onOk={onOk}
      onCancel={onCancel}>
      <h2>{ 'emptyMenuItems' }</h2>
      <p>{ 'emptyMenuItemsDetails' }</p>
      <p>{ 'emptyMenuItemsDetailsTwo'}</p>
    </Modal>
  );
};
