import * as React from 'react';
import {BusinessDto} from "../../../gen/client";
import styles from "./DashboardCustomers.module.scss";
import {useState} from "react";
import {CommentOutlined, UserOutlined} from "@ant-design/icons/lib";
import {Badge, Tabs} from "antd";
import CustomerList from "./CustomerList";
import Reviews from "./Reviews";

const {TabPane} = Tabs;

enum Tab {
  OVERVIEW = '1',
}

interface DashboardCustomersProps {
  business: BusinessDto;
  onBusinessUpdate: (business: BusinessDto) => void;
}

export default function DashboardCustomers({business, onBusinessUpdate}: DashboardCustomersProps) {

  const [activeTab, setActiveTab] = useState(Tab.OVERVIEW.toString());

  return (<div className={styles.customers}>
    <Tabs className={'enhanced fixed'} defaultActiveKey="1" activeKey={activeTab} tabPosition="left" onChange={setActiveTab}>
      <TabPane tab={<><UserOutlined/><span>{'Overview'}</span></>} key="1">
        <CustomerList business={business}/>
      </TabPane>
      <TabPane tab={
        <Badge offset={[5, 5]} dot={true}>
          <CommentOutlined/><span>{'Reviews'}</span>
        </Badge>} key="2">
        <Reviews business={business} onBusinessUpdate={onBusinessUpdate}/>
      </TabPane>
    </Tabs>
  </div>);
};
