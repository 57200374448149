import * as React from 'react';
import {useEffect, useState} from 'react';
import './AuthPage.scss';
import LoginForm from "./LoginForm";
import {RoutesEnum} from "../RoutesEnum";
import {useHistory, useLocation} from "react-router";
import {NavLink} from "react-router-dom";
import logo from "../img/logo.svg";
import StartForm from "./StartForm";
import useQueryString from "../hooks/useQueryString";
import {Button, message} from "antd";
import {PASSWORD, REF, SESSION} from "../util/constants";
import {setPageTitle} from "../util/seo";

function AuthPage() {
  const history = useHistory();
  const {pathname} = useLocation();
  const [ref] = useQueryString(REF, '');
  const [view, setView] = useState(pathname);

  useEffect(() => {
    setPageTitle('start');
  }, []);

  useEffect(() => {
    if (ref === SESSION) message.error('sessionExpired');
    else if (ref === PASSWORD) message.success('passwordUpdated');
  }, [ref]);

  function onViewToggle(path: string) {
    setView(path);
  }

  function onAuthSuccess() {
    history.push(RoutesEnum.App);
  }

  function onRegSuccess() {
    history.push(RoutesEnum.App);
  }

  return (
    <div className={'auth'}>
      <div className={'side'}>
        <NavLink to={RoutesEnum.Root} className={`logo`}><img src={logo} alt={'Logo'}/></NavLink>

        {view === RoutesEnum.Login ?
          <div>
            <h2>{'Don\'t have an account'}?</h2>
            <Button type={"dashed"} size={'large'} onClick={() => onViewToggle(RoutesEnum.Start)}>{'Create a Free Account'}</Button>
          </div> :
          <div>
            <h2>{'Already a member'}?</h2>
            <Button type={"dashed"} size={'large'} onClick={() => onViewToggle(RoutesEnum.Login)}>{'Log In'}</Button>
          </div>}
      </div>

      <div className={'data'}>
        {view === RoutesEnum.Login ? (
          <LoginForm onSuccess={onAuthSuccess} onError={() => {}} />
        ) : (
          <StartForm onSuccess={onRegSuccess} onError={() => {}} />
        )}
      </div>
    </div>
  );
}

export default AuthPage;
