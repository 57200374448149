import "./AutocompleteGoogleSearch.scss";
import { useEffect } from "react";
import { AutoComplete } from "antd";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import LocationIcon from "../../misc/icons/LocationIcon";
import React from "react";

const { Option } = AutoComplete;

interface AutocompleteGoogleSearchProps {
  fieldValue: string;
  placeholder: string;
  tabIndex: number;
  updateField: (val?: string, addressComponents?: any[]) => void;
  onBlur?: () => void;
}

export function AutocompleteGoogleSearch({
  fieldValue,
  placeholder,
  tabIndex,
  updateField,
  onBlur,
}: AutocompleteGoogleSearchProps) {
  const sessionToken = new google.maps.places.AutocompleteSessionToken();
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      sessionToken: sessionToken,
    },
  });

  useEffect(() => {
    if (fieldValue !== "") setValue(fieldValue, false);
    clearSuggestions();
  }, [fieldValue, clearSuggestions, setValue]);

  async function onSelectAddress(address: any) {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      updateField(
        results[0].formatted_address.toString(),
        results[0].address_components
      );
    } catch (error) {
      console.log("error ", error);
      updateField(address, []);
    }
  }

  const handleSearch = (address: string) => {
    setValue(address);
    updateField(address, []);
  };
  return (
    <AutoComplete
      tabIndex={tabIndex}
      className="autocomplete-adress"
      onSearch={handleSearch}
      placeholder={placeholder}
      value={value}
      onSelect={(address: any) => onSelectAddress(address)}
      onBlur={onBlur}
      allowClear
      disabled={!ready}
      notFoundContent={null}
    >
      {status === "OK" &&
        data.map(({ place_id, description }) => (
          <Option value={description} key={place_id}>
            <span className={"autocomplete-option"}><LocationIcon width={20} height={20} color={"#000"} /> {description}</span>
          </Option>
        ))}
    </AutoComplete>
  );
}
