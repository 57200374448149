import * as React from 'react';

export interface IconProps {
  fill?: string;
  stroke?: string;
}

export default function UploadIcon({fill, stroke}: IconProps) {
  return  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.85 23.28" width={'1em'} height={'1em'} fill={'currentColor'}>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path d="M26.66 8.35a6.2 6.2 0 0 0-6.73-5.45 6.39 6.39 0 0 0-1.44.32A8 8 0 0 0 4.11 8v.36a7.93 7.93 0 0 0 3.82 14.89h1.43v-1.81H8a6.13 6.13 0 0 1-6.08-6.18 6.14 6.14 0 0 1 3.52-5.52l.62-.27L6 8.8a5.25 5.25 0 0 1-.08-.8 6.18 6.18 0 0 1 6.18-6.16 6.25 6.25 0 0 1 5.28 3l.43.72.75-.37a4.38 4.38 0 0 1 5.88 2A4.24 4.24 0 0 1 24.9 9v.57l.52.24a6.12 6.12 0 0 1 3 8.13A6.14 6.14 0 0 1 23 21.47h-1.46v1.81H23a7.93 7.93 0 0 0 3.66-14.93Z" />
        <path d="m15.92 10.07-.12-.14a.94.94 0 0 0-1.28 0L11 14.2a.91.91 0 0 0 .12 1.28.94.94 0 0 0 .56.21.93.93 0 0 0 .73-.35l1.86-2.23v9.24a.91.91 0 0 0 1.81 0V13L18 15.35a.91.91 0 0 0 1.23.11.93.93 0 0 0 .12-1.28Z" />
      </g>
    </g>
  </svg>;
}
