import {DeleteOutlined} from "@ant-design/icons";
import {Popconfirm} from "antd";
import * as React from "react";
import {MenuItemModifier, MenuNode} from "../../../domain/Menu";
import Price from "../../Price";
import styles from "./MenuItemModifiersPreview.module.scss";

interface MenuItemModifiersPreviewProps {
  item: MenuNode;
  currency: string;
  onModifierRemove: (m: MenuItemModifier) => void;
}

export default function MenuItemModifiersPreview({item, currency, onModifierRemove}: MenuItemModifiersPreviewProps) {

  return (
    <div className={styles.container}>
      {item.modifiers && item.modifiers.map(m =>
        <div key={m.id} className={styles.entryV2}>
          <div>
            <span>{m.name} <span>{!!m.required ? 'required' : 'optional'} {!!m.required && ` | ${'select'} ${m.required}`}</span></span>

            <ul>
              {m.options.map(o => <li key={o.id}>
                {o.name}
                {!!o.price && <> (+<Price value={o.price + ''} currency={currency} />)</>}
              </li>)}
            </ul>
          </div>

          <div>
            <Popconfirm title={'Are you sure?'} onConfirm={() => onModifierRemove(m)}>
              <DeleteOutlined />
            </Popconfirm>
          </div>
        </div>
      )}
    </div>
  );
}
