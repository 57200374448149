import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {env} from "../../env/env";
import {Button, Modal} from "antd";
import {goToMainApp} from "../../util/Utils";
import './AutoLogout.scss';
import {minsAsMillis, now, secondsSince} from "../../util/dates";

export default function AutoLogout() {
  const [secondsTillExpire, setSecondsTillExpire] = useState(60);
  const [lastActionAt, setLastActionAt] = useState(now());
  const [modalShownAt, setModalShownAt] = useState(0);

  const onMouseMove = () => {
    setLastActionAt(now());
  }

  const onExitApp = useCallback(() => {
    reset();
    goToMainApp();
  }, []);

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
    }
  }, []);

  useEffect(() => {
    let interval = null as NodeJS.Timeout;
    if (modalShownAt) {
      interval = setInterval(() => {
        setSecondsTillExpire(60 - secondsSince(modalShownAt))
      }, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [modalShownAt]);

  useEffect(() => {
    if (secondsTillExpire <= 0) {
      onExitApp();
    }
  }, [secondsTillExpire, onExitApp]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setModalShownAt(prev => prev === 0 ? now() : prev);
    }, minsAsMillis(env.autoLogoutMins));

    return () => {
      clearTimeout(timeout);
    }
  }, [lastActionAt]);

  function onContinueEditing() {
    reset();
  }

  function reset() {
    setModalShownAt(0);
    setSecondsTillExpire(60);
  }

  return <>
    {!!modalShownAt &&
        <Modal className={`inactivity-modal`} visible={!!modalShownAt} closable={false} footer={null}>
            <header>
                <h2>Are you still there?</h2>
            </header>
            <main>
                <section>
                    Your session will expire in {secondsTillExpire <= 0 ? 0 : secondsTillExpire} seconds. Your changes are saved.
                </section>
            </main>
            <footer>
                <Button onClick={onExitApp}>Exit</Button>
                <Button onClick={onContinueEditing} type={"primary"}>Continue Editing</Button>
            </footer>
        </Modal>}
  </>
}
