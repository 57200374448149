import * as React from 'react';
import {Card, Switch} from "antd";
import {MemberColorDto} from '../../../gen/client';
import './SettingsAnnouncements.scss';
import LinkSchedule from "./LinkSchedule";
import {Moment} from "moment";
import {useEffect, useMemo, useRef, useState} from "react";
import TextArea from "antd/lib/input/TextArea";
import DesignColorPicker from "../DesignColorPicker";
import {BANNER_TEXT_MAX_LENGTH} from "../../../util/constants";
import {AnnouncementBanner} from "../../../domain/AnnouncementBanner";
import {fromTimestamp, timestamp} from "../../../util/Utils";
import {isBannerExpired, isBannerScheduled} from "../../../services/BusinessService";
import {ClockCircleOutlined, CloseOutlined} from "@ant-design/icons";

interface SettingsAnnouncementsProps {
  cfg: AnnouncementBanner;
  memberColors: MemberColorDto[];
  onAnnouncementConfigChange: (values: Partial<AnnouncementBanner>) => void;
}

export default function SettingsAnnouncements({
                                                cfg,
                                                memberColors,
                                                onAnnouncementConfigChange
                                              }: SettingsAnnouncementsProps) {
  const [chars, setChars] = useState(BANNER_TEXT_MAX_LENGTH);
  const textAreaRef = useRef(null);
  const [showSchedule, setShowSchedule] = useState(false);

  useEffect(() => {
    if (!cfg) return;

    setChars(cfg.text ? BANNER_TEXT_MAX_LENGTH - cfg.text.length : BANNER_TEXT_MAX_LENGTH);
  }, [cfg]);

  const onChange = (values: Partial<AnnouncementBanner>) => {
    onAnnouncementConfigChange(values);
  }

  function onSelect(m: Moment, type?: string) {
    onChange('start' === type ? {start: timestamp(m)} : {end: timestamp(m)});
  }

  function onClear(type?: string) {
    onChange('start' === type ? {start: null as number} : {end: null as number});
  }

  function onEnableClick() {
    onChange({
      enabled: !cfg.enabled,
      text: textAreaRef.current.resizableTextArea.props.value
    });
  }

  function onScheduleClick() {
    setShowSchedule(!showSchedule);
  }

  const scheduleLabel = useMemo(() => (
    !isBannerScheduled(cfg) ? <span>Schedule display (optional)</span> : isBannerExpired(cfg) ?
      <span className="scheduled expired">Banner schedule has expired</span> : <span className="scheduled">Scheduled!</span>
  ), [cfg]);

  return <Card className={'settings-announcements'}>
    <header>
      <h3>Announcements</h3>
      <h4>Display a promotional banner at the top of your site for important announcements.</h4>
    </header>
    <section>
      <div className={'banner actions'}>
        <label>Show Banner</label>
        <Switch className={"sp"} checked={cfg.enabled} onClick={onEnableClick}/>
      </div>
      <div className={'banner text'}>
        <h3>Text <span>{chars}</span></h3>
        <TextArea defaultValue={cfg.text} onChange={(e) => setChars(BANNER_TEXT_MAX_LENGTH - e.target.value.length)}
                  onBlur={(e) => onChange({text: e.target.value})} maxLength={BANNER_TEXT_MAX_LENGTH}
                  ref={textAreaRef}/>
      </div>
      <div className={`banner schedule ${isBannerScheduled(cfg) ? 'active' : ''}`}>
        <div className={'status'} onClick={onScheduleClick}><ClockCircleOutlined/>&nbsp; {scheduleLabel}</div>

        {showSchedule && <div className={'entry schedule'}>
            <div className={'header'}>
                <span>Schedule Banner</span>
                <div className={'close-btn'}><CloseOutlined onClick={onScheduleClick}/></div>
            </div>
            <div className={'content'}>
                <LinkSchedule value={cfg.start ? fromTimestamp(cfg.start) : null} label={'Start'}
                              onClear={() => onClear('start')} onSelect={(m) => onSelect(m, 'start')}/>
                <div className={'separator'}>
                    <div>&nbsp;</div>
                    <span>-</span></div>
                <LinkSchedule value={cfg.end ? fromTimestamp(cfg.end) : null} label={'End'} onClear={onClear}
                              onSelect={(m) => onSelect(m)}/>
            </div>
        </div>}
      </div>
      <div className={'banner design'}>
        <DesignColorPicker label={'Banner'} color={cfg.backgroundColor || '#000000'}
                           memberColors={memberColors}
                           onChange={v => onChange({backgroundColor: v})}/>

        <DesignColorPicker label={'Text'} color={cfg.textColor || '#FFFFFFFF'}
                           memberColors={memberColors}
                           onChange={v => onChange({textColor: v})}/>
      </div>
    </section>
  </Card>;

};
