import * as React from "react";
import {ReactNode, useEffect, useState} from "react";
import "./Uploader.scss";
import MemberApi from "../api/MemberApi";
import {ImageDtoSourceEnum, ImageTypeEnum, MemberImageDto} from "../gen/client";
import {Button, Dropdown, Menu, Upload} from "antd";
import {handleServerError} from "../util/ErrorHandler";
import {getImagePath} from "../services/ImageService";
import {DeleteOutlined, EditOutlined, RotateLeftOutlined, RotateRightOutlined, SwapOutlined} from "@ant-design/icons";
import Loader from "./misc/Loader";
import {MenuInfo} from "rc-menu/lib/interface";
import ImageApi from "../api/ImageApi";
import {fromBase64ToBlob, getBase64Response, getMimeStringFromBase64, rotateImage} from "../util/Utils";
import FileTooBigModal from "./misc/FileTooBigModal";
import {ALLOWED_IMAGE_TYPES, MAX_IMAGE_SIZE} from "../util/constants";
import FileInvalidTypeModal from "./misc/FileInvalidTypeModal";
import BrandsApi from "../api/BrandsApi";
import {useRecoilState} from "recoil";
import {currentLocation} from "../store/atoms";
import ChooseImageModal from "./ChooseImageModal";
import {isOOImage} from "../services/MenuService";
import ADAIcon from "./misc/icons/ADAIcon";
import Storage from "../util/Storage";

export enum ImageUploaderTypeEnum {
  Button,
  Card,
  Circle,
}

interface UploaderProps {
  memberId: number;
  imageType: ImageTypeEnum;
  widgetType: ImageUploaderTypeEnum;
  onUploadSuccess: (path: string, source: ImageDtoSourceEnum) => void;
  addLabel: ReactNode;
  changeLabel?: ReactNode;
  removeItemImage?: () => void;
  path?: string;
  source?: ImageDtoSourceEnum;
  className?: string;
  hidePreview?: boolean;
  hasDropdown?: boolean;
  hideModal?: boolean;
  memberImages?: MemberImageDto[];
  setMemberImages?: (images: MemberImageDto[]) => void;
  setSelectedMemberImage?: (image: MemberImageDto) => void;
  setAltTextModal?: (visible: boolean) => void;
}

export function Uploader({memberId, imageType, widgetType, path, addLabel, changeLabel, className, hidePreview, hasDropdown = false, removeItemImage, onUploadSuccess, source, hideModal, memberImages, setMemberImages, setSelectedMemberImage, setAltTextModal}: UploaderProps) {
  const [preview, setPreview] = useState(path);
  const [loading, setLoading] = useState(false);
  const [showFileTooBigModal, setShowFileTooBigModal] = useState(false);
  const [showFileInvalidModal, setShowFileInvalidModal] = useState(false);
  const [chooseImageModal, setChooseImageModal] = useState(false);
  const [location] = useRecoilState(currentLocation);

  function rotate(right: boolean = false) {
    setLoading(true);
    ImageApi.getImage(path).then(resp => {
      const base64 = getBase64Response(resp);
      const mimeString = getMimeStringFromBase64(base64);

      rotateImage(base64, mimeString, right).then(rotatedBase64 => {
        const blob = fromBase64ToBlob(rotatedBase64, mimeString);
        const file = new File([blob], path.substring(path.lastIndexOf('/')), { lastModified: new Date().getTime(), type: blob.type });

        uploadFile(file);
      }).catch((err) => {
        handleServerError(err);
        setLoading(false);
      });
    });
  }

  useEffect(() => {
    setPreview(path);
  }, [path]);

  const uploadFile = (file: File) => {
    setLoading(true);

    if (imageType === ImageTypeEnum.BusinessLogo) {
      BrandsApi.uploadLogo(location.internalId, file)
        .then((resp) => {
          onUploadSuccess(resp.data.path, ImageDtoSourceEnum.MyBrand);
          setPreview(resp.data.path);
          setLoading(false);
        })
        .catch((err) => {
          handleServerError(err);
          setLoading(false);
        });
    } else {
      MemberApi.uploadImage(memberId, location.internalId, imageType, file)
        .then((resp) => {
          MemberApi.getMemberImages(Storage.getMemberId(), location.internalId).then(resp2 => {
            setMemberImages(resp2.data.sort((a, b) => parseInt(b.createdDate || '0') - parseInt(a.createdDate || '0')));
            handleUploadSuccess(resp.data.path, ImageDtoSourceEnum.Ordering);
            setTimeout(() => {
              window.localStorage.setItem('alttext-just-uploaded', 'true');
              onADAClick(resp.data.path, resp2.data)
            }, 100);
            setLoading(false);
          }).catch(e => {
            handleServerError(e);
            setLoading(false);
          });
        })
        .catch((err) => {
          handleServerError(err);
          setLoading(false);
        });
      }
  };

  function handleUploadSuccess(path: string, source: ImageDtoSourceEnum) {
    onUploadSuccess(path, source);
    setPreview(path);
  }

  function onExistingImageChoice(img: MemberImageDto) {
    handleUploadSuccess(img.path, img.source === 'oo' ? ImageDtoSourceEnum.Ordering : ImageDtoSourceEnum.MyBrand);
    setChooseImageModal(false);
  }

  function onADAClick(pathParam?: string, memberImagesParam?: MemberImageDto[]) {
    const findPath = pathParam ? pathParam : path;
    const findMemberImages = memberImagesParam ? memberImagesParam : memberImages;
    const selected = findMemberImages.find(i => i.path === findPath);
    setSelectedMemberImage(selected);
    setAltTextModal(true);
  }

  const beforeUpload = (file: File) => {
    if (ALLOWED_IMAGE_TYPES.indexOf(file.type) < 0) {
      setShowFileInvalidModal(true);
      return false;
    }

    const mbSize = Math.floor(file.size / (1024 * 1024));
    if (mbSize >= MAX_IMAGE_SIZE) {
      setShowFileTooBigModal(true);
      return false;
    } else {
      setChooseImageModal(false);
      uploadFile(file);
      return false;
    }
  };

  const toggleDropdown = (info: MenuInfo) => {
    info.domEvent.stopPropagation();
    info.domEvent.preventDefault();
    switch (+info.key) {
      case 0: onADAClick(); break;
      case 1: setChooseImageModal(true); break;
      case 2: rotate(); break;
      case 3: rotate(true); break;
      case 4: removeItemImage();
    }
  };

  const menu = (
    <Menu className={'upload-img-menu'} onClick={toggleDropdown}>
      <Menu.Item key="0"><ADAIcon color="#333"/> ADA Text</Menu.Item>
      <Menu.Item key="1"><SwapOutlined/> Replace Photo</Menu.Item>
      <Menu.Item key="2"><RotateLeftOutlined/> Rotate Left</Menu.Item>
      <Menu.Item key="3"><RotateRightOutlined/> Rotate Right</Menu.Item>
      <Menu.Item key="4"><DeleteOutlined/> Delete Photo</Menu.Item>
    </Menu>
  );

  return (
    <div className={`uploader ${hasDropdown ? 'with-drop' : ''} ${className || ""}`}>
      {loading && <Loader small/>}

      {!hidePreview && preview && <div className='preview' style={{ backgroundImage: `url(${getImagePath(preview, isOOImage(source))})`}}/>}

      {hasDropdown && preview && (
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type={"link"}>{!loading && <EditOutlined />}</Button>
        </Dropdown>
      )}

      {(!hasDropdown || !preview) && hideModal && (
        <div className={'ant-upload ant-upload-select ant-upload-select-picture-card'}>
          <Upload className={!preview ? 'no': ''} listType={widgetType === ImageUploaderTypeEnum.Card ? "picture-card" : null} beforeUpload={beforeUpload} showUploadList={false} progress={null} accept={ALLOWED_IMAGE_TYPES}>
            <Button type={widgetType === ImageUploaderTypeEnum.Card ? "link" : null}>
              {!loading && (preview ? changeLabel || addLabel : addLabel)}
            </Button>
          </Upload>
        </div>
      )}

      {(!hasDropdown || !preview) && !hideModal && (
        <div className={'ant-upload ant-upload-select ant-upload-select-picture-card'}>
          <Button type={widgetType === ImageUploaderTypeEnum.Card ? "link" : null} onClick={() => setChooseImageModal(true)}>
            {!loading && (preview ? changeLabel || addLabel : addLabel)}
          </Button>
        </div>
      )}

      {showFileTooBigModal && <FileTooBigModal onOk={() => setShowFileTooBigModal(false)} maxSize={MAX_IMAGE_SIZE}/>}
      {showFileInvalidModal && <FileInvalidTypeModal onOk={() => setShowFileInvalidModal(false)}/>}
      {chooseImageModal && <ChooseImageModal 
        memberId={memberId} 
        onClose={() => setChooseImageModal(false)} 
        onUpload={beforeUpload} 
        onChoice={onExistingImageChoice}
        memberImages={memberImages}
        setMemberImages={setMemberImages} />}
    </div>
  );
}
