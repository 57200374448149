import * as React from 'react';
import './DesignColorPicker.scss';
import {Dropdown} from "antd";
import ColorPicker from '../misc/color-picker/ColorPicker';
import {useEffect, useRef, useState} from "react";
import _ from "lodash";
import {MemberColorDto} from "../../gen/client";

interface DesignColorPickerProps {
  label: string;
  color: string;
  onChange: (color: string) => void;
  memberColors: MemberColorDto[];
}

export default function DesignColorPicker({label, color, onChange, memberColors}: DesignColorPickerProps) {
  const [localColor, setLocalColor] = useState('');

  const throttledOnChange = useRef(_.debounce((color: string, currentColor: string) => save(color, currentColor), 250));

  function save(color: string, currentColor: string) {
    if (color !== currentColor) {
      onChange(color);
    }
  }

  useEffect(() => {
    setLocalColor(color);
  }, [color]);

  useEffect(() => {
    if (localColor) {
      throttledOnChange.current(localColor, color);
    }
  }, [color, localColor]);


  return (
    <div className={'design-color-picker'}>
      <h6>{label}</h6>
        <Dropdown placement={'topCenter'} trigger={['click']} overlay={
          <ColorPicker color={localColor} onChange={c => setLocalColor(c.hex)} onChangeComplete={c => setLocalColor(c.hex)} memberColors={memberColors} />
      }>
        <div className={'field'}>
          <label>{localColor}</label>
          <span style={{backgroundColor: localColor}} />
        </div>
      </Dropdown>
    </div>
  );
};
