import {AuthResourceApi, AuthResponseDto, LoginDto, RegisterDto} from "../gen/client";
import {AxiosPromise} from "axios";
import {apiConfig, ops} from "./ApiConfig";

const api = new AuthResourceApi(apiConfig);

const authApi = {
  login(data: LoginDto): AxiosPromise<AuthResponseDto> {
    return api.login(data, ops());
  },

  register(data: RegisterDto): AxiosPromise<AuthResponseDto> {
    return api.register(data, ops());
  }
}

export default authApi;
