import * as React from 'react';
import {Button, Modal} from "antd";
import './EnableOrderingModal.scss';
import image from '../../img/enable-oo-cta.png';
import {useState} from "react";
import {BusinessApi} from "../../api/BusinessApi";
import {AxiosError} from "axios";
import {handleServerError} from "../../util/ErrorHandler";

export interface EnableOrderingModalProps {
  businessId: string;
  isVisible: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

export default function EnableOrderingModal({isVisible, onSuccess, onCancel, businessId}: EnableOrderingModalProps) {

  const [enabling, setEnabling] = useState(false);

  function enable() {
    setEnabling(true);
    BusinessApi.patch(businessId, {orderingEnabled: true}).then(() => {
      onSuccess();
    }).catch((err: AxiosError) => {
      setEnabling(false);
      handleServerError(err);
    });
  }

  return (
    <Modal className={`enable-ordering-modal`} visible={isVisible} onCancel={onCancel}>
      <div className={'bg'} />
      <div className={'circle'} />
      <div className={'content'}>
        <img src={image} alt={''}/>
        <div className={'data'}>
          <h3>Get setup to take orders online in minutes</h3>
          <p>
            We'll just need you to set up your payment account with Stripe, then review your menu items to make sure they are ready for ordering...
          </p>

          <Button onClick={enable} loading={enabling} disabled={enabling}>Enable Online Ordering</Button>
        </div>
      </div>
    </Modal>
  );
};
