import {Select} from 'antd';
import React from 'react';
import {AmPmType} from '../../util/types';
import './AmPmPicker.scss';

interface AmPmPickerProps {
  onChange: (type: AmPmType) => void;
  value: AmPmType;
  hidden: boolean;
}

const {Option} = Select;

export function AmPmPicker({onChange, value, hidden}: AmPmPickerProps) {

  return (
    <Select value={value} onChange={onChange} bordered={false} className={`am-pm ${hidden ? 'invisible' : ''}`}>
      <Option value={AmPmType.AM}>{'AM'}</Option>
      <Option value={AmPmType.PM}>{'PM'}</Option>
    </Select>
  );
}
