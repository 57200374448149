import Storage from "../util/Storage";
import {env} from "../env/env";

export const ops = (loginToken: string = null) => {
  if (!Storage.getToken() && !loginToken) return {}

  return {
    headers: {
      Authorization: "Bearer " + (loginToken || Storage.getToken())
    }
  }
};

export const apiConfig = {
  basePath: env.apiPath
};
