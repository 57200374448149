import * as React from 'react';
import {ColorPickerProps, CustomPicker, TwitterPicker} from "react-color";
import {EditableInput, Hue, Saturation} from "react-color/lib/components/common";
import './ColorPicker.scss';
import CustomPickerHuePointer from "./CustomPickerHuePointer";
import {MemberColorDto} from "../../../gen/client";
import CustomPickerSaturationPointer from "./CustomPickerSaturationPointer";
import {useEffect, useState} from "react";

const hueStyles = {
  div: {
    position: 'relative'
  }
}

const inputStyles = {
  input: {
    fontFamily: 'proxima-nova',
    width: '100%'
  }
}

const githubStyles = {
  'default': {
    body: {
      backgroundColor: 'none',
      background: 'none',
      border: '0',
      boxShadow: 'none',
      padding: '0'
    },
    card: {
      backgroundColor: 'none',
      background: 'none',
      border: '0',
      boxShadow: 'none',
      padding: '0',
      width: '215px'
    },
    input: {
      display: 'none'
    },
    label: {
      display: 'none'
    },
    hash: {
      display: 'none'
    }
  }
}

interface CustomPickerProps extends ColorPickerProps<any> {
  memberColors: MemberColorDto[];
}

export default CustomPicker(function ColorPicker(props: CustomPickerProps) {
  const [brandColors, setBrandColors] = useState([] as string[]);

  useEffect(() => {
    setBrandColors(props.memberColors.map(it => it.colorCode));
  }, [props.memberColors]);

  function inputRef(i: EditableInput) {
    if (i) {
      // @ts-ignore
      document.getElementById(i.inputId).classList.add('ant-input');
    }
  }

  return <div className={'custom-picker-wrapper'}>
    <div className={'custom-picker'}>
      <div className={'chrome'}>
        <Saturation {...props} onChange={props.onChange} pointer={CustomPickerSaturationPointer}/>
      </div>
      <div className={'hue'}>
        <Hue {...props} direction={'vertical'} onChange={props.onChange} pointer={CustomPickerHuePointer} styles={hueStyles}/>
      </div>
    </div>
    <div className={'footer'}>
      <div className={'input'}>
        <label>Color code #</label>
        <EditableInput {...props} value={props.color} style={inputStyles} ref={inputRef}/>
      </div>
      {!!brandColors.length && <div className={'brand'}>
        <label>Brand Colors</label>
        <TwitterPicker {...props} colors={brandColors} styles={githubStyles} triangle={'hide'} />
      </div>}
    </div>
  </div>
});
