import * as React from 'react';
import { Button, Input, Switch, Tooltip } from "antd";
import { Uploader, ImageUploaderTypeEnum } from "../../Uploader";
import Storage from "../../../util/Storage";
import { ImageTypeEnum, BusinessDto, MemberImageDto, MemberIconDto } from "../../../gen/client";
import { MenuItemIcon, MenuItemModifier, MenuNode } from "../../../domain/Menu";
import MenuItemSizes from "./MenuItemSizes";
import ItemView from "./ItemView";
import styles from './MenuItem.module.scss';
import "./MenuItem.scss"
import MenuItemModifiers from './MenuItemModifiers';
import { MenuDataType, MenuItemModifierDetail } from '../../../util/types';
import { DeleteOutlined, PictureOutlined, UpOutlined } from "@ant-design/icons";
import DeleteMenuDataModal from './DeleteMenuDataModal';
import { useState } from 'react';
import MenuIconModal from './MenuIconModal';
import { getIconUrl, getUploadedIconUrl } from '../../../util/Utils';
import { maxMenuItemIcons } from '../../../util/constants';

interface MenuItemProps {
  item: MenuNode;
  sectionId: string;
  business: BusinessDto;
  currency: string;
  modifiers: MenuItemModifierDetail[];
  onModifierRemovedGlobal: (m: MenuItemModifier) => void;
  onItemUpdate: (item: Partial<MenuNode>) => void;
  onItemRemove: (sectionId: string, itemId: string) => void;
  onCurrencyChange: (currency: string) => void;
  editMode?: boolean;
  onClick: () => void;
  onCancel: () => void;
  memberImages: MemberImageDto[];
  setMemberImages: (images: MemberImageDto[]) => void;
  setSelectedMemberImage: (image: MemberImageDto | MemberIconDto) => void;
  memberIcons: MemberIconDto[];
  setMemberIcons: (icons: MemberIconDto[]) => void;
  activeMenuIcons: MenuItemIcon[];
  updateMenuActiveIcons: () => void;
  setAltTextModal: (visible: boolean) => void;
}

export default function MenuItem({ item, sectionId, business, currency, modifiers, onItemRemove, onItemUpdate, editMode, onModifierRemovedGlobal, onClick, onCancel, memberImages, setMemberImages, memberIcons, setMemberIcons, activeMenuIcons, updateMenuActiveIcons, setSelectedMemberImage, setAltTextModal }: MenuItemProps) {
  const [isRemoveModal, setRemoveModal] = useState(false);
  const [isIconModal, setIsIconModal] = useState(false);
  const [selectedIconIndex, setSelectedIconIndex] = useState(0);

  function onModifierAddOrEdit(m: MenuItemModifier) {
    if (!item.modifiers) {
      item.modifiers = [];
    }

    const idx = item.modifiers.findIndex(it => it.id === m.id);

    if (idx < 0) {
      item.modifiers.push(m);
    } else {
      item.modifiers[idx] = m;
    }

    onItemUpdate({ modifiers: item.modifiers });
  }

  function onModifierRemove(m: MenuItemModifier, global?: boolean) {
    if (global) {
      onModifierRemovedGlobal(m);
    }

    onItemUpdate({ modifiers: item.modifiers.filter(it => it.id !== m.id) });
  }

  function removeItemImage() {
    onItemUpdate({ preview: null });
  }

  function onRemove(e: React.MouseEvent) {
    e.stopPropagation();
    onItemRemove(sectionId, item.id);
  }

  function onCurrentIconClick(icon: MenuItemIcon) {
    if (!item.icons) return;
    if (item.icons.includes(icon)) {
      setSelectedIconIndex(item.icons.indexOf(icon));
      setIsIconModal(true);
    }
  }

  function onNewIconClick() {
    setSelectedIconIndex(item.icons ? item.icons.length : 0);
    setIsIconModal(true);
  }

  function updateItemIcon(icon: MenuItemIcon, index: number) {
    const itemIcons = item.icons ? item.icons : [] as MenuItemIcon[];
    if (itemIcons.length === maxMenuItemIcons) return;
    if (index === itemIcons.length) {
      itemIcons.push(icon);
      setSelectedIconIndex(selectedIconIndex + 1);
    } else {
      itemIcons[index] = icon;
    }
    onItemUpdate({ icons: [...itemIcons] })
  }

  function deleteItemIcon(index: number) {
    item.icons.splice(index, 1);
    onItemUpdate({ icons: [...item.icons] });
  }

  function clearAllItemIcons() {
    onItemUpdate({ icons: [] });
    setSelectedIconIndex(0);
  }

  return <>
    {!editMode &&
      <ItemView sectionId={sectionId} id={item.id} name={item.name} description={item.description} preview={item.preview} previewSource={item.previewSource} prices={item.prices} sizes={item.sizes} business={business}
        available={item.available} extras={!business?.orderingEnabled ? item.extras : undefined} currency={currency} onItemUpdate={onItemUpdate} setRemoveModal={setRemoveModal} onClick={onClick} />}

    {editMode &&
      <div className={`${styles.item} item`}>
        <div className="item-actions">
          <Tooltip title={item.available ? 'Hide' : 'Show'} placement="bottom">
            <Switch className={'scc'} defaultChecked={item.available} onChange={(available) => onItemUpdate({ available })} size={"small"} />
          </Tooltip>
          <Tooltip title="Delete" placement="bottom">
            <Button type={"link"} onClick={(e) => { e.stopPropagation(); setRemoveModal(true) }}>
              <DeleteOutlined />
            </Button>
          </Tooltip>
          <Tooltip title="Collapse Item" placement="bottom">
            <UpOutlined className={'close-icon'} onClick={(e) => { e.stopPropagation(); onCancel(); }} />
          </Tooltip>
        </div>

        <div className="name-icon-actions">
          <div>
            <Input size="large" className={'name'} defaultValue={item.name} placeholder={'Item Name'} autoFocus onBlur={(ev) => onItemUpdate({ name: ev.target.value })} />
            <div className='current-icons'>
              {item.icons && item.icons.map((i => {
                  return (<div className="current-icon" onClick={() => onCurrentIconClick(i)}>
                    {i.path ? 
                    <img className="icon-entry" src={getUploadedIconUrl(i)} alt={i.altText}></img>
                    :
                    <svg width="28px" height="28px" fill="#000000" className="icon-entry">
                      <use xlinkHref={getIconUrl(i.iconKey)}></use>
                    </svg>}
                  </div>)
                }))}
              {(!item.icons || item.icons.length === 0 ) && <Button type={'default'} className={styles.defaultButton + ' btn-sm btn-sec-1'} onClick={() => onNewIconClick()}>
                + Icon
              </Button>}
              {item.icons && item.icons.length > 0 && item.icons.length < maxMenuItemIcons && <Button type={'default'} className={styles.defaultButton + ' btn-sm btn-sec-1'} style={{padding: '0', width: '34px'}} onClick={() => onNewIconClick()}>
                +
              </Button>}
            </div>
          </div>

          <Uploader
            className={'item'}
            hasDropdown={true}
            memberId={Storage.getMemberId()} imageType={ImageTypeEnum.MenuItem} widgetType={ImageUploaderTypeEnum.Card} path={item.preview} source={item.previewSource}
            addLabel={<div><PictureOutlined /><span>Add image</span></div>} changeLabel={'change'} removeItemImage={removeItemImage} onUploadSuccess={(preview, previewSource) => onItemUpdate({ preview, previewSource })}
            memberImages={memberImages} setMemberImages={setMemberImages} setSelectedMemberImage={setSelectedMemberImage} setAltTextModal={setAltTextModal}
          />
        </div>

        <Input.TextArea defaultValue={item.description} placeholder={'Description'} style={{ resize: 'none', marginTop: '15px' }} onBlur={(ev) => onItemUpdate({ description: ev.target.value })} />

        <section>
          <MenuItemSizes business={business} item={item} onItemUpdate={onItemUpdate} />
        </section>

        {business?.orderingEnabled ? (
          <><label>{'Modifiers'}</label>
            <section>
              <MenuItemModifiers item={item} currency={currency} business={business} onModifierAddOrEdit={onModifierAddOrEdit}
                onModifierRemove={onModifierRemove} existingModifiers={modifiers} />
            </section>
          </>
        ) : <Input.TextArea defaultValue={item.extras} maxLength={500} placeholder={'Extras'} style={{ resize: 'none', marginTop: '15px' }} onBlur={(ev) => onItemUpdate({ extras: ev.target.value })} />
        }
      </div>}

    <DeleteMenuDataModal visible={isRemoveModal} onConfirm={onRemove} onCancel={(e) => { e.stopPropagation(); setRemoveModal(false) }} type={MenuDataType.ITEM} />
    <MenuIconModal
      visible={isIconModal}
      onClose={() => setIsIconModal(false)}
      icons={item.icons ? item.icons : []}
      index={selectedIconIndex}
      setIndex={setSelectedIconIndex}
      updateIcon={updateItemIcon}
      deleteIcon={deleteItemIcon}
      clearIcons={clearAllItemIcons}
      setSelectedMemberImage={setSelectedMemberImage}
      setAltTextModal={setAltTextModal}
      memberIcons={memberIcons}
      setMemberIcons={setMemberIcons}
      activeMenuIcons={activeMenuIcons}
      updateMenuActiveIcons={updateMenuActiveIcons}
    />
  </>
}
