import * as React from 'react';
import {MenuDto, BusinessDto} from "../../gen/client";
import './MobilePreviewWrapper.scss'
import MobilePreview from "./MobilePreview";
import {clipboardCopy, getBusinessPageUrl} from "../../util/Utils";
import {Tooltip} from "antd";
import { CopyOutlined, EyeOutlined, LinkOutlined } from '@ant-design/icons';
import {useRecoilValue} from "recoil";
import {revisionState} from "../../store/atoms";
import {useEffect, useState} from "react";
import {EmptyBusiness} from "../../util/constants";

interface MobilePreviewWrapperProps {
  business: BusinessDto;
  menus: MenuDto[];
  isPublished: boolean;
}

export default function MobilePreviewWrapper({business, menus, isPublished}: MobilePreviewWrapperProps) {
  const revision = useRecoilValue(revisionState);
  const [revBusiness, setRevBusiness] = useState(EmptyBusiness);
  const [revMenus, setRevMenus] = useState([] as MenuDto[]);

  useEffect(() => {
    if (revision.business) {
      const biz = JSON.parse(revision.business);
      if (biz.config) {
        biz.config = JSON.parse(biz.config);
      }
      setRevBusiness(biz);
    }
    if (revision.menus) {
      setRevMenus(JSON.parse(revision.menus));
    }
  }, [revision]);

  function onCopy(ev: React.MouseEvent<HTMLElement>) {
    clipboardCopy(getBusinessPageUrl(business));

    const target = ev.target as HTMLElement;
    target.innerText = 'Copied!'

    setTimeout(function() {
			target.innerHTML = 'Copy'
		}, 3000)

    ev.stopPropagation();
  }

  return <div className={'menu-preview-wrapper'}>
    {isPublished && !revision.id && <><div className="actions">
        <h6><LinkOutlined /> <a href={getBusinessPageUrl(business)} target="_blank" rel="noreferrer">{getBusinessPageUrl(business)}</a></h6>
        <Tooltip title="Copy link" placement='bottom'>
          <div className="copy-btn" onClick={onCopy}><CopyOutlined/></div>
        </Tooltip>
      </div>
      <div className='views'>
        <h6><EyeOutlined/> {business.viewCount!} views</h6>
      </div>
    </>}
    {/*<h6 className="preview-label">Preview {revision.id ? `version saved on ${getRevisionSavedDate(revision)}` : ''}</h6>*/}
    <MobilePreview business={revision.id ? revBusiness : business} menus={revision.id ? revMenus : menus}/>
  </div>
};
