import * as React from 'react';
import {Modal} from "antd";
import './PublishModal.scss';
import {PublishFailReason} from "../../util/constants";

export interface CannotPublishModalProps {
  onOk: () => void;
  visible: boolean;
  reason: PublishFailReason
}

export default function CannotPublishModal({visible, reason, onOk}: CannotPublishModalProps) {
  return (
    <Modal visible={visible} onOk={onOk} onCancel={onOk} title={null} cancelButtonProps={{style: {display: 'none'}}}>
      <h2>Unable to Publish</h2>
      {reason === PublishFailReason.NO_MENUS && <p>Create a menu before you publish.</p>}
      {reason === PublishFailReason.NO_BUSINESS_INFO && <p>Please complete your Business Name, City and State before publishing.</p>}
      {reason === PublishFailReason.URL_TAKEN && <p>The business url is already in use. Edit your business name to a unique identifier.</p>}
    </Modal>
  );
};

