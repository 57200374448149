import * as React from "react";
import {Button, Modal} from "antd";
import {BusinessDto} from "../../../gen/client";
import {MenuItemModifier, MenuItemModifierOption, MenuNode} from "../../../domain/Menu";
import {useState} from "react";
import styles from "./MenuItemModifiers.module.scss";
import ItemModifier from "./MenuItemModifier";
import {MenuItemModifierDetail} from "../../../util/types";
import MenuItemModifiersPreview from "./MenuItemModifiersPreview";
import MenuItemModifierSelect from "./MenuItemModifierSelect";
import {uuid} from "../../../util/Utils";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";

interface MenuItemModifiersProps {
  item: MenuNode;
  business: BusinessDto;
  currency: string;
  existingModifiers: MenuItemModifierDetail[];
  onModifierAddOrEdit: (m: MenuItemModifier) => void;
  onModifierRemove: (m: MenuItemModifier, global?: boolean) => void;
}

export default function MenuItemModifiers({item, currency, business, existingModifiers, onModifierAddOrEdit, onModifierRemove}: MenuItemModifiersProps) {

  const [currentModifier, setCurrentModifier] = useState(new MenuItemModifier(''));
  const [isModifierBankModal, setModifierBankModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  function resetModifierToStore() {
    setCurrentModifier(new MenuItemModifier(''));
  }

  function triggerOpen() {
    resetModifierToStore();
    setModifierBankModal(true);
  }

  function triggerClose(closeModal: boolean) {
    resetModifierToStore();
    if (closeModal) {
      setModifierBankModal(false);
    }
  }

  function onModifierSave(m: MenuItemModifier) {
    resetModifierToStore();
    onModifierAddOrEdit(m);
    triggerClose(true);
  }

  function onTriggerEdit(m: MenuItemModifier) {
    setCurrentModifier(m);
    setIsEdit(true);
  }

  function onTriggerNew() {
    setCurrentModifier(newModifier());
    setIsEdit(false);
  }

  function newModifier() {
    return new MenuItemModifier(uuid(), '', 1, [new MenuItemModifierOption(uuid(), '', 1)]);
  }

  return (
    <div className={styles.modifiers}>
      <Button onClick={triggerOpen}>
        {'modifierBank'}
      </Button>

      <MenuItemModifiersPreview item={item} currency={currency} onModifierRemove={onModifierRemove} />

      <Modal className={'no-footer-modal'} visible={isModifierBankModal} onCancel={() => triggerClose(true)} width={720}>
        <div className={styles.addModifier}>
          <header>
            <h3>{!!currentModifier.id && (isEdit? <EditOutlined /> : <PlusOutlined />)} {!!currentModifier.id ? `${isEdit ? 'editModifier' : 'newModifier'}` : 'modifierBank'}</h3>
          </header>

          {!currentModifier.id &&
            <section>
              {!!existingModifiers.length && <MenuItemModifierSelect modifiers={existingModifiers} onSelect={onModifierSave} onEdit={onTriggerEdit} onRemove={(m) => onModifierRemove(m, true)} />}

              <Button type={'dashed'} className={styles.addButton} onClick={onTriggerNew}><PlusOutlined /> {'newModifier'}</Button>
            </section>
          }

          {!!currentModifier.id &&
            <ItemModifier key={'new'} edit={isEdit} value={currentModifier} business={business} currency={currency} onSave={onModifierSave} onCancel={() => triggerClose(false)} />
          }
        </div>
      </Modal>
    </div>
  );
}
