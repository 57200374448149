import {AxiosPromise} from "axios";
import {
  CustomerDto,
  CustomerResourceApi
} from "../gen/client";
import {apiConfig, ops} from "./ApiConfig";

const api = new CustomerResourceApi(apiConfig);

const customerApi = {
  createCustomer(dto: CustomerDto): AxiosPromise<CustomerDto> {
    return api.createCustomer(dto, ops());
  },

  getCustomer(customerId: string): AxiosPromise<CustomerDto> {
    return api.getCustomer(customerId, ops());
  },

  getCustomers(restaurantId: string): AxiosPromise<CustomerDto[]> {
    return api.getBusinessCustomers(restaurantId, ops());
  },

  searchCustomers(restaurantId: string, key: string): AxiosPromise<CustomerDto[]> {
    return api.searchCustomers(key, restaurantId, ops());
  }
};

export default customerApi;
