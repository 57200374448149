import {Input} from "antd";
import * as React from "react";
import {BusinessDto} from "../gen/client";
import "./Price.scss";

interface PriceProps {
  business?: BusinessDto;
  currency: string;
  value: string;
  className?: string;
  edit?: boolean;
  onChange?: (price: number) => void;
  onChangeText?: (price: string) => void;
}

export default function Price({business, value, className, edit, currency, onChange, onChangeText}: PriceProps) {

  return (
    <>
      {business && !business.orderingEnabled &&
      <>
        {edit && <Input type={"text"} size="large" defaultValue={value} placeholder={"Price"} onBlur={(ev) => onChangeText(ev.target.value)}/>}
        {!edit && value}
      </>}

      {/*{!business &&
      <>
        {!edit && <span className={`pc ${className || ""}`}>
            <span className={`c ${currency && currency.length > 1 ? "l" : ""}`}>
              {currency}
            </span>
          {value && typeof value === "string" ? parseFloat(value).toFixed(2) : (value || 0).toFixed(2)}
          </span>
        }
        {edit && <Input.Group compact className={'price'}>
          <Input
            addonBefore={currency}
            type={"number"}
            step={0.5}
            min={0}
            size="large"
            value={isNaN(value) ? null : value}
            placeholder={t("price")}
            onBlur={(ev) => onChange(!ev.target.value ? NaN : parseFloat(ev.target.value))}
          />
        </Input.Group>}
      </>
      }*/}
    </>
  );
}
