import {useState, useCallback, Dispatch, SetStateAction} from "react";
import qs from "qs";

const setQueryStringWithoutPageReload = (value: string) => {
  const url = window.location.protocol + "//" +  window.location.host + window.location.pathname + value;
  window.history.pushState({ path: url }, "", url);
};

const setQueryStringValue = (key: string, value: string, queryString = window.location.search ) => {
  const values = qs.parse(queryString.replace('?', ''));
  const newQsValue = qs.stringify({ ...values, [key]: value });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const getQueryStringValue = (key: string, queryString = window.location.search) => {
  if (queryString) {
    queryString = queryString.substr(1);
  }
  const values = qs.parse(queryString);
  return values[key];
};

function useQueryString<T>(key: string, initialValue: string = ''): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = useCallback(
    newValue => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value as unknown as T, onSetValue];
}

export default useQueryString;
