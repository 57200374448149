import * as React from 'react';
import {useEffect, useState} from "react";
import CustomerApi from "../../../api/CustomerApi";
import {handleServerError} from "../../../util/ErrorHandler";
import {CustomerDto} from "../../../gen/client";
import {Button, Skeleton} from "antd";
import Price from "../../Price";
import DateTimeStamp from "../../misc/DateTimeStamp";
import styles from "./CustomerDetails.module.scss";
import {PhoneOutlined} from "@ant-design/icons/lib";

interface CustomerDetailsProps {
  id: string;
}

export default function CustomerDetails({id}: CustomerDetailsProps) {

  const [customer, setCustomer] = useState(null as CustomerDto)
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!id) return;
    setIsLoading(true);
    CustomerApi.getCustomer(id).then(resp => {
      setIsLoading(false);
      setCustomer(resp.data);
    }).catch((error) => {
      handleServerError(error);
      setIsLoading(false);
    });
  }, [id]);

  return (<div>
    {isLoading && <Skeleton active/>}
    {!isLoading && !!customer && <div className={styles.customer}>
      <header>
        <h2>{customer.name}</h2>
        <h3><a href={`tel:${customer.phone}`}><PhoneOutlined/>{customer.phone}</a></h3>
        <h6>{customer.email}</h6>
      </header>

      <section>
        <label>{'Address'}</label>
        <p>{customer.address}</p>
      </section>

      <section>
        <label>{'Orders'}</label>
        {customer.orders.length > 0 &&
        <div className={styles.orders}>
          <header>
            <span>{'Id'}</span>
            <span>{'Placed on'}</span>
            <span className={styles.tr}>{'Total'}</span>
          </header>
          <ul>
            {customer.orders.map(it =>
              <li key={it.id}>
                <span>
                  <Button type={'link'} style={{padding: '0', height: 'auto'}}>#{it.friendlyId}</Button>
                </span>
                <DateTimeStamp date={new Date(it.submitDate)}/>
                <span className={styles.tr}>
                  <Price currency={it.currency} value={it.total + ''}/>
                </span>
              </li>)}
          </ul>
        </div>}

        {customer.orders.length === 0 &&
        <p>{'No orders'}</p>}
      </section>
    </div>}
  </div>);
};
