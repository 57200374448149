import * as React from 'react';
import './BusinessHoursModal.scss';
import {Modal} from "antd";
import {BusinessDto} from '../../gen/client';
import {useEffect, useState} from 'react';
import {parseHours} from '../../services/BusinessService';
import {BusinessHoursDto, WEEK} from '../../domain/BusinessHours';
import {dayHours, formatBusinessHour, isDayOpen, isNotEmptyObject} from '../../util/Utils';

export interface BusinessHoursModalProps {
  visible: boolean;
  business: BusinessDto;
  onClose: () => void;
}

const todayDayIdx = new Date().getDay() - 1;

export default function BusinessHoursModal({visible, business, onClose}: BusinessHoursModalProps) {

  const [hours, setHours] = useState(null as BusinessHoursDto);

  useEffect(() => {
    if (business && business.config && business.config.hours) {
      setHours(parseHours(business.config.hours));
    }
  }, [business]);

  return (
    <Modal className={'business-hours-modal'} visible={visible} onCancel={onClose}>
      <header><h2>Business Hours</h2></header>

      {isNotEmptyObject(hours) && <section>
        {WEEK.map((day, idx) => (
          <div key={idx} className={`entry ${idx === todayDayIdx ? 'act' : ''}`}>
            <span className={'day'}>{day}</span>
            <div className={'hours'}>
              {isDayOpen(hours, day) && dayHours(hours, day).map((hour, idx) => (
                <label key={idx}>{formatBusinessHour(hour.start, business.config.use24HourFormat)} - {formatBusinessHour(hour.end, business.config.use24HourFormat)}</label>
              ))}
              {!isDayOpen(hours, day) && <label>Closed</label>}
            </div>
          </div>
        ))}
      </section>}
    </Modal>
  );
};
