import './SelectableImage.scss'
import {Checkbox} from "antd";

interface SelectableImageProps {
  src: string;
  selected?: boolean;
  width?: number;
  onClick: () => void;
}

export default function SelectableImage({src, width, selected, onClick}: SelectableImageProps) {
  return <div className={`selectable-image ${selected ? 'selected' : ''}`}>
    {selected && <div className={'selected-overlay'}><Checkbox checked={true} /></div>}
    <img loading={'lazy'} width={width || 150} src={src} onClick={onClick} alt={''}/>
  </div>
}
