import * as React from 'react';
import {Modal} from "antd";
import './FirstTimePublishedModal.scss';
import {BusinessDto} from '../../gen/client';
import PartyIcon from '../misc/icons/PartyIcon';

export interface FirstTimePublishedModalProps {
  business: BusinessDto;
  onCancel: () => void;
}

export default function FirstTimePublishedModal({business, onCancel}: FirstTimePublishedModalProps) {
  function showShareDropdown() {
    onCancel();
    let shareTriggers = document.getElementsByClassName("ant-dropdown-trigger share-btn");
    for (let i = 0; i < shareTriggers.length; i++) {
      let trigger = shareTriggers[i] as HTMLElement;
      trigger.click();
    }
  }

  return (
    <Modal className={`first-time-published-modal`} okText={'Share'} cancelText={null} onOk={showShareDropdown} visible={true} onCancel={onCancel} closable={false}>
        <PartyIcon/>
        <h2>Your Online Menu has been published!</h2>
        <p>Share it with your customers via QR code, social media, and a branded Link Page.</p>
    </Modal>
  );
};
