import {FullRevisionDto, LocationDto, MenuDto, PublishedBusinessDataDto, RevisionDto} from '../gen/client';
import {AxiosPromise} from "axios";
import {
  BusinessDto,
  BusinessResourceApi,
  OrderDto, RatingDto, UpdateNotificationsDto
} from "../gen/client";
import {apiConfig, ops} from "./ApiConfig";
import {OrderListTab} from "../domain/Order";
import Storage from "../util/Storage";

const api = new BusinessResourceApi(apiConfig);

export const BusinessApi = {
  updateUrl(id: string, url: string): AxiosPromise<boolean> {
    return api.updateUrl(id, url, ops());
  },

  updateHours(id: string, hours: string, offset: number): AxiosPromise<boolean> {
    return api.updateHours(id, {hours, offset}, ops());
  },

  getOrders(id: string, tab: OrderListTab, page: number = 0): AxiosPromise<OrderDto[]> {
    return api.getBusinessOrders(id, tab, page, ops());
  },

  countOrders(id: string): AxiosPromise<{ [key: string]: number; }> {
    return api.countBusinessOrders(id, ops());
  },

  getPublishedBusinessAndMenus(url: string): AxiosPromise<PublishedBusinessDataDto> {
    return api.getPublishedBusinessAndMenus(url);
  },

  update(id: string, dto: BusinessDto, tkn: string = Storage.getToken()): AxiosPromise<BusinessDto> {
    return api.updateBusiness(id, dto, ops(tkn));
  },

  saveRevision(businessId: string): AxiosPromise<void> {
    return api.saveRevision(businessId, ops());
  },

  updateNotifications(id: string, dto: UpdateNotificationsDto): AxiosPromise<boolean> {
    return api.updateNotifications(id, dto, ops());
  },

  patchRating(businessId: string, dto: RatingDto): AxiosPromise<boolean> {
    return api.patchRating(businessId, dto, ops());
  },

  addRating(businessId: string, dto: RatingDto): AxiosPromise<boolean> {
    return api.addRating(businessId, dto, ops());
  },

  getRatings(businessId: string): AxiosPromise<RatingDto[]> {
    return api.getRatings(businessId, ops());
  },

  getPublicRatings(businessId: string): AxiosPromise<RatingDto[]> {
    return api.getPublicRatings(businessId, ops());
  },

  patch(id: string, dto: BusinessDto): AxiosPromise<boolean> {
    return api.patchBusiness(id, dto, ops());
  },

  searchProduct(id: string, query: string): AxiosPromise<MenuDto[]> {
    return api.searchProduct(id, query, ops());
  },

  canPublishChanges(id: string): AxiosPromise<boolean> {
    return api.canPublishChanges(id, ops());
  },

  publishChanges(id: string): AxiosPromise<BusinessDto> {
    return api.publishChanges(id, ops());
  },

  unpublishChanges(id: string): AxiosPromise<void> {
    return api.unpublishChanges(id, ops());
  },

  increaseViewCount(id: string) {
    return api.increaseViewCount1(id, ops());
  },
  getLocation(locationId: number): AxiosPromise<LocationDto> {
    return api.getLocationDetails(locationId, ops());
  },
  updateLocation(locationId: number, dto: LocationDto): AxiosPromise<LocationDto> {
    return api.updateLocation(locationId, dto, ops());
  },
  getPublicLocation(locationId: number): AxiosPromise<LocationDto> {
    return api.getPublicLocationDetails(locationId);
  },
  checkURLValidity(url: string): AxiosPromise<boolean> {
    return api.checkURLValidity(url, ops());
  },
  getRevisions(id: string): AxiosPromise<RevisionDto[]> {
    return api.getBusinessRevisions(id, ops());
  },
  getRevision(businessId: string, revisionId: number): AxiosPromise<FullRevisionDto> {
    return api.getBusinessRevision(businessId, revisionId, ops());
  },
  applyRevision(id: string, revisionId: number): AxiosPromise<void> {
    return api.useBusinessRevision(id, revisionId, ops());
  }
};
