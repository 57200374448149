import {Input, Switch} from "antd";
import * as React from "react";
import {ReactNode, useEffect, useState} from "react";
import './SettingsContactLink.scss';
import PhoneInput from "../../misc/PhoneInput";

interface SettingsContactLinkProps {
  icon: ReactNode;
  text: string;
  enabled: boolean;
  onSwitch: (checked: boolean) => void;
  allowEdit?: boolean;
  isPhone?: boolean;
  country?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
}

export default function SettingsContactLink({icon, text, enabled, onSwitch, allowEdit, isPhone, country, placeholder, onChange}: SettingsContactLinkProps) {
  const [value, setValue] = useState(text);

  useEffect(() => {
    setValue(text);
  }, [text]);

  function emitChange() {
    onChange(value);
  }

  return <div className={'contact-link'}>
    {!allowEdit && <div className={'icon'}>{icon}</div>}
    {!allowEdit && <div className={'text'}>{text}</div>}
    {allowEdit && !isPhone && <Input addonBefore={icon} value={value} placeholder={placeholder} size="large" onChange={e => setValue(e.target.value)} onBlur={emitChange}/>}
    {allowEdit && isPhone && <PhoneInput value={value} onChange={onChange} country={country} showAddon/>}
    <div className={'trigger'}><Switch checked={enabled} onChange={onSwitch} disabled={!value}/></div>
  </div>
}
