
import {getImagePath} from "../../../services/ImageService";
import styles from './ItemPreview.module.scss';
import {ImageDtoSourceEnum} from "../../../gen/client";
import {isOOImage} from "../../../services/MenuService";
import { PictureOutlined } from "@ant-design/icons";

interface ItemPreviewProps {
  preview: string;
  previewSource: ImageDtoSourceEnum;
}

export default function ItemPreview({preview, previewSource}: ItemPreviewProps) {

  return preview ?
    <div className={styles.preview} style={{backgroundImage: `url(${getImagePath(preview, isOOImage(previewSource))})`}}/> :
    <div className={`${styles.preview} ${styles.no}`}><PictureOutlined /></div>;
}
