import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import './DashboardDesign.scss';
import {
  BusinessCfg,
  BusinessDto,
  ImageDto,
  ImageDtoSourceEnum,
  ImageTypeEnum,
  MemberColorDto,
  MenuDto
} from "../../gen/client";
import {Colors} from "../../util/constants";
import {ColorPair} from "../../util/types";
import DesignColorPicker from "./DesignColorPicker";
import {handleServerError} from "../../util/ErrorHandler";
import MobilePreviewWrapper from "./MobilePreviewWrapper";
import {ImageUploaderTypeEnum, Uploader} from "../Uploader";
import BrandsApi from "../../api/BrandsApi";
import {getImagePath} from "../../services/ImageService";
import {PlusIcon} from "../misc/icons/PlusIcon";
import StopIcon from "../misc/icons/StopIcon";
import {getBusinessPageUrl, getTimezoneOffset} from '../../util/Utils';
import {parseColorPairs} from "../../services/BusinessService";
import PublishButton from "../PublishButton";
import {useRecoilState, useRecoilValue} from "recoil";
import {currentLocation, memberState} from "../../store/atoms";
import SettingsAnnouncements from './settings/SettingsAnnouncements';
import {AnnouncementBanner} from '../../domain/AnnouncementBanner';

interface DashboardDesignProps {
  business: BusinessDto;
  menus: MenuDto[];
  saving: boolean;
  publishing: boolean;
  isPublished: boolean;
  memberStatus: number;
  checkPublishingPossibility: () => void;
  onBusinessUpdate: (path: BusinessDto) => void;
}

export default function DashboardDesign({business, menus, saving, publishing, isPublished, memberStatus, checkPublishingPossibility, onBusinessUpdate}: DashboardDesignProps) {
  const member = useRecoilValue(memberState);
  const [location] = useRecoilState(currentLocation);

  const [logos, setLogos] = useState([] as ImageDto[]);
  const [colors, setColors] = useState([] as MemberColorDto[]);
  const colorPairs = useRef([] as ColorPair[]);
  const selectedColorPairIdx = useRef(-1);
  const containerRef = useRef(null as HTMLElement);
  const bannerCfg = useRef({} as AnnouncementBanner);

  useEffect(() => {
    let cp = parseColorPairs(business?.config?.colorPairs);
    if (!cp || !cp.length) cp = null;

    colorPairs.current = cp || [...Colors];

    bannerCfg.current = JSON.parse(business?.config?.announcementBanner || "{}");

    if (!bannerCfg.current.backgroundColor) {
      bannerCfg.current.backgroundColor = '#000000';
    }
    if (!bannerCfg.current.textColor) {
      bannerCfg.current.textColor = '#FFFFFFFF';
    }
    
  }, [business]);

  const fetchLogos = useCallback(() => {
    if (!member.id || !location.internalId) return;

    BrandsApi.getLogos(location.internalId).then(resp => {
    // MemberApi.getUploads(member.id, ImageTypeEnum.BusinessLogo).then(resp => {
      setLogos(resp.data);
    }).catch(handleServerError);
  }, [member.id, location.internalId]);

  const fetchColors = useCallback(() => {
    if (!member.id || !location.internalId) return;

    BrandsApi.getColors(location.internalId).then(resp => {
    // MemberApi.getColors(member.id).then(resp => {
      setColors(resp.data);
    }).catch(handleServerError);
  }, [member.id, location.internalId]);

  useEffect(fetchLogos, [fetchLogos]);
  useEffect(fetchColors, [fetchColors]);

  function onConfigChange(values: Partial<BusinessCfg>, type: string = null) {
    let updated: BusinessDto = {
      config: {
        ...values
      }
    };

    if (selectedColorPairIdx.current > -1 && type) {
      const cp = colorPairs.current;
      cp[selectedColorPairIdx.current][type as keyof ColorPair] = Object.values(values)[0] as string;

      updated = {
        ...updated,
        config: {
          ...updated.config,
          colorPairs: JSON.stringify(cp)
        }
      }
    }
    onBusinessUpdate(updated);
  }

  function onPairClick(pair: ColorPair, idx: number) {
    selectedColorPairIdx.current = idx;
    onConfigChange({
      businessNameColor: pair.one,
      bannerColor: pair.two,
      bannerTextColor: pair.three,
      menuTextColor: pair.four
    });
  }

  function onUploadSuccess(path: string, source: ImageDtoSourceEnum) {
    setLogos([{
      path: path,
      source
    }, ...logos]);
  }

  useEffect(() => {
    centerElement();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', centerElement);

    return () => {
      window.removeEventListener('resize', centerElement);
    };
  }, []);

  const centerElement = () => {
    if (!containerRef.current) return;

    const width = document.getElementsByClassName('menu-preview-wrapper')[0].getBoundingClientRect().width;
    containerRef.current.style.marginRight = parseInt(width + '') + 'px';
  }


  function setContainerRef(el: HTMLElement) {
    containerRef.current = el;
    centerElement();
  }

  
  function onConfigChangeByAnnouncement(values: Partial<BusinessCfg>) {
    let updated: BusinessDto = {
      ...business,
      config: {
        ...business.config,
        timeZoneOffset: getTimezoneOffset(),
        ...values
      }
    };

    onBusinessUpdate(updated);
  }


  function onAnnouncementConfigChange(values: Partial<AnnouncementBanner>) {
    onConfigChangeByAnnouncement({
     announcementBanner: JSON.stringify({...bannerCfg.current, ...values})
    });
  }

  return (
    <div>
      {isPublished && <div className={'design-site-link'}>
          <h6>Site link: <a href={getBusinessPageUrl(business)} target="_blank"
                            rel="noreferrer">{getBusinessPageUrl(business)}</a></h6>
          <PublishButton business={business} saving={saving} publishing={publishing} isPublished={isPublished} memberStatus={memberStatus} checkPublishingPossibility={checkPublishingPossibility} />
      </div>}
      
      <div className={'dashboard-design'} ref={setContainerRef}>
        <section>
          <div className={'entry'}>
            <h3>Logo</h3>
            <ul className={'logos'}>
              <li className={'upload'}>
                <Uploader memberId={member.id} addLabel={<PlusIcon/>} imageType={ImageTypeEnum.BusinessLogo}
                          widgetType={ImageUploaderTypeEnum.Card} onUploadSuccess={onUploadSuccess} hidePreview hideModal/>
              </li>
              {logos.map(it =>
                <li className={`logo ${business?.config?.logo?.indexOf(it.path) > -1 ? 'act' : ''}`} key={it.path}
                    onClick={() => onConfigChange({logo: getImagePath(it.path, it.source === ImageDtoSourceEnum.Ordering)})}>
                  <img src={getImagePath(it.path, it.source === ImageDtoSourceEnum.Ordering)} alt={''}/>
                </li>)}
              <li className={`remove ${!business?.config?.logo ? 'act' : ''}`}
                  onClick={() => onConfigChange({logo: ''})}>
                <StopIcon/>
              </li>
            </ul>
          </div>

          <div className={'entry'}>
            <h3>Colors</h3>
            <ul className={'colors'}>
              {colorPairs.current.map((it, idx) => <li key={idx} onClick={() => onPairClick(it, idx)}>
                <span style={{backgroundColor: it.one}}/>
                <span style={{backgroundColor: it.two}}/>
              </li>)}
            </ul>

            <div className={'sections'}>
              <ul>
                <li>
                  <DesignColorPicker label={'Business name'} color={business.config?.businessNameColor || '#000000'}
                                     memberColors={colors}
                                     onChange={v => onConfigChange({businessNameColor: v}, 'one')}/>
                </li>
                <li>
                  <DesignColorPicker label={'Banner'} color={business.config?.bannerColor || '#efefef'}
                                     memberColors={colors} onChange={v => onConfigChange({bannerColor: v}, 'two')}/>
                </li>
                <li>
                  <DesignColorPicker label={'Banner text'} color={business.config?.bannerTextColor || '#000000'}
                                     memberColors={colors} onChange={v => onConfigChange({bannerTextColor: v}, 'three')}/>
                </li>
                <li>
                  <DesignColorPicker label={'Menu text'} color={business.config?.menuTextColor || '#000000'}
                                     memberColors={colors} onChange={v => onConfigChange({menuTextColor: v}, 'four')}/>
                </li>
              </ul>
            </div>
          </div>
          <div className={'entry'}> 
            <SettingsAnnouncements cfg={bannerCfg.current} memberColors={colors} onAnnouncementConfigChange={onAnnouncementConfigChange} />
          </div>
        </section>
        <MobilePreviewWrapper business={business} menus={menus} isPublished={isPublished}/>
      </div>
    </div>
  );
};
