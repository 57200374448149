import * as React from 'react';
import {useEffect, useState} from 'react';
import "../components/dashboard/ShareDropdown.scss";
import {
    clipboardCopy,
    getBusinessPageUrl,
    getBusinessPageQRUrl,
    getLPDashboardLink,
    getLPMainAppLink,
    getQRCodeUrl,
    isStage,
    isProd
} from '../util/Utils';
import {LinkOutlined, QrcodeOutlined, LeftOutlined} from "@ant-design/icons";
import { BusinessDto, QRCodeDto } from '../gen/client';
import fb_logo_path from "../img/fb_logo.png";
import lp_logo_path from "../img/lp_logo.svg";
import MemberApi from '../api/MemberApi';
import { handleServerError } from '../util/ErrorHandler';
import Loader from './misc/Loader';
import QRCode from "qrcode.react";
import QRCodeApi from '../api/QRCodeApi';
import { Button } from 'antd';
import {useRecoilValue} from "recoil";
import {currentLocation, memberState} from "../store/atoms";
import { MEMBER_STATUS } from '../util/constants';
import { RoutesEnum } from '../RoutesEnum';
import QRLimitModal from './QRLimitModal';
// import ProPlanModal from './ProPlanModal';

const shareModes = {
    linkShare: 'link',
    qrCode: 'qrcode'
}

export interface ShareDropdownProps {
    business: BusinessDto;
    memberStatus: number;
    shareClose: () => void;
}

export default function ShareDropdown({business, memberStatus, shareClose} : ShareDropdownProps) {
    const [loading, setLoading] = useState(false);
    const [shareMode, setShareMode] = useState('');
    const [dropContent, setDropContent] = useState(<></>);
    const [qrLimitModal, setQRLimitModal] = useState(false);
    const member = useRecoilValue(memberState);
    const [qrCode, setQrCode] = useState({} as QRCodeDto);
    const [qrCodeExists, setQrCodeExists] = useState(false);
    const location = useRecoilValue(currentLocation);

    function onShareClick(e : any, mode : string) {
        e.stopPropagation();
        setShareMode(mode);
    }

    useEffect(() => {
        function copyLink(e: any) {
            e.stopPropagation();
            let copyBtn = document.getElementsByClassName("link-btn")[0];
            clipboardCopy(getBusinessPageUrl(business));
            copyBtn.innerHTML = "Copied!";
            setTimeout(() => {
                copyBtn.innerHTML = "Copy";
            }, 3000);
        }
    
        function shareToQR() {
            if (!member.id) return;
            setLoading(true);
            setShareMode(shareModes.qrCode);

            QRCodeApi.countMemberQRCodes(member.id).then(resp => {
                const codes = resp.data;
                if (Number.parseInt(codes) >= 1 && memberStatus !== MEMBER_STATUS.ACTIVE) {
                    shareClose();
                    setQRLimitModal(true);

                } else {
                    QRCodeApi.onlineMenuHasQRCode(business.id).then((resp) => {
                        if (resp.data) {
                            let qr = { id : resp.data } as QRCodeDto
                            setQrCode(qr)
                            setQrCodeExists(true);
                            setLoading(false);
                        } else {
                            QRCodeApi.createQRCode({ 
                                memberId: member.id,
                                locationId: Number(business.id), 
                                name: 'Online Menus for ' + location.name,
                                type: 'online-menu', 
                                url: getBusinessPageQRUrl(business.id),
                            }).then((resp2) => {
                                let qr = resp2.data;
                                setQrCode(qr)
                                setQrCodeExists(false);
                                setLoading(false);
                            }).catch(() => {
                                setLoading(false);
                            });
                        }
                    })
                }
            }).catch(handleServerError);

            // var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            //    navigator.userAgent &&
            //    navigator.userAgent.indexOf('CriOS') === -1 &&
            //    navigator.userAgent.indexOf('FxiOS') === -1;

            // if (isSafari) {
            //     let newTab = window.open();
            //     MemberApi.getQRCodes(memberId).then(resp => {
            //         const codes = resp.data;
            //         const path = isStage() ? RoutesEnum.MyStuffStaging : RoutesEnum.MyStuff;
            //         if (codes.length >= 1 && memberStatus !== MEMBER_STATUS.ACTIVE) {
            //             newTab.close();
            //             shareClose();
            //             setQRLimitModal(true);
            //         } else {
            //             newTab.location.href = path + '?qr-code-type=online-menu&qr-code-create=true#qr-codes';
            //         }
            //     }).catch(handleServerError);
            // } else {
            //     MemberApi.getQRCodes(memberId).then(resp => {
            //         const codes = resp.data;
            //         const path = isStage() ? RoutesEnum.MyStuffStaging : RoutesEnum.MyStuff;
            //         if (codes.length >= 1 && memberStatus !== MEMBER_STATUS.ACTIVE) {
            //             shareClose();
            //             setQRLimitModal(true);
            //         } else {
            //             window.open(path + '?qr-code-type=online-menu&qr-code-create=true#qr-codes', '_blank');
            //         }
            //     }).catch(handleServerError);
            // }
        }
    
        function shareToFb() {
            window.open(`https://www.facebook.com/sharer.php?u=${getBusinessPageUrl(business)}`, '_blank');
        }

        function shareToLp() {
            let newTab = window.open();
            MemberApi.getLandingPages(member.id).then(resp => {
            const landingPages = resp.data;

            if (landingPages.length > 1) {
                newTab.location.href = getLPMainAppLink();
            } else {
                let id = 'new';
                if (landingPages.length === 1) {
                id = landingPages[0].id;
                }
                newTab.location.href = getLPDashboardLink(business.id, id);
            }
            }).catch(handleServerError);
        }

        let toSet = <div className="dropContent">
                <h4>Share My Online Menu</h4>
                <ul>
                    <li>
                        <div className="share-option" onClick={(e) => onShareClick(e, shareModes.linkShare)}>
                            <div className="icon-container fb">
                            <LinkOutlined/> 
                            </div>
                            Share<br/>Link
                        </div>
                    </li>
                    <li>
                        <div className="share-option qr" onClick={() => shareToQR()}>
                            <div className="icon-container">
                                <QrcodeOutlined/> 
                            </div>
                            QR<br/>Code
                        </div>
                    </li>
                    <li>
                        <div className="share-option qr" onClick={() => shareToFb()}>
                            <div className="icon-container">
                                <img src={fb_logo_path} alt=""/> 
                            </div>
                            Facebook<br/>Post
                        </div>
                    </li>
                    <li>
                        <div className="share-option qr" onClick={() => shareToLp()}>
                            <div className="icon-container">
                                <img src={lp_logo_path} alt=""/> 
                            </div>
                            Link<br/>Page
                        </div>
                    </li>
                </ul>
            </div>;

        switch(shareMode) {
            case shareModes.linkShare:
                toSet = <div className="dropContent sub-menu">
                    <div className="back-btn" onClick={(e) => onShareClick(e, '')}><LeftOutlined/></div>
                    <h4 className="mode-title">Share My Online Menu</h4>
                    <div className="link-block">
                        <LinkOutlined/>
                        <span className="link-input">{getBusinessPageUrl(business)}</span>
                        <div className="link-btn" onClick={(e) => copyLink(e)}>Copy</div>
                    </div>
                </div>
                break;

            case shareModes.qrCode:
                toSet = <div className="dropContent sub-menu">
                    <div className="back-btn" onClick={(e) => onShareClick(e, '')}><LeftOutlined/></div>
                    <h4 className="mode-title">Share My Online Menu</h4>

                    { loading ? <Loader /> : 
                        <div className="qr-code-block">
                            <QRCode id={'qr-main'} size={90} value={getQRCodeUrl(qrCode.id)} onClick={() => window.open(getQRCodeUrl(qrCode.id))} className="qr-code"/>

                            <div>
                                { qrCodeExists 
                                    ? <p>
                                        This QR Code is already saved to your library.<br/>
                                        Ready to put it in a template?
                                    </p>
                                    
                                    : <p>
                                        Your QR Code is saved to your library!<br/>
                                        Ready to put it in a template?
                                    </p>
                                }

                                <Button type="primary" href={(isStage() ? RoutesEnum.StagingDomain : (isProd() ? RoutesEnum.ProdDomain : "")) + "/template/qr.html?qrCodeToAdd=" + qrCode.id} target="_blank">
                                    Find a Template
                                </Button>
                            </div>
                        </div>
                    }
                </div>
                break;
                
        }

        setDropContent(toSet);
    }, [shareMode, business, memberStatus, member.id, shareClose, loading, qrCodeExists, qrCode, location.name])

    return (
        <>
            <div className={"overlay"} onClick={() => shareClose()} />
            {dropContent}
            <QRLimitModal visible={qrLimitModal} onCancel={() => setQRLimitModal(false)}/>
            {/* <ProPlanModal visible={qrLimitModal} onClose={() => setQRLimitModal(false)} /> */}
        </>
    );
}
