import * as React from 'react';
import {format} from 'date-fns'

interface FriendlyDateProp {
  date: Date;
  withYear?: boolean;
}

const FORMAT = 'dd MMM, HH:mm';
const YEAR_FORMAT = 'dd MMM yyyy, HH:mm';

export default function DateTimeStamp({date, withYear = false}: FriendlyDateProp) {
  let val = '';
  try {
    val = format(date, withYear ? YEAR_FORMAT : FORMAT);
  } catch (ex) {
    console.error(ex);
  }

  return (
    <span className={'fdt'}>{val}</span>
  );
};

