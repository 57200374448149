import {BusinessDto} from "../gen/client";
import {DEFAULT_BG_COLOR, DEFAULT_TEXT_COLOR} from "../util/constants";

export function menuTextColor(business: BusinessDto): { color: string } {
  return {
    color: business?.config?.menuTextColor || DEFAULT_TEXT_COLOR
  }
}

export function businessNameColor(business: BusinessDto): { color: string } {
  return {
    color: business?.config?.businessNameColor || DEFAULT_TEXT_COLOR
  }
}

export function bannerBgColor(business: BusinessDto): { backgroundColor: string } {
  return {
    backgroundColor: business?.config?.bannerColor || DEFAULT_BG_COLOR
  }
}

export function scrollMenuStyle(business: BusinessDto): { color: string, backgroundColor: string } {
  return {
    color: business?.config?.bannerTextColor || DEFAULT_TEXT_COLOR,
    backgroundColor: business?.config?.bannerColor || DEFAULT_BG_COLOR
  }
}

export function buttonStyles(business: BusinessDto, active: boolean): { color: string, borderBottom: string, fontWeight: number, borderRadius: number } {
  return {
    color: business?.config?.bannerTextColor || DEFAULT_TEXT_COLOR,
    fontWeight: active ? 900 : 400,
    borderBottom: active ? `2px solid ${business?.config?.bannerTextColor || DEFAULT_TEXT_COLOR}` : '',
    borderRadius: 0
  }
}
