import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import "./Taxes.scss";
import {CustomTaxDto, CustomTaxDtoTypeEnum, BusinessDto} from "../../../gen/client";
import {Button, Card, Form, Input, Modal, Popconfirm, Select, Spin} from "antd";
import TaxApi from "../../../api/TaxApi";
import {AxiosError} from "axios";
import {handleServerError} from "../../../util/ErrorHandler";
import {LoadingOutlined} from "@ant-design/icons/lib";

interface TaxesProps {
  business: BusinessDto;
}

const {Item} = Form;

export default function Taxes({business}: TaxesProps) {

  const [isModal, setIsModal] = useState(false);
  const [taxes, setTaxes] = useState([] as CustomTaxDto[]);
  const [loading, setLoading] = useState(true);
  const [removing, setRemoving] = useState(false);
  const [saving, setSaving] = useState(false);

  const [id, setId] = useState(null);
  const [type, setType] = useState(CustomTaxDtoTypeEnum.PERCENT);
  const [label, setLabel] = useState('');
  const [value, setValue] = useState(null as number);

  const fetchTaxes = useCallback(() => {
    setLoading(true);
    TaxApi.getTaxes(business.id).then(it => {
      setTaxes(it.data);
      setLoading(false);
    }).catch((err: AxiosError) => {
      setLoading(false);
      handleServerError(err);
    });
  }, [business]);

  useEffect(fetchTaxes, [fetchTaxes]);

  function showEditModal(tax: CustomTaxDto) {
    setId(tax.id);
    setType(tax.type);
    setLabel(tax.label);
    setValue(tax.value);
    setIsModal(true);
  }

  function showCreateModal() {
    setId(null);
    setLabel('');
    setValue(null);
    setIsModal(true);
  }

  function remove(tax: CustomTaxDto) {
    setRemoving(true);
    TaxApi.deleteTax(business.id, tax.id).then(() => {
      setRemoving(false);
      setTaxes(taxes.filter(it => it.id !== tax.id));
    }).catch((err: AxiosError) => {
      setRemoving(false);
      handleServerError(err);
    });
  }

  function save() {
    setSaving(true);

    const promise = !!id ?
      TaxApi.updateTax(business.id, id, {label, type, value}) :
      TaxApi.createTax(business.id, {label, type, value});

    promise.then(() => {
      fetchTaxes();
      setIsModal(false);
      setSaving(false);
    }).catch((err: AxiosError) => {
      setSaving(false);
      handleServerError(err);
    });
  }

  let list = null;
  if (loading) {
    list = <Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>
  } else if (taxes.length > 0) {
    list = <>
      <hr/>
      <ul className={'od-ev'}>
        {taxes.map((it, idx) =>
          <li className={idx % 2 === 0 ? 'odd' : ''} key={it.id}>
            <span className={'lbl'}>{it.label}</span>
            <span className={'val'}>
                  {it.type === CustomTaxDtoTypeEnum.VALUE ? business.config.currency : ''}
              {it.value}
              {it.type === CustomTaxDtoTypeEnum.PERCENT ? '%' : ''}
                </span>

            <span className={'act'}>
                  <Button size={'small'} type={'primary'} onClick={() => showEditModal(it)}>{'Edit'}</Button>

                  <Popconfirm title={'Are you sure?'} okText={'yes'} cancelText={'no'} onConfirm={() => remove(it)}>
                    <Button loading={removing} size={'small'} danger>{'Delete'}</Button>
                  </Popconfirm>
                </span>
          </li>)
        }
      </ul>
    </>
  }

  return (
    <div className={'res-tax'}>
      <Card>
        <header className={'c'}>
          <h3>{'Taxes'}</h3>
          <h5>{'Add custom taxes'}</h5>
        </header>
        <Button type={'primary'} size={'large'} onClick={showCreateModal}>{'Add'}</Button>
        {list}
      </Card>


      <Modal
        className={'tax-modal'}
        visible={isModal}
        onCancel={() => setIsModal(false)}>
        <h2>{!!id ? 'Edit tax' : 'Add a tax'}</h2>

        <div className={'ant-form'}>
          <Item>
            <label>{'Tax name'}</label>
            <Input size={'large'} type={'text'} placeholder={'Tax name'} value={label} onChange={ev => setLabel(ev.target.value)}/>
          </Item>

          <Item>
            <label>{'Tax value'}</label>
            <Input.Group compact>
              <Input type={'number'} min={0} step={.01} size="large" value={value} placeholder={'Tax value'} onChange={ev => setValue(parseFloat(ev.target.value))}/>
              <Select value={type} onChange={val => setType(val)} size={'large'}>
                <Select.Option value={CustomTaxDtoTypeEnum.PERCENT}>%</Select.Option>
                <Select.Option value={CustomTaxDtoTypeEnum.VALUE}>{business.config && business.config.currency ? business.config.currency : '$' }</Select.Option>
              </Select>
            </Input.Group>
          </Item>

          <Button size={'large'} type={'primary'} onClick={save} loading={saving}>{'Save'}</Button>
        </div>

      </Modal>
    </div>
  );
};
