import {BusinessHoursDto, Day, getDayOfWeek} from "../domain/BusinessHours";
import moment, {Moment} from "moment";
import {
  HOUR_FORMAT_24
} from "./constants";

const FF = 'HHmm';

export class BusinessHoursCalculator {
  hours: BusinessHoursDto;

  constructor(hours: BusinessHoursDto) {
    this.hours = hours;
  }

  nextOpenTime(sameDayFormat: string, nextDayFormat: string) {
    // check valid interval for today
    const today = this.getTodayHours();
    if (today.open) {
      const remainingIntervalsToday = today.hours.filter(i => {
        const start = parseInt(moment(i.start, HOUR_FORMAT_24).format(FF));
        const now = moment();
        const nowF = parseInt(now.format(FF));

        return start >= nowF;
      });

      if (remainingIntervalsToday.length) {
        return moment(remainingIntervalsToday[0].start, HOUR_FORMAT_24).format(sameDayFormat);
      }
    }

    // next open time is on another day
    const [day, addedDays] = this.getNextOpenDay();
    const time = moment(day.hours[0].start, HOUR_FORMAT_24).add(addedDays, 'day');

    return time.format(nextDayFormat);
  }

  nextClosingTime(format: string) {
    const today = this.getTodayHours();
    const yesterday = this.getYesterdayHours();

    let intervals = null;
    if (today.open) {
      intervals = this.sameDay(today);
      if (intervals.length) {
        return moment(intervals[0].end, HOUR_FORMAT_24).format(format);
      }
    }

    if (yesterday.open) {
      intervals = this.previousDay(yesterday);
      if (intervals.length) {
        return moment(intervals[0].end, HOUR_FORMAT_24).format(format);
      }
    }
    return null;
  }

  isOpen(): boolean {
    const today = this.getTodayHours();
    const yesterday = this.getYesterdayHours();

    let intervals = null;
    if (today.open) {
      intervals = this.sameDay(today);
      if (intervals.length) return true;
    }

    if (yesterday.open) {
      intervals = this.previousDay(yesterday);
      return !!intervals.length;
    }

    return false;
  }

  private sameDay(day: Day, now: Moment = moment()) {
    if (!day || !day.hours) return [];

    return day.hours.filter(i => {
      const start = parseInt(moment(i.start, HOUR_FORMAT_24).format(FF));
      let end = parseInt(moment(i.end, HOUR_FORMAT_24).format(FF));
      const nowF = parseInt(now.format(FF));

      if (end < start) {
        end += 2400;
      }

      return end >= start && nowF <= end && nowF >= start;
    });
  }

  private previousDay(day: Day, now: Moment = moment()) {
    if (!day || !day.hours) return [];

    return day.hours.filter(i => {
      const start = parseInt(moment(i.start, HOUR_FORMAT_24).format(FF));
      let end = parseInt(moment(i.end, HOUR_FORMAT_24).format(FF));
      let nowF = parseInt(now.format(FF));

      if (start <= 1200 && end <= 1200 && start >= nowF && nowF <= end) {
        return true;
      }

      let dayFlipped = false;
      if (end < start && start >= 1200) {
        dayFlipped = true;
        end += 2400;
      }

      if (start >= 1200 && end >= 1200 && nowF <= 1200) {
        dayFlipped = true;
        nowF += 2400;
      }

      return start <= nowF && nowF <= end && dayFlipped;
    });
  }

  getTodayHours(): Day {
    const day = getDayOfWeek() as keyof BusinessHoursDto;
    return this.hours[day] || new Day();
  }

  getYesterdayHours(): Day {
    const day = getDayOfWeek(this.getTodayIdx() - 1) as keyof BusinessHoursDto;
    return this.hours[day] || new Day();
  }

  private getTodayIdx(): number {
    return new Date().getDay();
  }

  private getNextOpenDay(nowDof: number = new Date().getDay()): [Day, number] {
    if (!Object.keys(this.hours).length) return [new Day(), 0];

    let addedDays = 1;
    let day = getDayOfWeek(++nowDof) as keyof BusinessHoursDto;
    while (!day || !this.hours[day] || !this.hours[day].open) {
      if (nowDof === 7) {
        nowDof = 0;
      } else {
        nowDof++;
        addedDays++;
      }
      day = getDayOfWeek(nowDof) as keyof BusinessHoursDto;
    }

    return [this.hours[day], addedDays];
  }
}
