import {MenuNode} from "../domain/Menu";
import {Button, message} from "antd";
import * as React from "react";
import {getUndoRemovalMessage} from "../util/Messages";

export interface HistoryStatus {
  state: State;
  action: Action;
}

export interface StackEntry {
  action: Action,
  payload: any
}

export enum Action {
  RemoveMenu, RemoveSection, RemoveItem
}

export enum State {
  Empty, NotEmpty
}

const UndoRemoveNotificationKey = 'undo-notification';

let stack = [] as StackEntry[];

export const History = {
  push(action: Action, payload: any) {
    stack[0] = {action, payload};
  },

  last(): StackEntry {
    if (stack.length === 0) return null;
    return stack[stack.length - 1];
  },

  clear() {
    stack = [];
  }
};

export function undoNodeRemoval(entry: StackEntry, nodes: MenuNode[]) {
  if (entry.action === Action.RemoveItem) {
    const {sectionId, index, node} = entry.payload;
    const section = nodes.find(section => section.id === sectionId);
    section.nodes.splice(index, 0, node);
  } else if (entry.action === Action.RemoveSection) {
    const {index, node} = entry.payload;
    nodes.splice(index, 0, node);
  }
  return nodes;
}

export function showUndoNotification(action: Action, onClick: () => void) {
  message.info({
    content: (<>{getUndoRemovalMessage(action)} <Button type={'default'} size='small' onClick={onClick}>Undo</Button></>),
    duration: 4,
    className: 'undo-remove-notification',
    key: UndoRemoveNotificationKey,
    rtl: true
  });
}

export function hideUndoNotification() {
  message.destroy(UndoRemoveNotificationKey);
}
