import * as React from 'react';
import path from '../../img/logo.svg';

export interface LogoProps{
  onClick?: () => void;
}

export default function Logo({onClick}: LogoProps) {
  function handler() {
    if (onClick) onClick();
  }

  return <img src={path} alt={'logo'} onClick={handler}/>;
};

