import * as React from "react";
import {Card} from "antd";
import Storage from "../../../util/Storage";
import {useEffect, useState} from "react";
import {handleServerError} from "../../../util/ErrorHandler";
import Input from "antd/es/input/Input";
import MemberApi from "../../../api/MemberApi";

export default function Notifications() {
  const [notificationEmail, setNotificationEmail] = useState("");

  useEffect(() => {
    MemberApi.getMember(Storage.getMemberId()).then(resp => {
      setNotificationEmail(resp.data.notificationEmail);
    }).catch(handleServerError);
  }, []);

  return (
    <div>
      <div className={"split rs-set"}>
        <Card>
          <header className={"c"}>
            <h3>{"Notifications"}: </h3>
          </header>
        </Card>
        <Card>
          <header className={"c"}>
            <h3>{"Notification email"}</h3>
            <h5>{"Notification email description"}</h5>
          </header>
          <div>
            <Input inputMode={"email"} value={notificationEmail} size="large" placeholder={"email"}
                   onChange={(ev) => setNotificationEmail(ev.target.value)}/>
          </div>
        </Card>
      </div>
    </div>
  );
}
