import * as React from 'react';
import {Modal} from "antd";
import {capitalize} from "../../util/Utils";

export interface BusinessDayInvalidModalProps {
  visible: boolean,
  onOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  day: string;
  interval: string;
}

export default function BusinessDayInvalidModal({visible, day, interval, onOk}: BusinessDayInvalidModalProps) {
  return (
    <Modal visible={visible} onOk={onOk} onCancel={onOk} title={null} cancelButtonProps={{style: {display: 'none'}}}>
      Invalid time range for the {interval} interval of {capitalize(day)}.
    </Modal>
  );
}
