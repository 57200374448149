import * as React from 'react';
import {useState} from "react";
import {StarFilled, StarOutlined} from "@ant-design/icons/lib";
import {useEffect} from "react";
import styles from './Stars.module.scss';

interface StarsProps {
  score?: number;
  size?: 'small' | 'normal' | 'large';
  visibleScore?: boolean;
  changeable?: boolean;
  error?: boolean;
  onChange?: (score: number) => void;
}

const STARS = 5;

export default function Stars({score = 0, visibleScore, changeable, onChange, error, size = 'normal'}: StarsProps) {
  const [initial, setInitial] = useState(0);
  const [filled, setFilled] = useState(0);
  const [raw, setRaw] = useState(0)

  useEffect(() => {
    const fixed = Math.round(score);
    setFilled(fixed);
    setInitial(fixed);
    setRaw(score);
  }, [score]);

  function onMouseEnter(idx: number) {
    setFilled(idx + 1);
  }

  function onMouseLeave() {
    setFilled(initial);
  }

  function onClick(idx: number) {
    setFilled(idx + 1);
    setInitial(idx + 1);
    if (onChange) {
      onChange(idx + 1);
    }
  }

  let cls = '';
  if (size === 'large') cls = styles.large;
  else if (size === 'small') cls = styles.small;

  return <div className={`${styles.stars} ${cls} ${error ? styles.error : ''}`}>
    {[...Array(filled)].map((_, idx) =>
      <StarFilled key={`filled-${idx}`}
                  onMouseEnter={changeable ? () => onMouseEnter(idx) : null}
                  onMouseLeave={changeable ? onMouseLeave : null}
                  onClick={changeable ? () => onClick(idx) : null} />)}

    {[...Array(STARS - filled)].map((_, idx) =>
      <StarOutlined key={`filled-${filled + idx}`}
                    onMouseEnter={changeable ? () => onMouseEnter(filled + idx) : null}
                    onMouseLeave={changeable ? onMouseLeave : null}
                    onClick={changeable ? () => onClick(filled + idx) : null} />)}

    {visibleScore && <h6><span>{raw}</span> / 5</h6>}
  </div>;
};

