import { useEffect, useState } from "react";
import ADAIcon from "../../misc/icons/ADAIcon";
import { Button, Modal } from "antd";
//import BrandApi from "../../api/BrandApi";
import "./AltTextModal.scss";
import { CloseOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import AltTextHelpModal from "./AltTextHelpModal";
import { MemberIconDto, MemberImageDto } from "../../../gen/client";
import { getImagePath } from "../../../services/ImageService";
import ImageApi from "../../../api/ImageApi";
import { MenuNode } from "../../../domain/Menu";
import MemberApi from "../../../api/MemberApi";

export interface AltTextModalProps {
  visible: boolean;
  onClose: () => void;
  selectedImage: MemberImageDto | MemberIconDto;
  images: MemberImageDto[];
  setImages: (images: MemberImageDto[]) => void;
  icons: MemberIconDto[];
  setIcons: (icons: MemberIconDto[]) => void;
  nodes: MenuNode[];
  updateAltText: (nodes: MenuNode[]) => void;
}

export function AltTextModal({ visible, onClose, selectedImage, images, setImages, icons, setIcons, nodes, updateAltText }: AltTextModalProps) {
  const [curText, setCurText] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [isHelpModal, setHelpModal] = useState(false);

  function setAltText() {
    setLoading(true);

    if (selectedImage.path.includes('icon-uploads')) {
      icons.forEach(i => {
        if (i.id === selectedImage.id) {
          i.altText = curText;
        }
      })

      ImageApi.saveIcons(icons).then(r => {
        setIcons([...icons]);
        nodes.forEach(section => {
          if (section.nodes.length > 0) {
            section.nodes.forEach(n => {
              if (n.icons && n.icons.length > 0) {
                if (n.icons.map(i => i.path).includes(selectedImage.path)) {
                  n.icons.find(i => i.path === selectedImage.path).altText = curText;
                  updateAltText([...nodes]);
                }
              }
            })
          }
        })
        updateAltText([...nodes]);
        setLoading(false);
      }).catch(e => {
        console.error(e);
        setLoading(false);
      })
    } else {
      images.forEach(i => {
        if (i.id === selectedImage.id) {
          i.altText = curText;
        }
      })

      ImageApi.saveImages(images).then(r => {
        setImages([...images]);
        nodes.forEach(section => {
          if (section.nodes.length > 0) {
            section.nodes.forEach(n => {
              if (n.preview === selectedImage.path) {
                n.previewAltText = curText;
              }
            })
          }
        })
        updateAltText([...nodes]);
        setLoading(false);
      }).catch(e => {
        console.error(e);
        setLoading(false);
      })
    }

    resetAndClose();
  }

  useEffect(() => {
    if (!selectedImage || !selectedImage.id) return;
    setImgUrl(getImagePath(selectedImage.path, selectedImage.source === 'oo'));
    if (selectedImage.altText) {
      setCurText(selectedImage.altText);
    } else {
      setCurText('');
    }
  }, [selectedImage])

  function resetAndClose() {
    window.localStorage.setItem('alttext-just-uploaded', '');
    onClose();
  }

  function deleteIcon() {
    setLoading(true);
    MemberApi.deleteIcon(selectedImage.id).then(resp => {
      if (resp.data) {
        const index = icons.map(i => i.id).indexOf(selectedImage.id);
        icons.splice(index, 1);
        setIcons([...icons]);
      }
      setLoading(false);
      resetAndClose();
    }).catch(err => {
      console.error(err);
      setLoading(false);
      resetAndClose();
    })
  }

  return <Modal
    className={"one alt-text-modal"}
    onCancel={() => onClose()}
    width={570}
    visible={visible}
    zIndex={1005}
  >
    <div onClick={resetAndClose} className="close-btn"><CloseOutlined/></div>
    <div className="img-cont">
      <img src={imgUrl} alt="Selected" />
    </div>
    <h3>
      <ADAIcon/> 
      Accessibility Text 
      <div className="help-btn" onClick={() => setHelpModal(true)}><QuestionCircleOutlined/></div>
    </h3>
    <p>Image description necessary for ADA compliance and screen readers</p>
    <textarea placeholder="Description 125 chars max" maxLength={125} value={curText} onChange={(e: any) => setCurText(e.target.value)} />
    
    <footer>
      <Button type="primary" className={`btn primary`} disabled={!curText} loading={loading} onClick={setAltText}>Done</Button>
      {window.localStorage.getItem('alttext-just-uploaded') === 'true' &&
        <Button className={`btn delete-icon-btn`} loading={loading} onClick={() => resetAndClose()}>Skip for now</Button>}
      {selectedImage.path && selectedImage.path.includes('icon-uploads') && window.localStorage.getItem('alttext-just-uploaded') !== 'true' &&
        <Button className={`btn delete-icon-btn`} loading={loading} onClick={() => deleteIcon()}>Delete icon</Button>}
    </footer>

    <AltTextHelpModal visible={isHelpModal} onClose={() => setHelpModal(false)}/>
  </Modal>
}
