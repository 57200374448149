import * as React from 'react';
import {BusinessCfg, BusinessDto} from "../../../gen/client";
import {Alert, Button, Card, Popover, Skeleton, Switch} from "antd";
import PaymentApi from "../../../api/PaymentApi";
import Storage from "../../../util/Storage";
import {useEffect, useState} from "react";
import {handleServerError} from "../../../util/ErrorHandler";
import styles from './Payments.module.scss';
import {AxiosError} from "axios";
import badge from "../../../img/stripe-badge.svg";
// import CouponCodes from '../coupon/CouponCodes';
import Taxes from './Taxes';

interface PaymentsProps {
  business: BusinessDto;
  onConfigChange: (patch: Partial<BusinessCfg>) => void;
}

export default function Payments({business, onConfigChange}: PaymentsProps) {

  const [isGeneratingLink, setIsGeneratingLink] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    setLoading(true);
    PaymentApi.getConnectedAccount(Storage.getMemberId()).then(resp => {
      setIsCreated(resp.data.exists);
      setIsEnabled(resp.data.chargesEnabled);
      setLoading(false);
    }).catch((err: AxiosError) => {
      setLoading(false);
      handleServerError(err);
    });
  }, []);

  function openAccountLink(url: string) {
    window.open(url, '_blank');
  }

  function registerConnectedAccount() {
    setIsGeneratingLink(true);
    PaymentApi.registerConnectedAccount(Storage.getMemberId()).then(resp => {
      openAccountLink(resp.data);
      setIsGeneratingLink(false);
    }).catch((error) => {
      handleServerError(error);
      setIsGeneratingLink(false);
    });
  }

  function linkAccount() {
    setIsGeneratingLink(true);
    PaymentApi.linkAccount(Storage.getMemberId()).then(resp => {
      openAccountLink(resp.data);
      setIsGeneratingLink(false);
    }).catch((error) => {
      handleServerError(error);
      setIsGeneratingLink(false);
    });
  }

  const cardSwitch = <Switch disabled={!isEnabled} defaultChecked={business.config && business.config.onlineCardPayment}
                             onChange={() => onConfigChange({onlineCardPayment: !business.config.onlineCardPayment})}/>;

  return (
    <div>
      <div className={'split rs-set'}>
        <Card>
          <header className={'c'}>
            <h3>{'Pay on receipt'}</h3>
            <h5>{'Allow pay in person'}</h5>
          </header>
          <div>
            <Switch defaultChecked={business.config && business.config.deliveryCashPayment} onChange={() => onConfigChange({deliveryCashPayment: !business.config.deliveryCashPayment})}/>
            <label style={{marginLeft: '5px'}}>{'Pay on delivery with cash'}</label>
          </div>
          <div style={{marginTop: '16px'}}>
            <Switch defaultChecked={business.config && business.config.deliveryCardPayment} onChange={() => onConfigChange({deliveryCardPayment: !business.config.deliveryCardPayment})}/>
            <label style={{marginLeft: '5px'}}>{'Pay on delivery with card'}</label>
          </div>
        </Card>

        <Card>
          <header className={'c'}>
            <h3 className={styles.badged}>
              {'Pay online'}
              <a href={'https://stripe.com/'} target={'_blank'} rel={'noopener noreferrer'} className={styles.badged}>
                <img src={badge} alt={'Powered by Stripe'}/>
              </a>
            </h3>
            <h5>{'Allow pay online'}</h5>
          </header>

          <div>
            {!isEnabled && <Popover content={'Create Stripe account to process payments'} trigger="hover">{cardSwitch}</Popover>}
            {isEnabled && cardSwitch}
            <label style={{marginLeft: '5px'}}>{'Pay online with card'}</label>
          </div>

          {isLoading && <Skeleton loading={true}/>}

          <div className={styles.payments}>
            {!isLoading && !isCreated &&
            <>
              <p>
                {'Create Stripe account to process payments'}
              </p>
              <Button onClick={registerConnectedAccount} loading={isGeneratingLink}>{'Create Stripe account'}</Button>
            </>}

            {!isLoading && isCreated && !isEnabled &&
            <>
              <div>
                <Alert message={'Finish Stripe setup'} type={"warning"} style={{display: "inline-block", margin: 0}}/>
              </div>
              <Button onClick={linkAccount} loading={isGeneratingLink}>{'Continue setup'}</Button>
            </>}

            {!isLoading && isCreated && isEnabled &&
            <div>
              <Alert message={'Your account is linked'} type={"success"} style={{display: "inline-block", margin: 0}}/>
            </div>}
          </div>
        </Card>
      </div>

      <div className={'split rs-set'}>
        {/* <CouponCodes business={business} onConfigChange={onConfigChange}/> */}

        <div>
          <Taxes business={business}/>
        </div>
      </div>

    </div>);
}
