export function setPageTitle(title: string, count: number = 0) {
  document.title = `${count > 0 ? '(' + count + ') ' : ''}${title ? title + ' | ' : ''}MustHaveMenus`;
}

export function setCleanTitle(title: string) {
  document.title = title;
}

export function setOGImage(src: string) {
  setMeta('og:image', src);
}

export function setOGImageSecure(src: string) {
  setMeta('og:image:secure_url', src);
}

export function setOGTitle(title: string) {
  setMeta('og:title', title);
}

export function setOGUrl(url: string) {
  setMeta('og:url', url);
}

export function setMeta(property: string, content: string) {
  const existing = document.querySelector(`meta[property="${property}"]`);
  if (!!existing) {
    existing.remove();
  }

  const meta = document.createElement('meta');
  meta.setAttribute('property', property);
  meta.content = content;
  document.getElementsByTagName('head')[0].appendChild(meta);
}
