import * as React from 'react';
import {Modal} from "antd";
import {MenuDataType} from "../../../util/types";

interface DeleteMenuDataProps {
  onConfirm: (e: React.MouseEvent) => void;
  onCancel: (e: React.MouseEvent) => void;
  type: MenuDataType;
  visible: boolean;
}

export default function DeleteMenuData({onConfirm, onCancel, type, visible}: DeleteMenuDataProps) {
  return (
    <Modal okText={'Delete'} cancelText={'Cancel'} visible={visible} onOk={onConfirm} onCancel={onCancel}>
      <h2>Are you sure?</h2>
      <p style={{margin: 0}}>
        {type === MenuDataType.MENU && 'This menu will be removed and all its data will be lost.'}
        {type === MenuDataType.SECTION && 'This section will be removed and all its data will be lost.'}
        {type === MenuDataType.ITEM && 'This item will be removed and all its data will be lost.'}
      </p>
    </Modal>
  );
};
