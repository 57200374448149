import {Modal} from 'antd';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {Prompt, useHistory} from 'react-router-dom';
import {Location} from 'history';

interface RouteLeaveGuardProps {
  enabled: boolean;
  message?: string;
  element?: JSX.Element;
}

export default function RouteLeaveGuard({enabled, message, element}: RouteLeaveGuardProps) {
  const history = useHistory();

  const [modalVisible, setModalVisible] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);

  function handleBlockedNavigation(nextLocation: Location<unknown>): boolean {
    if (!confirmedNavigation && enabled) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  }

  function handleConfirmNavigationClick() {
    setModalVisible(false);
    setConfirmedNavigation(true);
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  return (
    <>
      <Prompt
        when={enabled}
        message={handleBlockedNavigation}
      />

      {!!element && enabled && element}

      {!element &&
        <Modal okText={'yes'} cancelText={'no'} visible={modalVisible} onOk={handleConfirmNavigationClick} onCancel={() => setModalVisible(false)}>
          {message}
        </Modal>
      }
    </>);
}
