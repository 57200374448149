import { CloseOutlined } from "@ant-design/icons";
import ADAIcon from "../../misc/icons/ADAIcon";
import { Modal } from "antd";

export interface AltTextHelpModalProps {
  visible: boolean;
  onClose: () => void;
}

export default function AltTextHelpModal({ visible, onClose }: AltTextHelpModalProps) {
  return <Modal
    className={"one alt-text-modal alt-text-help-modal"}
    onCancel={() => onClose()}
    width={432}
    visible={visible}
    zIndex={1006}
  >
    <div onClick={onClose} className="close-btn"><CloseOutlined/></div>
    <h3><ADAIcon /> Accessibility Text</h3>
    <p>ADA compliance is a federal law that requires businesses to provide accessible services to individuals with disabilities. Accessibility text makes your images readable.</p>
    <p><b>For menu images</b><br />
      Briefly describe the dish or item. <i>E.g. "Grilled chicken salad with avocado and tomatoes."</i></p>
    <p><b>For logos and illustrations</b><br />
      Focus on describing the brand name and essential design elements. <i>E.g. "Cafe logo with green leaf icon."</i></p>
  </Modal>
}
