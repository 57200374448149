import React, {useEffect, useState} from 'react';
import {HourFormatType} from '../../../util/types';
import {HourPicker} from '../../misc/HourPicker';
import {Moment} from 'moment';
import './BusinessDayInterval.scss';
import {DayInterval} from '../../../domain/BusinessHours';
import moment from 'moment';
import {HOUR_FORMAT_24} from '../../../util/constants';
import {CloseOutlined, PlusOutlined} from '@ant-design/icons';
import BusinessDayInvalidModal from "../../misc/BusinessDayInvalidModal";

interface BusinessDayIntervalProps {
  hourFormat: HourFormatType;
  interval: DayInterval;
  idx: number;
  day: string;
  showAddButton: boolean;
  showRemoveButton: boolean;
  onChange: (interval: DayInterval) => void;
  onIntervalAdd: () => void;
  onIntervalRemove: () => void;
}

export function BusinessDayInterval({hourFormat, interval, idx, day, showAddButton, showRemoveButton, onChange, onIntervalAdd, onIntervalRemove}: BusinessDayIntervalProps) {
  const [start, setStart] = useState(null as Moment);
  const [end, setEnd] = useState(null as Moment);
  const [showInvalidBusinessDayModal, setShowInvalidBusinessDayModal] = useState(false);
  const [intervalError, setIntervalError] = useState(false);

  useEffect(() => {
    if (interval.start) {
      setStart(moment(interval.start, HOUR_FORMAT_24));
    }
    if (interval.end) {
      setEnd(moment(interval.end, HOUR_FORMAT_24));
    }
  }, [interval]);

  function onStartChange(value: Moment) {
    setStart(value);

    if (end) {
      validateAndEmit(new DayInterval(value.format(HOUR_FORMAT_24), end.format(HOUR_FORMAT_24)), value, end);
    }
  }

  function onEndChange(value: Moment) {
    setEnd(value);

    if (start) {
      validateAndEmit(new DayInterval(start.format(HOUR_FORMAT_24), value.format(HOUR_FORMAT_24)), start, value);
    }
  }

  function validateAndEmit(interval: DayInterval, start: Moment, end: Moment) {
    // if (start.isBefore(end)) {
      setIntervalError(false);
      onChange(interval);
    // } else {
    //   setShowInvalidBusinessDayModal(true);
    //   setIntervalError(true);
    // }
  }

  return (
    <div className={'container-interval'}>
      <HourPicker hourFormat={hourFormat} initialValue={start} onChange={onStartChange} placeholder={'Opens at'} intervalError={intervalError} />
      <span className={'separator'}>&ndash;</span>
      <HourPicker hourFormat={hourFormat} initialValue={end} onChange={onEndChange} placeholder={'Closes at'} intervalError={intervalError} />

      <div className={'buttons'}>
        {showAddButton && <PlusOutlined onClick={onIntervalAdd} />}
        {showRemoveButton && <CloseOutlined onClick={onIntervalRemove} />}
      </div>

      <BusinessDayInvalidModal visible={showInvalidBusinessDayModal} onOk={() => setShowInvalidBusinessDayModal(false)} day={day} interval={idx === 0 ? 'first' : 'second'} />
    </div>
  );
}
