export default function ADAIcon({ color="#0055FF" }) {
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0.47 0.73 21.49 21.49">
    <g id="blue-ada 1" clipPath="url(#clip0_107_3090)">
      <g id="Layer_1-2">
        <path id="Vector" d="M11.2173 21.0289C16.4925 21.0289 20.7689 16.7525 20.7689 11.4773C20.7689 6.20215 16.4925 1.92578 11.2173 1.92578C5.94214 1.92578 1.66577 6.20215 1.66577 11.4773C1.66577 16.7525 5.94214 21.0289 11.2173 21.0289Z" fill={color} />
        <path id="Vector_2" d="M11.2173 22.22C5.29333 22.22 0.474609 17.4012 0.474609 11.4773C0.474609 5.55334 5.29333 0.734619 11.2173 0.734619C17.1412 0.734619 21.9599 5.55334 21.9599 11.4773C21.9599 17.4012 17.1412 22.22 11.2173 22.22ZM11.2173 1.29967C5.60523 1.29967 1.03966 5.86524 1.03966 11.4773C1.03966 17.0893 5.60523 21.6549 11.2173 21.6549C16.8293 21.6549 21.3949 17.0893 21.3949 11.4773C21.3949 5.86524 16.8293 1.29967 11.2173 1.29967Z" fill={color} />
        <path id="Vector_3" d="M16.9333 8.09379C16.8474 7.67792 16.531 7.4406 16.106 7.47224H16.0925C15.8009 7.4858 15.5161 7.52422 15.2223 7.56491C12.9327 7.9288 10.7155 7.98982 8.63386 7.74572C8.07334 7.67792 7.51507 7.60107 6.98167 7.52874L6.47764 7.46094H6.44826C6.24484 7.46094 5.63007 7.46094 5.49898 8.10961C5.4131 8.55939 5.62555 8.9278 6.06629 9.09053C6.2019 9.14478 6.36011 9.18094 6.53867 9.19676L8.73105 9.46798C9.44301 9.56969 9.46335 9.59455 9.48595 10.3223V10.9281C9.44753 12.3701 9.25315 13.8008 8.90735 15.1817L8.74913 15.8259C8.57962 16.513 8.40558 17.2204 8.25641 17.9279C8.15696 18.3867 8.35134 18.7393 8.78303 18.8704C8.87118 18.893 8.95707 18.9065 9.03844 18.9065C9.37068 18.9065 9.64417 18.7144 9.79334 18.3573C9.86566 18.1697 9.90635 17.9889 9.94477 17.8126C10.2499 16.6011 10.5663 15.349 10.8918 14.124C10.9822 13.7962 11.0387 13.6832 11.206 13.6832C11.3416 13.6832 11.4026 13.7488 11.4998 14.0833L11.5088 14.1127C11.5427 14.2438 11.5789 14.3726 11.615 14.5037C11.6851 14.7568 11.7574 15.019 11.8185 15.2789L12.5191 18.0409C12.5508 18.181 12.596 18.3166 12.6502 18.4228C12.7745 18.6918 13.0412 18.9901 13.6244 18.8726C14.0289 18.7709 14.2278 18.4635 14.1713 18.0273C14.0832 17.3244 13.9046 16.6441 13.7306 15.9864C13.6944 15.8462 13.656 15.7084 13.6198 15.5682C13.0819 13.4911 12.874 11.7621 12.9644 10.1257C12.9847 9.61037 13.0683 9.52449 13.5927 9.47024C14.2753 9.4047 14.9601 9.32559 15.6201 9.24874C15.9117 9.22162 16.2145 9.19224 16.5038 9.02499C16.87 8.81705 17.0237 8.4848 16.9378 8.09379H16.9333Z" fill="white" />
        <path id="Vector_4" d="M11.2443 4.05036C10.8126 4.04584 10.4058 4.20405 10.0961 4.50917C9.78194 4.81882 9.60791 5.23017 9.60791 5.66639C9.60791 6.56594 10.3334 7.29824 11.2262 7.29824C12.119 7.29824 12.8287 6.57272 12.8287 5.67995C12.8287 4.78718 12.119 4.06392 11.2465 4.0481L11.2443 4.05036Z" fill="white" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_107_3090">
        <rect width="21.4853" height="21.4853" fill="white" transform="translate(0.474609 0.734619)" />
      </clipPath>
    </defs>
  </svg>
}
