import React, {useEffect, useState} from 'react';
import {Input} from 'antd';
import {AmPmType, HourFormatType} from '../../util/types';
import {Moment} from "moment";
import './HourPicker.scss';
import {AmPmPicker} from './AmPmPicker';
import {HOUR_FORMAT_24, HOUR_FORMAT_AM_PM, HOUR_FORMAT_AM_PM_FULL} from '../../util/constants';
import moment from 'moment';

interface HourPickerProps {
  hourFormat: HourFormatType;
  initialValue: Moment;
  intervalError: boolean;
  placeholder: string;
  onChange: (value: Moment) => void;
}

export function HourPicker({hourFormat, intervalError, initialValue, placeholder, onChange}: HourPickerProps) {
  const [displayedValue, setDisplayedValue] = useState('');
  const [amPm, setAmPm] = useState(AmPmType.AM);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (initialValue) {
      setAmPm(initialValue.format('a') === 'pm' ? AmPmType.PM : AmPmType.AM);

      if (hourFormat === HourFormatType.AM_PM) {
        setDisplayedValue(initialValue.format(HOUR_FORMAT_AM_PM));
      } else {
        setDisplayedValue(initialValue.format(HOUR_FORMAT_24));
      }
    }
  }, [initialValue, hourFormat]);

  function emitChange(value: AmPmType, addHours: number = 0) {
    const m = getDateAsMoment(value);

    if (addHours !== 0) {
      m.add(addHours, 'hour');
    }

    onChange(m);
  }

  function getDateAsMoment(value: AmPmType) {
    return hourFormat === HourFormatType.AM_PM ?
      moment(`${displayedValue} ${value === AmPmType.AM ? 'am' : 'pm'}`, HOUR_FORMAT_AM_PM_FULL)
      :
      moment(`${displayedValue}`, HOUR_FORMAT_24);
  }

  function onAmPmChange(value: AmPmType) {
    if (validateTime(value)) {
      emitChange(value);
    }
  }

  function onBlur() {
    if (validateTime(amPm)) {
      emitChange(amPm);
    }
  }

  function validateTime(value: AmPmType): boolean {
    let isValid = getDateAsMoment(value).isValid();

    setError(!isValid);

    return isValid;
  }

  function onKeyDown(ev: React.KeyboardEvent<HTMLInputElement>) {
    const isNumeric = ev.key >= '0' && ev.key <= '9';

    if (displayedValue.length === 2 && isNumeric && ev.key !== ':' && displayedValue.indexOf(':') < 0) {
      setDisplayedValue(displayedValue + ':');
    }
  }

  function onKeyUp(ev: React.KeyboardEvent<HTMLInputElement>) {
    if (ev.key === 'ArrowUp') addHour(1);
    if (ev.key === 'ArrowDown') addHour(-1);
  }

  function onInputChange(ev: React.ChangeEvent<HTMLInputElement>) {
    setDisplayedValue(ev.target.value);
  }

  function addHour(hours: number) {
    emitChange(amPm, hours);
  }

  return (
    <div>
      <Input
        className={`picker ${error || intervalError ? 'err' : ''}`}
        maxLength={5}
        placeholder={placeholder}
        title={placeholder}
        value={displayedValue}
        onChange={onInputChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      />

      <AmPmPicker onChange={onAmPmChange} value={amPm} hidden={hourFormat === HourFormatType._24}/>
    </div>
  );
}



