export enum ActionButtonType {
  Nav = 'nav',
  Social = 'social',
  Reviews = 'reviews',
  Menus = 'menus',
  StartOrder = 'start-order',
  Call = 'call'
}

interface ActionButtonOption {
  action: ActionButtonType;
  label: string;
}

export const ActionButtonTypes: ActionButtonOption[] = [
  {action: ActionButtonType.Nav, label: 'Link to page'},
  {action: ActionButtonType.Social, label: 'List of links to your social platforms'},
  {action: ActionButtonType.Reviews, label: 'Link to your business reviews'},
  {action: ActionButtonType.Menus, label: 'Link to all your menus'},
  {action: ActionButtonType.StartOrder, label: 'Start order button'},
  {action: ActionButtonType.Call, label: 'Call us button'}
];

export enum ButtonLocation {
  Top = 'top',
  Nav = 'nav'
}
