import * as React from 'react';
import {RatingDto} from "../../../gen/client";
import {Button, Popconfirm, Tag} from "antd";
import {BusinessApi} from "../../../api/BusinessApi";
import {useState} from "react";
import {AxiosError} from "axios";
import {handleServerError} from "../../../util/ErrorHandler";
import styles from './Reviews.module.scss';
import DateTimeStamp from "../../misc/DateTimeStamp";
import Stars from "../../misc/Stars";
import {DeleteOutlined} from "@ant-design/icons/lib";

interface DashboardRatingsEntryProps {
  businessId: string;
  rating: RatingDto;
  onRemove: (rating: RatingDto) => void;
  onAccepted: (rating: RatingDto) => void;
}

export default function ReviewEntry({rating, businessId, onRemove, onAccepted}: DashboardRatingsEntryProps) {

  const [accepting, setAccepting] = useState(false);
  const [removing, setRemoving] = useState(false);

  function remove(rating: RatingDto) {
    setRemoving(true);
    BusinessApi.patchRating(businessId, {id: rating.id, removed: true}).then(it => {
      setRemoving(false);
      onRemove(rating);
    }).catch((err: AxiosError) => {
      setRemoving(false);
      handleServerError(err);
    });
  }

  function accept(rating: RatingDto) {
    setAccepting(true);
    BusinessApi.patchRating(businessId, {id: rating.id, approved: true}).then(it => {
      setAccepting(false);
      onAccepted(rating);
    }).catch((err: AxiosError) => {
      setAccepting(false);
      handleServerError(err);
    });
  }

  return <>
    <span className={styles.name}>
      {!rating.approved && <Tag color={'#87d068'}>{'New'}</Tag>}
      {rating.name}
    </span>
    <span>{rating.message}</span>
    <span><Stars score={rating.score}/></span>
    <span><DateTimeStamp date={new Date(rating.createdDate)}/></span>
    <span className={styles.actions}>
      {!rating.approved && <Button size={'small'} onClick={() => accept(rating)} loading={accepting} disabled={accepting}>{'Accept'}</Button>}
      <Popconfirm title={'Are you sure?'} okText={'yes'} cancelText={'no'} onConfirm={() => remove(rating)}>
        <Button size={'small'} loading={removing} disabled={removing}><DeleteOutlined/></Button>
      </Popconfirm>
    </span>
  </>
};
