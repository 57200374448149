const MID = 'mid';
const TKN = 'tkn';
const BID = 'bid';
const CFG = 'cfg';

const Storage = {
  setMemberId(memberId: number) {
    window.localStorage.setItem(MID, `${memberId}`);
  },

  getMemberId(): number {
    return parseInt(window.localStorage.getItem(MID));
  },

  removeMemberId() {
    window.localStorage.removeItem(MID);
  },

  setToken(memberId: string) {
    window.localStorage.setItem(TKN, memberId);
  },

  getToken(): string {
    return window.localStorage.getItem(TKN);
  },

  removeToken() {
    window.localStorage.removeItem(TKN);
  },

  setBusinessId(businessId: string) {
    window.localStorage.setItem(BID, businessId);
  },

  getBusinessId(): string {
    return window.localStorage.getItem(BID);
  },

  removeBusinessId() {
    window.localStorage.removeItem(BID);
  },

  setConfig(config: string) {
    window.localStorage.setItem(CFG, config);
  },

  getConfig(): string {
    return window.localStorage.getItem(CFG);
  },

  removeConfig() {
    window.localStorage.removeItem(CFG);
  }
}

export default Storage;
