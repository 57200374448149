import * as React from "react";
import { Button, Switch, Tooltip } from "antd";
import Price from "../../Price";
import styles from "./ItemView.module.scss";
import ItemPreview from "./ItemPreview";
import { DeleteOutlined, DownOutlined } from "@ant-design/icons";
import {BusinessDto, ImageDtoSourceEnum} from "../../../gen/client";
import { MenuNode } from "../../../domain/Menu";

interface ItemViewProps {
  available: boolean;
  currency: string;
  description: string;
  extras: string | undefined;
  id: string;
  name: string;
  preview: string;
  previewSource: ImageDtoSourceEnum,
  prices: string[];
  sectionId: string;
  sizes: string[];
  business: BusinessDto;

  onItemUpdate: (item: Partial<MenuNode>) => void;
  setRemoveModal: (removeModal: boolean) => void;
  onClick: () => void;
}

export default function ItemView({
  available,
  currency,
  description,
  extras,
  id,
  name,
  preview,
  previewSource,
  prices,
  sectionId,
  sizes,
  business,
  onItemUpdate,
  setRemoveModal,
  onClick,
}: ItemViewProps) {
  return (
    <div className={`${styles.view} ${!available ? 'unavailable' : ''}`} onClick={onClick}>
      <div className={styles.itemActions}>
        <Tooltip title={available ? 'Hide' : 'Show'} placement="bottom">
          <Switch className={'scc'} defaultChecked={available} onChange={(available) => onItemUpdate({ available })} size={"small"} />
        </Tooltip>
        <Tooltip title="Delete" placement="bottom">
          <Button type={"link"} onClick={(e) => {e.stopPropagation(); setRemoveModal(true)}}>
            <DeleteOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="Expand Item" placement="bottom">
          <DownOutlined className={'close-icon'} />
        </Tooltip>
      </div>
      <section>
        <div>
          <h3 className={`${styles.content} ${styles.lineClamp}`}>{name}</h3>
          <p className={`${styles.content} ${styles.lineClamp}`}>{description}</p>
          {business.orderingEnabled ?
            <ul>
              {prices.map((price, idx) => (
                <li key={idx}>
                  <span>{sizes && sizes[idx] ? `${sizes[idx]} `: null}</span>
                  <Price business={business} currency={currency} value={price} />
                </li>
              ))}
            </ul>:
            <ul className={`${styles.ulNotEnabled} ${styles.content} ${styles.oneLineClamp}`}>
              {prices.map((price, idx) => (
                <li key={idx}>
                  {sizes && sizes[idx] && prices.length > 1 && <><span>{sizes[idx]}</span>&nbsp;</>}
                  <Price business={business} currency={currency} value={price} />&nbsp;{idx < prices.length-1? <>|&nbsp;</> : null}
                </li>
              ))}
            </ul>
          }
        </div>
        <ItemPreview preview={preview} previewSource={previewSource} />
      </section>
    </div>
  );
}
