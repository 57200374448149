import * as React from 'react';
import './LightBusinessFooter.scss';
import {BusinessDto, LocationDto} from "../../gen/client";
import {States} from "../../util/countries";

interface LightBusinessFooterProps {
  business: BusinessDto;
  location: LocationDto;
}

export default function LightBusinessFooter({business, location}: LightBusinessFooterProps) {

  return <footer className={'main'}>
    {business?.config && business?.config.showAddress && <div className={'address'}>
      <p>{location.address}{location.address && location.address2 && ','} {location.address2}</p>
      <p>{location.city}{(States[location.state] || location.state) && ','} {States[location.state] || location.state} {location.zip}</p>
    </div>}
    <div className={'mhm'}>
      <a href={'https://www.musthavemenus.com'} target={'_blank'} rel={'noreferrer'}>powered by <span>musthavemenus</span></a>
    </div>
  </footer>
}
