import * as React from 'react';
import './ImportItemModal.scss';
import {Modal} from "antd";
import {useState} from "react";
import {BusinessDto, TimberItemDto, TimberMenuDto} from "../../../gen/client";
import ImportItemDesigns from "./ImportItemDesigns";
import ImportItemData from "./ImportItemData";

enum ImportItemModalView {
  Designs, Items
}

export interface ImportItemModalProps {
  business: BusinessDto;
  visible: boolean;
  onSuccess: (items: TimberItemDto[]) => void;
  onClose: () => void;
}

export default function ImportItemModal({business, visible, onClose, onSuccess}: ImportItemModalProps) {
  const [design, setDesign] = useState(null as TimberMenuDto);
  const [view, setView] = useState(ImportItemModalView.Designs);

  function toItems() {
    setView(ImportItemModalView.Items);
  }

  function toDesigns() {
    setView(ImportItemModalView.Designs);
  }

  return visible ? <Modal className={'import-item-modal'} visible={visible} onCancel={onClose} width={900}>
    {visible && view === ImportItemModalView.Designs && <ImportItemDesigns business={business} onSelect={it => setDesign(it)} onClose={onClose} active={design} onNext={toItems} />}
    {visible && view === ImportItemModalView.Items && <ImportItemData active={design} onClose={onClose} goBack={toDesigns} onSuccess={onSuccess}/>}
  </Modal> : null;
}
