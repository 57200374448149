import {AxiosPromise} from "axios";
import {
  QRCodeDto,
  QrCodeResourceApi
} from "../gen/client";
import {apiConfig, ops} from "./ApiConfig";
import Storage from "../util/Storage";

const api = new QrCodeResourceApi(apiConfig);

const QRCodeApi = {
  createQRCode(dto: QRCodeDto): AxiosPromise<QRCodeDto> {
    return api.createQrCode(Storage.getToken(), dto, ops());
  },

  onlineMenuHasQRCode(businessId: string): AxiosPromise<String> {
    return api.onlineMenuHasQRCode(businessId, Storage.getToken(), ops());
  },

  countMemberQRCodes(memberId: number): AxiosPromise<string> {
    return api.countMemberQRCodes(memberId, Storage.getToken(), ops());
  }
}

export default QRCodeApi;
