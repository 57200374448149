import {Button, Popconfirm} from "antd";
import * as React from "react";
import {MenuItemModifier} from "../../../domain/Menu";
import {MenuItemModifierDetail} from "../../../util/types";
import styles from "./MenuItemModifierSelect.module.scss";

interface MenuItemModifierSelectProps {
  modifiers: MenuItemModifierDetail[];
  onSelect: (m: MenuItemModifier) => void;
  onRemove: (m: MenuItemModifier) => void;
  onEdit: (m: MenuItemModifier) => void;
}

export default function MenuItemModifierSelect({modifiers, onSelect, onEdit, onRemove}: MenuItemModifierSelectProps) {

  return (
    <div className={styles.container}>
      <span>{'savedModifiers'}</span>

      {modifiers && modifiers.map(it => <div key={it.modifier.name} className={styles.line}>
        <div className={styles.column}>
          <Button type={'primary'} onClick={() => onSelect(it.modifier)}>{'addToItem'}</Button>
        </div>

        <div className={styles.column}>
          {it.modifier.name}
        </div>

        <div className={styles.column}>
          {`usedInItem${it.usedIn.length > 1 ? 's' : ''}`}
        </div>

        <div className={styles.column}>
          <Button type={'link'} onClick={() => onEdit(it.modifier)}>{'Edit'}</Button>
          <Popconfirm title={'Are you sure?'} onConfirm={() => onRemove(it.modifier)}>
            <Button type={'link'}>{'Remove'}</Button>
          </Popconfirm>
        </div>
      </div>)}
    </div>
  );
}
