import * as React from 'react';
import {Modal} from "antd";

export interface FileTooBigModalProps {
  size?: number;
  maxSize?: number;
  onOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export default function FileTooBigModal({size, maxSize, onOk}: FileTooBigModalProps) {
  return (
    <Modal visible={true} onOk={onOk} onCancel={onOk} cancelButtonProps={{style: {display: 'none'}}}>
      <h2>File Exceeds Limit</h2>
      <p>This image can't be uploaded because it exceeds the {maxSize || 5} MB file size limit.</p>
    </Modal>
  );
}
