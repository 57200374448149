import * as React from "react";
import {BusinessDto} from "../../gen/client";
import "./StaticItemDetailsModal.scss";
import {Modal} from "antd";
import {MenuNode} from "../../domain/Menu";
import Price from "../Price";
import {getImagePath} from "../../services/ImageService";
import {isOOImage} from "../../services/MenuService";

interface StaticItemDetailsModalProps {
  isVisible: boolean;
  item: MenuNode;
  business: BusinessDto;
  onClose: () => void;
}

export default function StaticItemDetailsModal({isVisible, item, business, onClose}: StaticItemDetailsModalProps) {
  return (
    <Modal className={`details-item-modal`} visible={isVisible} onCancel={onClose}>
      {item && <div className={'content'}>
        {item.preview && <img src={getImagePath(item.preview, isOOImage(item.previewSource))} alt={item.previewAltText}/>}
        <div className={'data'}>
          <h3>{item.name}</h3>
          <section className={!item.name ? 'empty-name' : ''}>
            {item.description && <p className={'new-lines'}>{item.description}</p>}
            {item.prices[0] && <p>
              {item.prices.map((price, idx) => (
                <span key={idx}>
                  {item.sizes[idx] && item.prices.length > 1 && <><span>{item.sizes[idx]}&nbsp;</span></>}
                  <Price business={business} currency={business.config?.currency} value={price}/>&nbsp;
                  {idx < item.prices.length - 1 && '| '}
                </span>))}
            </p>}
            {item.extras && <p className={'new-lines'}>{item.extras}</p>}
          </section>
        </div>
      </div>}
    </Modal>
  );
}
