import * as React from 'react';
import './LightBusinessNotFound.scss';

interface LightBusinessNotFoundProps {
  found: boolean;
}

export default function LightBusinessNotFound({found}: LightBusinessNotFoundProps) {
  return <div className={'light-not-found'}>
    <div className={'emoji'}>😞</div>
    <h1>{found ? `We're sorry!` : `Sorry!`}</h1>
    <h2>{found ? 'This link is no longer available.' : 'Can\'t find that page.'}</h2>
    <img src={'https://www.musthavemenus.com/imageservice/images/img/13/email/circle-mhm.png'} alt={'logo'}/>
  </div>
}
