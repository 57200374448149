import {ImageResourceApi, MemberIconDto, MemberImageDto, MemberResourceApi} from "../gen/client";
import {AxiosPromise} from "axios";
import {apiConfig, ops} from "./ApiConfig";

const api = new ImageResourceApi(apiConfig);
const memberApi = new MemberResourceApi(apiConfig);

const ImageApi = {
  getImage(url: string): AxiosPromise<Array<string>> {
    return api.getImage(url, {
      ...ops(),
      responseType: 'arraybuffer'
    });
  },

  saveImages(dtos: MemberImageDto[]) {
    return memberApi.saveMemberImages(dtos, {
      ...ops()
    })
  },

  saveIcons(dtos: MemberIconDto[]) {
    return memberApi.saveMemberIcons(dtos, {
      ...ops()
    })
  }
}

export default ImageApi;
