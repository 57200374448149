import * as React from "react";
import {Button, Input, message, Popconfirm, Radio} from "antd";
import {BusinessDto} from "../../../gen/client";
import {MenuItemModifier as ItemModifier, MenuItemModifierOption} from "../../../domain/Menu";
import {useState} from "react";
import Price from "../../Price";
import {uuid} from "../../../util/Utils";
import styles from "./MenuItemModifier.module.scss";
import {DeleteOutlined} from "@ant-design/icons";

interface MenuItemModifierProps {
  edit: boolean;
  business: BusinessDto;
  currency: string;
  value?: ItemModifier;
  onCancel: () => void;
  onSave: (m: ItemModifier) => void;
}

export default function MenuItemModifier({edit, currency, value, onSave, onCancel}: MenuItemModifierProps) {

  const [modifier, setModifier] = useState(value);

  function onModifierChange(val: Partial<ItemModifier>) {
    setModifier({...modifier, ...val});
  }

  function onOptionChange(obj: Partial<MenuItemModifierOption>, idx: number) {
    const options = [...modifier.options];
    options[idx] = {...options[idx], ...obj};

    setModifier({
      ...modifier, options
    });
  }

  function onOptionAdd() {
    setModifier({
      ...modifier,
      options: [...modifier.options, new MenuItemModifierOption(uuid())]
    });
  }

  function onModifierSave() {
    if (modifier.name && !modifier.options.filter(it => !it.name || isNaN(it.price)).length) {
      onSave(modifier);
    } else {
      message.error('Please complete all modifier information.');
    }
  }

  function onOptionRemove(o: MenuItemModifierOption) {
    setModifier({
      ...modifier,
      options: modifier.options.filter(it => it.id !== o.id)
    });
  }

  return (
    <div className={styles.container}>
      <section>
        <div className={styles.name}>
          <div>
            <label>{'modifierName'}</label>
          </div>
          <div>
            <Input size="large" defaultValue={modifier.name} placeholder={'modifierName'} onBlur={(ev) => onModifierChange({name: ev.target.value})} />
          </div>
        </div>

        <div className={styles.selection}>
          <div>
            <label>{'selection'}</label>
          </div>
          <div>
            <Radio.Group className={styles.radioGroup} onChange={(ev) => onModifierChange({required: ev.target.value ? modifier.required || 1 : 0})} value={modifier.required > 0}>
              <Radio value={true}>{'required'}</Radio>
              <Radio value={false}>{'notRequired'}</Radio>
            </Radio.Group>
          </div>

          <div>
            <label>{'#'}</label>
            <Input type={'number'} defaultValue={'1'} min={'0'} value={modifier.required} onBlur={(ev) => onModifierChange({required: parseInt(ev.target.value)})}></Input>
          </div>
        </div>

        <div className={styles.options}>
          <label>{'options'}</label>
          {modifier.options.map((o, idx) =>
            <div key={idx} className={styles.optionsLine}>
              <div>
                <Input size="large" placeholder={'Name'} defaultValue={o.name} onBlur={(ev) => onOptionChange({name: ev.target.value}, idx)} />
              </div>

              <div className={styles.price}>
                <div>
                  <Price edit currency={currency} value={o.price + ''} onChange={(price) => onOptionChange({price}, idx)} />
                </div>

                <Popconfirm title={'Are you sure?'} onConfirm={() => onOptionRemove(o)}>
                  <DeleteOutlined />
                </Popconfirm>
              </div>
            </div>
          )}

          <Button onClick={onOptionAdd} className={styles.addOption}>{'addOption'}</Button>
        </div>
      </section>

      <footer>
        <Button onClick={onCancel}>{'Cancel'}</Button>
        <Button type={'primary'} onClick={onModifierSave}>{edit ? 'save' : 'addToItem'}</Button>
      </footer>
    </div>
  );
}
