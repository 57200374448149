import { MenuItemModifier, MenuNode } from '../domain/Menu';
export enum HourFormatType {
  AM_PM,
  _24
}

export enum AmPmType {
  AM,
  PM
}

export interface MenuItemModifierDetail {
  modifier: MenuItemModifier;
  usedIn: MenuNode[]
}

export interface FormItemData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

export class ColorPair {
  one: string;
  two: string;
  three: string;
  four: string;

  constructor(one: string, two: string, three: string, four: string) {
    this.one = one;
    this.two = two;
    this.three = three;
    this.four = four;
  }
}

export interface ReferenceProp {
  [key: string]: any;
}

export enum MenuDataType {
  MENU, SECTION, ITEM
}
