import * as React from 'react';

export default function InstagramIcon() {
  return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 503.84 503.84">
    <path
      d="M251.92,45.39c67.27,0,75.23.26,101.8,1.47,24.56,1.12,37.9,5.22,46.78,8.67a78,78,0,0,1,29,18.85,78,78,0,0,1,18.85,29c3.45,8.88,7.55,22.22,8.67,46.78,1.21,26.57,1.47,34.53,1.47,101.8s-.26,75.23-1.47,101.8c-1.12,24.56-5.22,37.9-8.67,46.78a83.51,83.51,0,0,1-47.81,47.81c-8.88,3.45-22.22,7.55-46.78,8.67-26.56,1.21-34.53,1.47-101.8,1.47s-75.24-.26-101.8-1.47c-24.56-1.12-37.9-5.22-46.78-8.67a78,78,0,0,1-29-18.85,78,78,0,0,1-18.85-29c-3.45-8.88-7.55-22.22-8.67-46.78-1.21-26.57-1.47-34.53-1.47-101.8s.26-75.23,1.47-101.8c1.12-24.56,5.22-37.9,8.67-46.78a78,78,0,0,1,18.85-29,78,78,0,0,1,29-18.85c8.88-3.45,22.22-7.55,46.78-8.67,26.57-1.21,34.53-1.47,101.8-1.47m0-45.39c-68.42,0-77,.29-103.87,1.52S102.93,7,86.9,13.23A123.52,123.52,0,0,0,42.28,42.28a123.52,123.52,0,0,0-29,44.62C7,102.93,2.74,121.24,1.52,148.05S0,183.5,0,251.92s.29,77,1.52,103.87S7,400.91,13.23,416.94a123.52,123.52,0,0,0,29.05,44.62A123.68,123.68,0,0,0,86.9,490.62c16,6.22,34.34,10.48,61.15,11.71s35.45,1.51,103.87,1.51,77-.29,103.87-1.51,45.12-5.49,61.15-11.71a128.85,128.85,0,0,0,73.68-73.68c6.22-16,10.48-34.34,11.71-61.15s1.51-35.45,1.51-103.87-.29-77-1.51-103.87-5.49-45.12-11.71-61.15a123.68,123.68,0,0,0-29.06-44.62,123.52,123.52,0,0,0-44.62-29C400.91,7,382.6,2.74,355.79,1.52S320.34,0,251.92,0Z"/>
    <path d="M251.92,122.56A129.37,129.37,0,1,0,381.29,251.92,129.35,129.35,0,0,0,251.92,122.56Zm0,213.33a84,84,0,1,1,84-84A84,84,0,0,1,251.92,335.89Z"/>
    <circle cx="386.4" cy="117.44" r="30.23"/>
  </svg>;
}
