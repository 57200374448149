import * as React from 'react';
import './LightBusiness.scss';
import {BusinessDto, LocationDto, MenuDto} from "../../gen/client";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {MenuNode} from "../../domain/Menu";
import {getImagePath} from "../../services/ImageService";
import {isBusinessOpen} from "../../services/BusinessService";
import BusinessHoursModal from './BusinessHoursModal';
import MenuApi from "../../api/MenuApi";
import {isInViewport, isPreviewUrl, scrollToElementWithOffset} from "../../util/Utils";
import LightBusinessSocial from "./LightBusinessSocial";
import OrderNowButton from "./OrderNowButton";
import LightBusinessFooter from "./LightBusinessFooter";
import LightMenuNav from './LightMenuNav';
import HoursLabel from "./HoursLabel";
import StaticMenuLine from "./StaticMenuLine";
import {businessNameColor, menuTextColor} from "../../services/StylesService";
import StaticItemDetailsModal from "./StaticItemDetailsModal";
import {DEFAULT_TEXT_COLOR} from "../../util/constants";
import LightBusinessAnnouncementBanner from "./LightBusinessAnnouncementBanner";
import {fixVersionedNodes} from "../../services/MenuService";

interface LightBusinessProps {
  isPreview?: boolean;
  business: BusinessDto;
  location: LocationDto;
  menus: MenuDto[];
  selectedId: string;
}

export default function LightBusiness({isPreview, location, business, menus, selectedId}: LightBusinessProps) {
  const [menu, setMenu] = useState({} as MenuDto);
  const [nodes, setNodes] = useState([] as MenuNode[]);
  const [isOpen, setIsOpen] = useState(false);
  const [hasOrderNowButton, setHasOrderNowButton] = useState(false);
  const [showBusinessHoursModal, setShowBusinessHoursModal] = useState(false);
  const [showStaticItemDetailsModal, setShowStaticItemDetailsModal] = useState(false);
  const [selected, setSelected] = useState('');
  const [activeItem, setActiveItem] = useState(null as MenuNode);
  const mainRef = useRef(null);
  const [isBrandLogo, setBrandLogo] = useState(false);

  function handleMainRef(el: HTMLElement) {
    mainRef.current = el;
  }

  const showActiveMenu = useCallback((active: MenuDto) => {
    setMenu(active);
    setSelected(active.id);
    if (active?.id) {
      if (!isPreview && !isPreviewUrl()) {
        MenuApi.increaseViewCount(active.id).then();
      }

      setNodes(fixVersionedNodes(active));

      if (!!mainRef.current) {
        setTimeout(() => {
          const menuNav = document.getElementsByClassName('menu-nav')[0];
          const businessNameEl = document.querySelector('.light-biz-page h1') as HTMLElement;
          // @ts-ignore
          const isBusinessNameInViewPort = isInViewport(businessNameEl);

          if (!isBusinessNameInViewPort) {
            // @ts-ignore
            scrollToElementWithOffset(mainRef.current, menuNav.offsetHeight);
          }
        }, 20);
      }
    } else {
      setNodes([]);
    }
  }, [isPreview]);

  useEffect(() => {
    const actives = menus.filter(it => it.active && !it.removed);
    if (!actives || actives.length === 0) {
      showActiveMenu({});
      return;
    }

    if (!selectedId) {
      let hash = window.location.hash;
      let found: MenuDto = null;
      if (!!hash) {
        hash = hash.substr(1).toLowerCase();
        found = actives.find(it => it.name.replace(/[^a-zA-Z0-9]+/g,'').toLowerCase() === hash);
      }
      if (!found) {
        found = actives.reduce((prev, current) => (prev.sequence < current.sequence) ? prev : current);
      }

      showActiveMenu(found);
      setSelected(found.id);
    } else {
      let found = actives.find(it => it.id === selectedId);

      let menuId = selectedId
      if (!found) {
        found = actives[0];
        menuId = found.id;
      }

      showActiveMenu(found);
      setSelected(menuId);
    }
  }, [selectedId, showActiveMenu, menus])

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--active-menu-item-border', business?.config?.bannerTextColor || DEFAULT_TEXT_COLOR);
  }, [business])

  useEffect(() => {
    const open = isBusinessOpen(business);
    setIsOpen(open);
    setHasOrderNowButton(open && business?.config?.orderNowButton && !!business?.config?.orderNowPhone);
    document.documentElement.style.setProperty('--active-menu-item-border', business?.config?.bannerTextColor || DEFAULT_TEXT_COLOR);
  }, [business]);

  function onSelect(key: any) {
    showActiveMenu(menus.find(el => el.id === key))
  }

  function onItemClick(item: MenuNode) {
    setActiveItem(item);
    if (!isPreview) setShowStaticItemDetailsModal(true);
  }

  function showBusinessHours() {
    if (!isPreview) setShowBusinessHoursModal(true);
  }

  function getNavMenus() {
    const filtered = [...menus.filter(it => it.active && !it.removed)];
    filtered.sort((a: MenuDto, b: MenuDto) => a.sequence - b.sequence);
    return filtered;
  }

  const businessLogo = useMemo(() => {
    let logo = business?.config?.logo;
    if (logo && location.brandLogos && location.brandLogos.length) {
      location.brandLogos.forEach(brandLogo => {
        if (logo === brandLogo.path) {
          setBrandLogo(true);
        }
      });
    }
    return logo;
  }, [business, location]);

  return <div className={`light-biz-page ${hasOrderNowButton ? 'with-order-button' : ''} ${business.orderingEnabled ? 'oo-enabled' : 'oo-disabled'}`}>
    <LightBusinessAnnouncementBanner business={business} isPreview={isPreview}/>

    {businessLogo && <div className={'logo'}>
      <img src={getImagePath(businessLogo, !isBrandLogo)} alt={'logo'}/>
    </div>}

    {business?.config?.showBusinessName && <h1 style={businessNameColor(business)}>{location.name}</h1>}

    {business?.config?.showPhone && location.phone && <p className={'phone'}><a href={`tel: ${location.phone}`}>{location.phone}</a></p>}

    <div className={`hours ${!isPreview ? 'pointer' : ''}`}>
      <HoursLabel isPreview={isPreview} business={business} isOpen={isOpen} showBusinessHours={showBusinessHours}/>
    </div>

    <header>
      <LightBusinessSocial isPreview={isPreview} business={business} location={location}/>
    </header>

    <LightMenuNav
      menus={getNavMenus()}
      business={business}
      selected={selected}
      isPreview={isPreview}
      onSelect={onSelect}/>

    <div className={'menu-notes wrapword'} ref={handleMainRef}>
      <span>{menu.details}</span>
    </div>

    <main>
      {nodes.filter(it => it.available).map((section, idx) =>
        <div key={section.id} className={'section'} style={menuTextColor(business)}>
          <header style={menuTextColor(business)}>
            <h2 style={menuTextColor(business)}>{section.name}</h2>
            {section.description && <p>{section.description}</p>}
          </header>
          {section.nodes.filter(it => it.available).map(it => !!it ? <StaticMenuLine isPreview={isPreview} key={it.id} node={it} business={business} onClick={onItemClick}/> : null)}
        </div>
      )}
      {hasOrderNowButton && <OrderNowButton business={business} isPreview={isPreview}/>}
    </main>

    <LightBusinessFooter business={business} location={location}/>

    <StaticItemDetailsModal item={activeItem} isVisible={showStaticItemDetailsModal} business={business} onClose={() => setShowStaticItemDetailsModal(false)}/>
    <BusinessHoursModal business={business} visible={showBusinessHoursModal} onClose={() => setShowBusinessHoursModal(false)}/>
  </div>
}
