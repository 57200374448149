import * as React from 'react';
import {BusinessDto, MenuDto} from '../../gen/client';
import './LightMenuNav.scss';
import {DEFAULT_TEXT_COLOR} from "../../util/constants";
import {createRef, useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {Button} from "antd";
import {buttonStyles, scrollMenuStyle} from "../../services/StylesService";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {tick} from "../../util/Utils";
import {ReferenceProp} from "../../util/types";
import {isMenusPath} from "../../RoutesEnum";
import {useHistory} from "react-router";
import _ from "lodash";

const BUTTON_BUFFER = 20;

interface LightMenuNavProps {
  menus: MenuDto[];
  business: BusinessDto;
  selected: string;
  onSelect: (key: any) => void;
  isPreview: boolean;
}

export default function LightMenuNav({menus, business, selected, isPreview, onSelect}: LightMenuNavProps) {
  const history = useHistory();
  const scroller = useRef(null);
  const menu = useRef(null);
  const scrollStep = useRef(150);

  const [leftButton, setLeftButton] = useState(false);
  const [rightButton, setRightButton] = useState(false);

  const [references, setReferences] = useState([] as ReferenceProp[]);

  const scroll = useRef(_.debounce((menuId: string, refs: ReferenceProp[]) => {
    if (refs && (refs as ReferenceProp)[menuId]) {
        (refs as ReferenceProp)[menuId].current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'center'
        });
    }
  }, 200));

  const scrollToItem = useCallback((menuId: string) => {
    if (isPreview && isMenusPath(history.location.pathname)) {
      scroll.current(menuId, references);
    }
  }, [references, isPreview, history.location.pathname]);

  useEffect(() => {
    if (selected) {
      scrollToItem(selected);
    }
  }, [selected, scrollToItem]);

  useEffect(() => {
    if (!menus.length) return;

    setReferences(menus.reduce((acc: any, value: MenuDto) => {
      acc[value.id] = createRef();
      return acc;
    }, {}));
  }, [menus]);

  function onMenuRefInit(el: HTMLElement) {
    if (el) {
      menu.current = el;
      checkScroll();
    }
  }

  function onScrollerRefInit(el: HTMLDivElement) {
    if (el) {
      scroller.current = el;
      checkScroll();
    }
  }

  const checkScroll = useCallback(() => {
    if (menu.current && scroller.current) {
      if (menu.current.scrollWidth > scroller.current.clientWidth + BUTTON_BUFFER) {
        menu.current.classList.remove('center');
      } else {
        menu.current.classList.add('center');
      }
    }
    checkButtons();
  }, []);

  useEffect(() => {
    window.addEventListener('load', checkScroll);
    return () => {
      window.removeEventListener('load', checkScroll);
    }
  }, [checkScroll]);

  useEffect(() => {
    document.documentElement.style.setProperty('--active-menu-item-border', business?.config?.bannerTextColor || DEFAULT_TEXT_COLOR);
  }, [business]);

  useEffect(() => {
    if (!scroller || !menu) return;

    if (isPreview) {
      scrollStep.current = document.getElementsByClassName('menu-preview')[0].getBoundingClientRect().width / 2;
    } else {
      scrollStep.current = window.innerWidth / 2;
    }
    window.addEventListener('resize', checkScroll);
    tick(() =>  checkScroll());
    return () => {
      window.removeEventListener('resize', checkScroll);
    }
  }, [checkScroll, isPreview]);

  useLayoutEffect(() => {
    checkScroll();
  }, [checkScroll]);

  function checkButtons() {
    if (scroller.current) {
      setLeftButton(scroller.current.scrollLeft > 0);
      setRightButton(scroller.current.scrollWidth - scroller.current.scrollLeft > Math.ceil(scroller.current.offsetWidth));
    }
  }

  function scrollLeft() {
    const value = scroller.current.scrollLeft - scrollStep.current;
    scroller.current.scroll({left: value, behavior: 'smooth'});
    checkButtons();
  }

  function scrollRight() {
    const value = scroller.current.scrollLeft + scrollStep.current;
    scroller.current.scroll({left: value, behavior: 'smooth'});
    checkButtons();
  }

  return (
    <nav className={'menu-nav sticky'} style={scrollMenuStyle(business)}>
      <div className={'scroller'} ref={onScrollerRefInit} onScroll={checkButtons}>
        {leftButton && <span className={'scr l'} onClick={scrollLeft}><LeftOutlined/></span>}
        <ul ref={onMenuRefInit}>
          {menus.map(it => (<li key={it.id}>
            <Button type={'link'} style={buttonStyles(business, it.id === selected)} onClick={() => onSelect(it.id)} ref={(references as ReferenceProp)[it.id]}>{it.name}</Button>
          </li>))}
          {rightButton && <span className={'scr r'} onClick={scrollRight}><RightOutlined/></span>}
        </ul>
      </div>
    </nav>
  )
};
