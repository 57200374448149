import React from "react";

interface LocationIconProps {
  width?: number,
  height?: number,
  color?: string,
}

export default function LocationIcon({width = 36.393, height = 49.973 , color = "#FFF"}: LocationIconProps) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 38 38"
  >
   
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect
          id="icon_outlined_application_environment_clip_mask_2"
          data-name="icon outlined application environment clip mask 2"
          width={width}
          height={height}
          fill="none"
        />
        <g clipPath="url(#clip-path)">
          <g
            id="icon_outlined_application_environment-2"
            data-name="icon outlined application environment-2"
          >
            <g
              id="icon_outlined_application_environment_clip_group_"
              data-name="icon outlined application environment clip group "
            >
              <rect
                id="icon_outlined_application_environment_style_"
                data-name="icon outlined application environment style "
                width={width}
                height={height}
                fill="none"
              />
              <path
                id="Vector"
                d="M19,35.51a1.56,1.56,0,0,1-.82-.23,32.7,32.7,0,0,1-7-5.68,24.37,24.37,0,0,1-4.11-5.86A17.41,17.41,0,0,1,5.2,16a13,13,0,0,1,1.09-5.27,13.4,13.4,0,0,1,3-4.29A14,14,0,0,1,24.36,3.55a13.57,13.57,0,0,1,4.39,2.89,13.24,13.24,0,0,1,3,4.29A13.1,13.1,0,0,1,32.81,16a17.49,17.49,0,0,1-1.87,7.74,24.75,24.75,0,0,1-4.12,5.87,33.51,33.51,0,0,1-7,5.68,1.72,1.72,0,0,1-.83.22ZM19,5.16a11.22,11.22,0,0,0-7.88,3.18A10.64,10.64,0,0,0,7.87,16c0,3.82,1.56,7.55,4.65,11.11A30.71,30.71,0,0,0,19,32.66C21.61,31,30.13,24.9,30.13,16a10.6,10.6,0,0,0-3.25-7.65A11.18,11.18,0,0,0,19,5.16Zm0,17.66a6.53,6.53,0,1,1,6.53-6.53A6.53,6.53,0,0,1,19,22.82Zm0-10.69a4.16,4.16,0,0,0,0,8.32h0a4.09,4.09,0,0,0,1.58-.32,4.16,4.16,0,0,0-1.59-8Z"
                fill={color}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  );
}
