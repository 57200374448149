import * as React from 'react';
import './LightBusinessAnnouncementBanner.scss';
import {BusinessDto} from "../../gen/client";
import {AnnouncementBanner} from "../../domain/AnnouncementBanner";
import {CSSProperties, useEffect, useState} from "react";
import {fromTimestamp} from "../../util/Utils";
import moment from 'moment';
import {CloseOutlined} from "@ant-design/icons";

interface LightBusinessAnnouncementBannerProps {
  business: BusinessDto;
  isPreview: boolean;
}

export default function LightBusinessAnnouncementBanner({business, isPreview}: LightBusinessAnnouncementBannerProps) {
  const [cfg, setCfg] = useState({} as AnnouncementBanner);
  const [active, setActive] = useState(false);
  const [style, setStyle] = useState({} as CSSProperties);
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    if (!business || !business.config || !business.config.announcementBanner) return;

    setCfg(JSON.parse(business.config.announcementBanner));
  }, [business]);

  useEffect(() => {
    const now = moment.now();
    setActive(cfg.enabled && cfg.text && !!cfg.text.trim().length && (!cfg.start || fromTimestamp(cfg.start).isBefore(now)) && (!cfg.end || fromTimestamp(cfg.end).isAfter(now)));

    setStyle({
      background: cfg.backgroundColor,
      color: cfg.textColor
    })
  }, [cfg]);

  function onBannerClose(e: React.MouseEvent) {
    if (!isPreview) {
      e.stopPropagation();
      setClosed(true);
    }
  }

  return <div>
    {active && !closed && <div className={'biz-banner'} style={style}>
        <span>{cfg.text}</span>
        <CloseOutlined className={`close-icon ${isPreview ? 'preview' : ''}`} onClick={onBannerClose} />
    </div>}
  </div>
}
