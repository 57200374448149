import * as React from 'react';
import './ImportItemModal.scss';
import { Button, Input, Modal, Switch, Tooltip } from "antd";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { BusinessDto, TimberMenuDto } from "../../../gen/client";
import MenuApi from "../../../api/MenuApi";
import { handleServerError } from '../../../util/ErrorHandler';
import { debounce } from 'lodash';
import { CloseOutlined, QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { timberImgPath } from '../../../util/Utils';
import Loader from '../../misc/Loader';
import { groupMoItems, TimberMenuObject } from '../../../services/MenuService';
import { AxiosError } from 'axios';
import CopyMenuImportHelpModal from './CopyMenuImportHelpModal';

export interface CopyMenuImportModalProps {
  business: BusinessDto;
  visible: boolean;
  copyDesignImportLoading: boolean;
  onCopy: (isMultiMenu: boolean, items: TimberMenuObject[]) => void;
  onClose: () => void;
}

export default function CopyMenuImportModal({ business, visible, copyDesignImportLoading, onClose, onCopy }: CopyMenuImportModalProps) {
  const [designsLoading, setDesignsLoading] = useState(true);
  const [designs, setDesigns] = useState([] as TimberMenuDto[]);

  const [design, setDesign] = useState(null as TimberMenuDto);
  const initialized = useRef(false);
  const mounted = useRef(false);
  const key = useRef('');
  const [isMultiMenu, setMultiMenu] = useState(false);

  const [isHelpModal, setHelpModal] = useState(false);

  const fetchMenus = useCallback((search: string, page: number, businessId: number) => {
    if (page === 1) {
      setDesigns([]);
    }

    setDesignsLoading(true);
    key.current = `${search}-${page}`;

    MenuApi.getTimberMoMenus(businessId, search, page).then(resp => {
      if (!mounted.current) return;
      if (key.current !== `${search}-${page}`) return;

      setDesigns(designs => [...designs, ...resp.data.list]);

      if (resp.data.hasNext) {
        fetchMenus(search, page + 1, businessId)
      } else {
        setDesignsLoading(false);
      }
    }).catch(err => {
      setDesignsLoading(false);
      handleServerError(err);
    });
  }, []);

  const performSearch = debounce((key) => {
    fetchMenus(key, 1, parseInt(business.id));
  }, 1500);

  useEffect(() => {
    if (visible) {
      fetchMenus('', 1, parseInt(business.id));
    }
  }, [visible, business.id, fetchMenus])

  useEffect(() => {
    mounted.current = true;
    if (!initialized.current && business.id) {
      fetchMenus('', 1, parseInt(business.id));
      initialized.current = true;
    }
  }, [business.id, fetchMenus]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, []);

  function onSelect(des: TimberMenuDto) {
    setDesign(des);
  }

  function onSearchChange(ev: ChangeEvent<HTMLInputElement>) {
    performSearch(ev.target.value);
  }

  function copyMenu() {
    MenuApi.getDesignMenuObjects(design.id).then(resp => {
      resp.data.forEach(it => it.visible = true);
      const groupedObjects = groupMoItems(resp.data);
      onCopy(isMultiMenu, groupedObjects);
    }).catch((err: AxiosError) => {
      setDesignsLoading(false);
      handleServerError(err);
    });
  }

  return visible ? <Modal className={'import-item-modal copy-menu-modal'} visible={visible} onCancel={onClose} width={900}>
    <header>
      <h2 style={{ marginBottom: '14px' }}>Copy Menu</h2>
      <div className="browser-nav-label">
        Browser nav
        <Tooltip title="What's this?" placement='bottom'><div onClick={() => setHelpModal(true)}><QuestionCircleOutlined/></div></Tooltip>
      </div>
      <div className="switch-labels">
        <span>Regular</span>
        <Switch checked={isMultiMenu} onChange={(checked) => setMultiMenu(checked)} />
        <span>Multi-Menu</span>
      </div>
      <Input prefix={<SearchOutlined />} placeholder={'Search by design name'} onChange={onSearchChange} />
      <div className="close-btn" onClick={onClose}><CloseOutlined /></div>
    </header>

    {designs.length === 0 && !designsLoading && <p>No designs</p>}
    {designs.length > 0 && <div className={'scroller'}>
      <ul className={'designs'}>
        {designs.map(it => <li key={it.id} className={it.id === design?.id ? 'act' : ''} onClick={() => onSelect(it)}>
          <img src={timberImgPath(it.id)} alt={it.name} />
          <h5>{it.name}</h5>
        </li>)}
      </ul>
    </div>}

    {designsLoading && <Loader />}

    <footer>
      <Button className={"btn-med"} type={'primary'} disabled={!design} onClick={copyMenu} loading={copyDesignImportLoading}>Next</Button>
    </footer>

    <CopyMenuImportHelpModal visible={isHelpModal} onClose={() => setHelpModal(false)} />
  </Modal> : null;
}
