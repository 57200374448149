import * as React from 'react';
import './LightBusiness.scss';
import {BusinessDto} from "../../gen/client";
import {useEffect, useState} from "react";
import {businessIsEmpty, getFirstClosingTime, getNextOpenTime, hasHoursConfigured} from "../../services/BusinessService";
import {DownOutlined} from '@ant-design/icons';

interface HoursLabelProps {
  business: BusinessDto;
  isOpen: boolean;
  isPreview: boolean;
  showBusinessHours: () => void;
}

export default function HoursLabel({business, isOpen, isPreview, showBusinessHours}: HoursLabelProps) {
  const [isConfigured, setIsConfigured] = useState(false);
  const [status, setStatus] = useState('');
  const [details, setDetails] = useState('');

  useEffect(() => {
    if (businessIsEmpty(business)) return;
    const configured = hasHoursConfigured(business);
    setIsConfigured(configured);

    if (configured) {
      setStatus(isOpen ? 'Open now' : 'Closed');
      if (isOpen) {
        setDetails(`Closes ${getFirstClosingTime(business)}`);
      } else {
        setDetails(`Opens ${getNextOpenTime(business)}`);
      }
    }
  }, [isOpen, business]);

  return !isConfigured ? null : <>
    <span className={`av ${isOpen ? '' : 'cl'}`}>{status}</span>
    <ul>
      <li><span className={`details ${!isPreview ? 'pointer' : ''}`} onClick={showBusinessHours}>{details}</span></li>
    </ul>
    <DownOutlined className={`hours-expand-icon ${!isPreview ? 'pointer' : ''}`} onClick={showBusinessHours}/>
  </>;
}
