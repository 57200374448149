import * as React from 'react';
import {CustomerDto, BusinessDto} from "../../../gen/client";
import styles from "./CustomerList.module.scss";
import {useCallback, useEffect, useState} from "react";
import CustomerApi from "../../../api/CustomerApi";
import {AxiosError} from "axios";
import {handleServerError} from "../../../util/ErrorHandler";
import {EyeOutlined, PlusOutlined} from "@ant-design/icons/lib";
import {Button, Drawer, Input, message, Skeleton} from "antd";
import throttle from 'lodash.throttle';
import AddCustomerModal from "./AddCustomerModal";
import CustomerDetails from "./CustomerDetails";
import DateTimeStamp from "../../misc/DateTimeStamp";

interface DashboardCustomersProps {
  business: BusinessDto;
}

export default function CustomerList({business}: DashboardCustomersProps) {

  const [isLoading, setLoading] = useState(true);
  const [isSearching, setSearching] = useState(false);
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isDetails, setIsDetails] = useState(false);
  const [hasData, setHasData] = useState(true);

  const [selectedId, setSelectedId] = useState('');
  const [total, setTotal] = useState(0);
  const [customers, setCustomers] = useState([] as CustomerDto[]);

  const fetch = useCallback(function (id: string) {
    setLoading(true);
    CustomerApi.getCustomers(id).then(resp => {
      setLoading(false);
      setCustomers(resp.data);

      setTotal(resp.data.length);
      setHasData(resp.data.length > 0)
    }).catch((err: AxiosError) => {
      setLoading(false);
      handleServerError(err);
    });
  }, []);

  const search = throttle(key => {
    if (!key) {
      fetch(business.id);
      return;
    }

    setSearching(true);
    CustomerApi.searchCustomers(business.id, key).then(resp => {
      setSearching(false);
      setCustomers(resp.data);
    }).catch((err: AxiosError) => {
      setSearching(false);
      handleServerError(err);
    });
  }, 1000);

  useEffect(() => {
    if (!business.id) return;
    fetch(business.id);
  }, [business.id, fetch]);

  function showCreateModal() {
    setIsCreateModal(true);
  }

  function onCustomerAdded(dto: CustomerDto) {
    setCustomers([dto, ...customers]);
    setIsCreateModal(false);
    setTotal(total + 1);
    setHasData(true);
    message.success('Saved successfully', 2);
  }

  function showDetails(customer: CustomerDto) {
    setIsDetails(true);
    setSelectedId(customer.id);
  }

  return (<div className={styles.customers}>
    {hasData &&
    <header>
      <h2>{total} {'Customers'}</h2>
      <section>
        <div className={styles.search}>
          <Input.Search size={'large'} placeholder={`${'Search customers by name or phone'}...`} onChange={ev => search(ev.target.value)} loading={isSearching}/>
        </div>

        <Button onClick={showCreateModal} className={`scc-btn ${styles.add}`}>
          <span>{'Add customer'}</span>
          <PlusOutlined />
        </Button>
      </section>
    </header>}

    {!hasData && !isLoading &&
    <div className={styles.noData}>
      <p>{'No customers yet'}.</p>
      <p>{'Customers will be automatically added'}</p>
      <p>{'Manually add customers'}</p>
      <Button type={"dashed"} onClick={showCreateModal} className={'scc-btn'}>{'Add customer'}</Button>
    </div>}

    {hasData &&
    <div className={styles.data}>

      <header>
        <ul>
          <li>{'Name'}</li>
          <li>{'Phone'}</li>
          <li>{'Email'}</li>
          <li>{'Last order'}</li>
          <li/>
        </ul>
      </header>

      {isLoading && <Skeleton active/>}

      {!isLoading &&
      <>
        {customers.length > 0 &&
        <ul className={styles.list}>
          {customers.map(it => <li key={it.id}>
            <span className={styles.name}>{it.name}</span>
            <span className={styles.phone}>{it.phone}</span>
            <span className={styles.email}>{it.email}</span>
            <span className={styles.last}><DateTimeStamp date={new Date(it.lastOrderDate)}/></span>
            <span className={styles.address}>{it.address}</span>
            <span className={styles.actions}>
              <Button onClick={() => showDetails(it)} className={styles.viewLarge} type="link" icon={<EyeOutlined/>} size={'large'}/>
              <Button onClick={() => showDetails(it)} className={styles.viewLarge}>{'Details'}</Button>
            </span>
          </li>)}
        </ul>}

        {customers.length === 0 && !isLoading &&
        <div className={styles.noResults}>{'No customers found'}</div>}
      </>}
    </div>}

    <AddCustomerModal business={business} isVisible={isCreateModal} onSuccess={onCustomerAdded} onCancel={() => setIsCreateModal(false)}/>

    <Drawer className={styles.drawer} placement="right" onClose={() => setIsDetails(false)} visible={isDetails}>
      <CustomerDetails id={selectedId}/>
    </Drawer>
  </div>);
};
