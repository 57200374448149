import * as React from 'react';
import {Button, Card, Input} from "antd";
import {BusinessDto, LandingPageDto} from '../../../gen/client';
import {useEffect, useState} from "react";
import UrlChangeConfirmModal from "../UrlChangeConfirmModal";
import {handleServerError} from "../../../util/ErrorHandler";
import {hasValidLengthUrl, urlHasInvalidChars} from "../../../util/Utils";
import './SettingsSeo.scss';
import {BusinessApi} from "../../../api/BusinessApi";

interface EditRestaurantGeneralProps {
  business: BusinessDto;
  onConfigChange: (patch: Partial<BusinessDto>) => void;
}

let urlPatch = {} as Partial<LandingPageDto>;

export default function SettingsSeo({business, onConfigChange}: EditRestaurantGeneralProps) {
  const [isUrlChangeModal, setUrlChangeModal] = useState(false);
  const [urlError, setUrlError] = useState('');
  const [customUrl, setCustomUrl] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCustomUrl(business.customUrl);
    document.getElementById("custom-url-input").focus();
  }, [business]);

  function onUrlConfigChange(patch: Partial<BusinessDto>) {
    urlPatch = patch;

    if (!!patch.customUrl) {
      if (!hasValidLengthUrl(patch.customUrl)) {
        setUrlError('Custom URL should not contain more than 25 characters.');
        return;
      }
      if (urlHasInvalidChars(patch.customUrl)) {
        setUrlError('Custom URL is invalid. Please remove any special characters or spaces.');
        return;
      }

      setLoading(true);
      BusinessApi.checkURLValidity(patch.customUrl).then(resp => {
        setLoading(false);
        const valid = resp.data;

        setUrlError(null);
        if (valid) {
          setUrlChangeModal(patch.customUrl !== business.customUrl);
        } else {
          if (patch.customUrl !== business.customUrl) {
            setUrlError('The url is already taken.');
          }
        }
      }).catch((e) => {
        setLoading(false);
        handleServerError(e);
      });
    } else {
      setUrlChangeModal(patch.customUrl !== business.customUrl);
    }
  }

  function confirmUrlChange() {
    setUrlChangeModal(false);
    onConfigChange(urlPatch);
  }

  function cancelUrlChange() {
    setUrlChangeModal(false);
    setCustomUrl(business.customUrl);
  }

  return <div className={'settings-seo'}>
    <Card>
      <header>
        <h3>SEO</h3>
      </header>

      <div className={`entry ${urlError ? 'error' : ''}`}>
        <div>
          <label>Custom URL</label>
          <Input size="large" placeholder={'cafe-de-flore'} id="custom-url-input" className={`${urlError ? 'ant-form-item-has-error' : ''}`} addonBefore={"ohbz.com/oo/"} value={customUrl} onChange={(ev) => setCustomUrl(ev.target.value)} />
          {urlError && <span className={'err'}>{urlError}</span>}
        </div>

        <Button className={'btn-med'} type={'primary'} onClick={() => onUrlConfigChange({customUrl})} loading={loading} disabled={customUrl === business.customUrl}>Update</Button>
      </div>
    </Card>

    <UrlChangeConfirmModal isVisible={isUrlChangeModal} onCancel={cancelUrlChange} onSuccess={confirmUrlChange}/>
  </div>;
};
