import {Button, Switch} from "antd";
import * as React from "react";

interface SettingsCardHeaderProps {
  name: string;
  onEdit: () => void;
  onSwitch?: (checked: boolean) => void;
  hideTrigger?: boolean;
  hideEdit?: boolean;
  triggerChecked?: boolean;
}

export default function SettingsCardHeader({name, onEdit, onSwitch, hideTrigger, triggerChecked, hideEdit}: SettingsCardHeaderProps) {
  return <header>
    <h3 className={'section-name'}>{name}</h3>
    {!hideEdit && <Button className={'edit-btn'} type={'link'} onClick={onEdit}>Edit</Button>}
    {!hideTrigger && <Switch defaultChecked={triggerChecked} className={'trigger'} onChange={onSwitch}/>}
  </header>
}
