import * as React from 'react';
import {Switch} from "antd";
import "./BusinessDay.scss"
import {Day, DayInterval} from "../../../domain/BusinessHours";
import {HourFormatType} from '../../../util/types';
import {BusinessDayInterval} from './BusinessDayInterval';

interface BusinessDaysProps {
  day: string;
  data: Day;
  hourFormat: HourFormatType;
  onChange: (id: string, data: Day) => void;
}

export default function BusinessDay({day, data, hourFormat, onChange}: BusinessDaysProps) {

  function onOpenChange() {
    onChange(day, {
      ...data,
      open: !data.open,
      hours: !data.hours || data.hours.length === 0 ? [new DayInterval('09:00', '17:00')] : data.hours
    });
  }

  function onIntervalChange(idx:number, interval: DayInterval) {
    onChange(day, {
      ...data,
      hours: data.hours.map((it, i) => idx === i ? interval : it)
    });
  }

  function onIntervalAdd() {
    onChange(day, {
      ...data,
      hours: [...data.hours, new DayInterval('09:00', '17:00')]
    });
  }

  function onIntervalRemove(idx: number) {
    onChange(day, {
      ...data,
      hours: data.hours.filter((it, i) => idx !== i)
    });
  }

  return (
    <div className={'biz-day'}>
      <div className={'sw'}>
        <label>{day}</label>
        <Switch checked={data.open} onChange={onOpenChange}/>
        <span className={'status'}><label>{data.open ? 'open' : 'closed'}</label></span>
      </div>

      {data.open && <div className={'interval'}>
          {data.hours.map((it, idx) =>
            <BusinessDayInterval key={idx} hourFormat={hourFormat} interval={it} idx={idx} day={day}
            onChange={(i) => onIntervalChange(idx, i)}
            onIntervalAdd={onIntervalAdd}
            onIntervalRemove={() => onIntervalRemove(idx)}
            showAddButton={idx === 0 && data.hours.length <= 1}
            showRemoveButton={idx !== 0} />)
          }
      </div>}
    </div>
  );
};
