import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import './BusinessPage.scss';
import LightBusiness from "../order/LightBusiness";
import {
  MenuDto, BusinessDto
} from "../../gen/client";
import {isPreviewUrl} from "../../util/Utils";
import {BusinessApi} from "../../api/BusinessApi";
import {useParams} from "react-router";
import {AxiosError} from "axios";
import {Skeleton} from "antd";
import LightBusinessNotFound from "./LightBusinessNotFound";
import {setCleanTitle, setOGImage, setOGImageSecure, setOGTitle, setOGUrl} from "../../util/seo";
import {useRecoilState} from "recoil";
import {currentLocation} from "../../store/atoms";

export default function LightBusinessPage() {
  const {url, menu} = useParams<{url: string, menu: string}>();
  const [business, setBusiness] = useState({} as BusinessDto);
  const [menus, setMenus] = useState([] as MenuDto[]);
  const [found, setFound] = useState(false);
  const [available, setAvailable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedId, setSelectedId] = useState('');
  const [location, setLocation] = useRecoilState(currentLocation);

  const fetchBusiness = useCallback((url) => {
    return BusinessApi.getPublishedBusinessAndMenus(url);
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchBusiness(url).then(resp => {
      let business = resp.data.business;
      let list = resp.data.menus;

      if (business.url && !business.customUrl && window.location.pathname !== `/oo/places/${business.url}` && window.location.pathname !== `/oo/${business.url}`) {
        window.history.replaceState({}, null, business.url);
      }

      if (business.customUrl && window.location.pathname !== `/oo/places/${business.customUrl}` && window.location.pathname !== `/oo/${business.customUrl}`) {
        window.history.replaceState({}, null, business.customUrl);
      }

      // TODO: fix this
      if (list.length && !list[0].name) {
        fetchBusiness(url).then(resp => {
          business = resp.data.business;
          list = resp.data.menus;

          doLogic(list, business);
        });
      } else {
        doLogic(list, business);
      }

      function doLogic(menus: MenuDto[], business: BusinessDto) {
        if (menu) {
          const found = list.find(it => it.name.toLowerCase() === menu.toLowerCase());
          if (found) {
            setSelectedId(found.id);
          }
        }

        setBusiness(business);
        setMenus(list);

        BusinessApi.getPublicLocation(parseFloat(business.id)).then(resp => {
          const loc = resp.data;
          setLocation(loc);

          setFound(true);
          setAvailable(business.published);
          setLoading(false);

          if (loc.name && loc.city && loc.state) {
            const title = `Menus for ${loc.name} | ${loc.city} ${loc.state}`;
            setCleanTitle(title);
            setOGTitle(title)
          } else {
            setCleanTitle('MustHaveMenus');
          }
          if (business.config.logo) {
            setOGImage(business.config.logo);
            setOGImageSecure(business.config.logo);
          }

          setOGUrl(`https://ohbz.com/oo/${business.customUrl || business.url}`);

          if (!isPreviewUrl()) {
            BusinessApi.increaseViewCount(business.id);
          }
        }).catch(handleError);
      }

      // Track Link View
      // const baseUrl = window.location.hostname.includes("test") ? "https://shared-test.mhme.nu" : "https://shared.mhme.nu";
      
      // fetch(`${baseUrl}/api/link-view`, {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({
      //     url: window.location.href,
      //     memberId: business.memberId,
      //     type: "om",
      //     refId: business.id,
      //   }),
      // });
    }).catch(handleError);
  }, [url, menu, fetchBusiness, setLocation]);

  function handleError(err: AxiosError) {
    let found;
    if (err.code) {
      found = err.code === '402';
    } else {
      found = err.toString().indexOf('code 402') > -1;
    }

    setFound(found);
    setAvailable(false);
    setLoading(false);
  }

  return <>
    {loading && <Skeleton active/>}
    {!loading && available && <LightBusiness location={location} business={business} menus={menus} selectedId={selectedId}/>}
    {!loading && !available && <LightBusinessNotFound found={found}/>}
  </>
}
