import * as React from 'react';
import {MenuDto, BusinessDto} from "../../gen/client";
import './MobilePreview.scss'
import LightBusiness from "../order/LightBusiness";
import {isDashboardPath, isPublicPage} from "../../RoutesEnum";
import {useHistory} from "react-router";
import {useRecoilState} from "recoil";
import {currentLocation} from "../../store/atoms";
import {useEffect} from "react";
import {BusinessApi} from "../../api/BusinessApi";

export enum MobilePreviewSize {
  Small, Normal
}

interface MobilePreviewProps {
  business: BusinessDto;
  menus: MenuDto[];
  size?: MobilePreviewSize;
}

export default function MobilePreview({business, menus, size}: MobilePreviewProps) {
  const history = useHistory();

  const [location, setLocation] = useRecoilState(currentLocation);

  useEffect(() => {
    if (!business || !business.id) return;

    BusinessApi.getLocation(parseFloat(business.id)).then(resp => {
      setLocation(resp.data);
    });
  }, [business, setLocation]);

  function getSelectedId() {
    const path = history.location.pathname;
    if (!isPublicPage(path) && isDashboardPath(path)) {
      return path.substring(path.lastIndexOf('/') + 1);
    }

    return menus.length ? menus[0].id : null;
  }

  return <div className={`menu-preview ${size === MobilePreviewSize.Small ? 'small' : 'normal'}`}>
    <LightBusiness business={business} location={location} menus={menus} isPreview={true} selectedId={getSelectedId()} />
  </div>
};
