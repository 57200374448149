import * as React from 'react';
import {useState} from 'react';
import './StartForm.scss';
import {Form, Input, Button} from "antd";
import {AuthResponseDto} from "../gen/client";
import {AxiosError} from "axios";
import StartFormAccount from "./StartFormAccount";

interface AuthFormProps {
  onSuccess: (member: AuthResponseDto) => void;
  onError: (error: AxiosError) => void;
}

function StartForm({onSuccess, onError}: AuthFormProps) {
  const [step, setStep] = useState(1);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');

  const onOneFinish = (values: any) => {
    setName(values.name);
    setAddress(values.address);
    setCity(values.city);
    setState(values.state);
    setZip(values.zip);
    setPhone(values.phone);
    setStep(step + 1);
  };

  const content = step === 1 ? (
    <Form className={'step stacked'} initialValues={{remember: true}} onFinish={onOneFinish}>
      <h1>{'Your Business Details'}</h1>

      <Form.Item
        name="name"
        label={'restaurantName'}
        rules={[{required: true, message: 'pleaseRestaurantName'}]}>
        <Input size="large" placeholder={'Your Business Name'}/>
      </Form.Item>

      <Form.Item
        name="phone"
        label={'phone'}
        rules={[{required: true, message: 'pleasePhone'}]}>
        <Input size="large" placeholder={'whereCustomerReach'}/>
      </Form.Item>

      <Form.Item
        name="address"
        label={'address'}
        rules={[{required: true, message: 'pleaseRestaurantAddress'}]}>
        <Input size="large" placeholder={'yourRestaurantAddress'}/>
      </Form.Item>
      
      <Form.Item
        name="city"
        label={'city'}
        rules={[{required: true, message: 'Please specify the city.'}]}>
        <Input size="large" placeholder={'city'}/>
      </Form.Item>
      
      <Form.Item
        name="state"
        label={'state'}
        rules={[{required: true, message: 'Please specify the state.'}]}>
        <Input size="large" placeholder={'state'}/>
      </Form.Item>
      
      <Form.Item
        name="zip"
        label={'zip'}
        rules={[{required: true, message: 'pleaseZip'}]}>
        <Input size="large" placeholder={'zip'}/>
      </Form.Item>

      <Form.Item className={'flx'}>
        <Button size="large" type="primary" htmlType="submit" className={'btn-xl'}>{'next'}</Button>
      </Form.Item>
    </Form>
  ) : (
    <StartFormAccount name={name} address={address} city={city} state={state} zip={zip} phone={phone} onSuccess={onSuccess} onError={onError} />
  );

  return (
    <div className={'form start-form'}>
      {content}
    </div>
  );
}

export default StartForm;
