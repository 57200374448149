import * as React from 'react';
import {Modal} from "antd";
import {useRecoilValue} from "recoil";
import {memberState} from "../store/atoms";

export interface QRLimitModalProps {
  visible: boolean;
  onCancel: () => void;
}

export default function QRLimitModal({visible, onCancel}: QRLimitModalProps) {
    const member = useRecoilValue(memberState);
    function refresh() {
        window.removeEventListener("focus", refresh);
        window.location.reload();
    }
    function clickUpgrade() {
        window.addEventListener("focus", refresh);
        window.MHM.ProModal.upgrade({memberId: `${member.id}`, onSuccess: () => window.location.reload()});
    }

    return (
        <Modal className={'qr-limit-modal'} okText={'Upgrade Now'} cancelText={'Cancel'} visible={visible} onOk={clickUpgrade} onCancel={onCancel}>
        <h2>{'You\'ve reached your free QR code limit'}</h2>
        <p style={{margin: 0}}>
            You have already created 1 free QR code. Upgrade now to access unlimited Pro QR codes.
        </p>
        </Modal>
    );
};
