import * as React from 'react';
import './AppLoader.scss';
import Logo from "./misc/Logo";

function Loader() {
  return (
    <div className="app-loader">
      <Logo />
      <p>Loading...</p>
    </div>
  )
}

export default Loader;
