import {env} from "../env/env";
import SockJS from "sockjs-client";

let socket: WebSocket = null;

export function init() {
  socket = new SockJS(`${env.wsPath}/connection`, null, { transports: ['websocket'] });
}

export function socketConnect(businessId: string, initials: string, internalId: number) {
  if (!socket) {
    init();
  }
  socket.onclose = () => {
    console.log("=> Server Disconnect You");
  };

  socket.onopen = () => {
    console.log("=> connected");
    socket.send(JSON.stringify({type: 'join', data: `${businessId},${initials},${internalId}`}));
  };
}
