import {AxiosPromise} from "axios";
import {
  CustomTaxDto,
  TaxResourceApi,
} from "../gen/client";
import {apiConfig, ops} from "./ApiConfig";

const api = new TaxResourceApi(apiConfig);

const taxApi = {
  getTaxes(businessId: string): AxiosPromise<CustomTaxDto[]> {
    return api.getBusinessTaxes(businessId, ops());
  },

  createTax(businessId: string, dto: CustomTaxDto): AxiosPromise<CustomTaxDto> {
    return api.createTax(businessId, dto, ops());
  },

  updateTax(businessId: string, taxId: string, dto: CustomTaxDto): AxiosPromise<CustomTaxDto> {
    return api.updateTax(businessId, taxId, dto, ops());
  },

  deleteTax(businessId: string, taxId: string): AxiosPromise<Boolean> {
    return api.removeTax(businessId, taxId, ops());
  },
}

export default taxApi;