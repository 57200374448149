import * as React from 'react';
import {useState} from 'react';
import './LoginForm.scss';
import {message, Form, Input, Button} from "antd";
import Storage from "../util/Storage";
import {handleServerError} from "../util/ErrorHandler";
import {AuthResponseDto,} from "../gen/client";
import {AxiosError} from "axios";
import AuthApi from "../api/AuthApi";
import {NavLink} from "react-router-dom";

const {Item} = Form;
const {Password} = Input;

interface AuthFormProps {
  withHeader?: boolean;
  withReset?: boolean;
  large?: boolean;
  onSuccess: (member: AuthResponseDto) => void;
  onError: (error: AxiosError) => void;
}

function LoginForm({onSuccess, onError, withHeader = true, large = true, withReset = true}: AuthFormProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setLoading] = useState(false);

  const onFinish = () => {
    if (!email || !password) {
      message.error('Email and password are required!');
      return;
    }

    setLoading(true);
    AuthApi.login({email, password}).then(resp => {
      setLoading(false);

      // dispatch(setLogged(true));
      // dispatch(setMember(resp.data.member));

      Storage.setToken(resp.data.token);
      Storage.setMemberId(resp.data.member.id);
      onSuccess(resp.data);
    }).catch(err => {
      setLoading(false);
      handleServerError(err);
      onError(err);
    });
  };

  return (
    <Form onFinish={onFinish}  className={`auth-form ${large ? 'lg' : ''}`}>
      {withHeader && <h1>{'Log in'}</h1>}
      <Item>
        <label>{'Email'}</label>
        <Input inputMode={'email'} size="large" placeholder={'yourEmail'} value={email} onChange={(ev) => setEmail(ev.target.value)}/>
      </Item>
      <Item>
        <label>{'password'}</label>
        <Password size={'large'} placeholder={'yourPassword'} value={password} onChange={(ev) => setPassword(ev.target.value)}/>
      </Item>
      <Item>
        <div className={'btn-ctr'}>
          <Button size="large" type="primary" htmlType="submit" className={large ? 'btn-xl' : ''} loading={isLoading}>{'Log In'}</Button>
        </div>
        {withReset && <NavLink className={'lft reset'} to={'pass-forgot'}>{'Forgot Password'}</NavLink>}
      </Item>
    </Form>
  );
}

export default LoginForm;
