import {AxiosPromise} from "axios";
import {
  BrandLogoDto, BrandColorDto,
  BrandResourceApi
} from "../gen/client";
import {apiConfig, ops} from "./ApiConfig";
import Storage from "../util/Storage";

const api = new BrandResourceApi(apiConfig);

const BrandsApi = {
  getColors(locationId: number): AxiosPromise<BrandColorDto[]> {
    return api.getBrandColors(locationId, ops());
  },

  getLogos(locationId: number): AxiosPromise<BrandLogoDto[]> {
    return api.getBrandLogos(locationId, ops());
  },

  uploadLogo(locationId: number, image: any): AxiosPromise<BrandLogoDto> {
    return api.uploadBrandLogo(locationId, Storage.getToken(), image, ops());
  },
};

export default BrandsApi;
