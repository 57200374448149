import React from 'react';
import {Switch} from 'antd';
import {useState} from 'react';
import {HourFormatType} from '../../util/types';
import styles from './HourFormat.module.scss';

interface HourFormatProps {
  onChange: (type: HourFormatType) => void;
  value: HourFormatType;
}

export function HourFormat({onChange, value}: HourFormatProps) {

  const [amPmFormat, setAmPmFormat] = useState(value === HourFormatType.AM_PM);

  function toggleHourFormat() {
    setAmPmFormat(!amPmFormat);
    onChange(!amPmFormat ? HourFormatType.AM_PM : HourFormatType._24);
  }

  return (
    <div className={styles.container}>
      <span>{'AM/PM'}</span>
      <Switch className={styles.switch} defaultChecked={!amPmFormat} onChange={toggleHourFormat} />
      <span>{'24h'}</span>
    </div>
  );
}
