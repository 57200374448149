import {Form, Select} from "antd";
import {Countries} from "../../util/countries";
import * as React from "react";
import {defaultSelectFilterOption, defaultSelectFilterSort} from "../../util/Utils";
import './NativeSelect.scss';

const {Option} = Select;

interface CountryDropdownProps {
  onChange: (value: string) => void;
  tabIndex: number;
  native?: boolean;
  light?: boolean;
}

export default function CountryDropdown({onChange, tabIndex, native, light}: CountryDropdownProps) {
  return <>
    <Form.Item name="country" label={light ? null : 'Country'} className={`${native ? 'hidden' : ''}`}>
      <Select tabIndex={tabIndex} size={"large"} onChange={onChange}
              showSearch optionFilterProp="children"
              filterOption={defaultSelectFilterOption} filterSort={defaultSelectFilterSort}>
        <Option value={""} disabled={true}>Select Country</Option>
        {Countries.map(it => <Option key={it} value={it}>{it}</Option>)}
      </Select></Form.Item>

    {native && <div className={'ant-row ant-form-item'}>
        <div className="ant-col ant-form-item-label"><label>Country</label></div>
        <select
            className={'native-select ant-select ant-select-lg ant-select-single ant-select-selector ant-form-item-control-input'}
            tabIndex={tabIndex} onChange={(e) => onChange(e.target.value)}>
          {Countries.map(it => <option key={it} value={it}>{it}</option>)}
        </select></div>}
  </>;
}
