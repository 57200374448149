import * as React from 'react';
import {useState} from 'react';
import './StartFormAccount.scss';
import {Form, Input, Button} from "antd";
import {AuthResponseDto} from "../gen/client";
import {AxiosError} from "axios";

interface StartFormAccountProps {
  withHeader?: boolean;
  name?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  large?: boolean;
  onSuccess: (member: AuthResponseDto) => void;
  onError: (error: AxiosError) => void;
}

export default function StartFormAccount({name = 'Your Business', address, city, state, zip, phone, onSuccess, onError, withHeader = true, large = true}: StartFormAccountProps) {

  const [isLoading, setLoading] = useState(false);

  function register(values: any) {

    setLoading(true);
    // AuthApi.register({email, password, name, address, city, state, zip, phone}).then(resp => {
    //   setLoading(false);
    //   dispatch(setLogged(true));
    //   Storage.setToken(resp.data.token);
    //   Storage.setMemberId(resp.data.member.id);
    //   message.success(t('registerSuccess'), 3);
    //   onSuccess(resp.data);
    //   reportSignUpConversion();
    // }).catch(err => {
    //   setLoading(false);
    //   handleServerError(err);
    //   onError(err);
    // });
  }

  return (
    <Form className={'step stacked start-form-acct'} initialValues={{remember: true}} onFinish={register}>
      {withHeader && <h1>{'Your Account'}</h1>}

      <Form.Item
        name="email"
        label={'Email'}
        rules={[{required: true, message: 'pleaseEmail'}, {type: 'email', message: 'pleaseEmail'}]}>
        <Input size="large" placeholder={'yourEmail'} inputMode={'email'}/>
      </Form.Item>

      <Form.Item
        name="password"
        label={'password'}
        rules={[{required: true, message: 'pleasePassword'}]}>
        <Input.Password size="large" placeholder={'yourPassword'}/>
      </Form.Item>
      <Form.Item>
        <div className={'btn-ctr'}>
          <Button size="large" type="primary" htmlType="submit" className={large ? 'btn-xl' : ''} loading={isLoading}>{'Register'}</Button>
        </div>
      </Form.Item>
    </Form>
  );
}
