import {Form, Select} from "antd";
import {States} from "../../util/countries";
import * as React from "react";
import {defaultSelectFilterOption, defaultSelectFilterSort} from "../../util/Utils";
import './NativeSelect.scss';

const {Option} = Select;

interface StateDropdownProps {
  onChange: (value: string) => void;
  tabIndex: number;
  required?: boolean;
  native?: boolean;
  light?: boolean;
}

export default function StateDropdown({onChange, tabIndex, required, native, light}: StateDropdownProps) {

  return <>
    {!native && <Form.Item name="state" label={light ? null : 'State/Province'} rules={[{required: true, message: 'Please specify the state.'}]}
               className={`${native ? 'hidden' : ''}`}>
      <Select tabIndex={tabIndex} size={"large"} onChange={onChange}
              showSearch optionFilterProp="children"
              virtual={false}
              filterOption={defaultSelectFilterOption} filterSort={defaultSelectFilterSort}>
        <Option value={""} disabled={true}>Select State</Option>
        {Object.keys(States).map(it => <Option key={it} value={it}>{it}</Option>)}
      </Select>
    </Form.Item>}

    {native && <div className={'ant-row ant-form-item'}>
        <div className="ant-col ant-form-item-label"><label className="ant-form-item-required">{'State/Province'}</label></div>
        <select
            className={'native-select ant-select ant-select-lg ant-select-single ant-select-selector ant-form-item-control-input'}
            tabIndex={tabIndex} onChange={(e) => onChange(e.target.value)}>
          {Object.keys(States).map(it => <option key={it} value={it}>{it}</option>)}
        </select></div>}
  </>;
}
