import * as React from 'react';
import {Card, Switch} from "antd";
import {BusinessDto} from '../../../gen/client';
import './SettingsSite.scss';
import {useState} from 'react';
import PublishModal from "../PublishModal";
import Loader from "../../misc/Loader";

interface SettingsSiteProps {
  business: BusinessDto;
  publishing: boolean;
  isPublished: boolean;
  onPublish: (published: boolean) => void;
  checkPublishingPossibility: () => void;
}

export default function SettingsSite({onPublish, publishing, isPublished, checkPublishingPossibility}: SettingsSiteProps) {
  const [publishModal, setPublishModal] = useState(false);

  function switchClicked() {
    if (!isPublished) {
      checkPublishingPossibility();
    } else {
      setPublishModal(true);
    }
  }

  return <Card className={'settings-site'}>
    <header>
      <h3>Published Status</h3>
    </header>
    <section>
      {!publishing && <label>Published</label>}
      {!publishing && <Switch className={"sp"} checked={isPublished} onClick={switchClicked} />}

      {publishing && <Loader/>}
    </section>
    <PublishModal unpublish={isPublished} visible={publishModal} onConfirm={() => {onPublish(!isPublished); setPublishModal(false);}} onCancel={() => setPublishModal(false)}/>
  </Card>;

};
