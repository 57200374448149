import * as React from 'react';
import './OrderNowButton.scss';
import {BusinessDto} from "../../gen/client";

interface OrderNowButtonProps {
  business: BusinessDto;
  isPreview: boolean;
}

export default function OrderNowButton({business, isPreview}: OrderNowButtonProps) {
  return <>
    {!isPreview && <a className={`call-btn`}
            href={`tel:${business?.config?.orderNowPhone}`}
            style={{backgroundColor: business?.config?.bannerColor, color: business?.config?.bannerTextColor}}>
    Call to Order Now
    </a>}

    {isPreview && <button className={'call-btn preview'}
                          style={{backgroundColor: business?.config?.bannerColor, color: business?.config?.bannerTextColor}}>
        Call to Order Now
    </button>}
  </>
}
