export const loadJS = (href: string, callback: () => void) => {
  let doc = document, lnk = doc.createElement('script'), ref;
  const refs = (doc.body || doc.getElementsByTagName("head")[0]).childNodes;
  ref = refs[refs.length - 1];
  lnk.type = 'text/javascript';
  lnk.src = href;

  lnk.addEventListener("load", function () {
    if (callback !== undefined && typeof callback === 'function') callback();
  });

  ref?.parentNode?.insertBefore(lnk, ref.nextSibling);
  return lnk;
};

export const getJSPath = () => {
  const host = window.location.host;
  if (host === 'test.mhmfun.com') {
    return 'https://test.mhmfun.com/home-test/mhm.umd.cjs';
  } else if (host === 'staging.mhmfun.com' || window.location.href.includes('/xstage')) {
    return 'https://staging.mhmfun.com/home-stage/mhm.umd.cjs';
  } else if (host === 'www.musthavemenus.com' || host === 'ohbz.com') {
    return 'https://www.musthavemenus.com/home/mhm.umd.cjs';
  }
  return 'https://www.musthavemenus.com/home/mhm.umd.cjs';
}

declare global {
  interface SetupProps {
    memberId: string;
    onSuccess?: () => void;
  }

  interface Window {
    MHM: {
      ProModal: {
        upgrade: (props: SetupProps) => void;
        cancel: (props: SetupProps) => void;
        reactivate: (props: SetupProps) => void;
        pause: (props: SetupProps) => void;
        resume: (props: SetupProps) => void;
        destroy: () => void;
      }
    }
  }
}

//@ts-ignore
window.MHM = window.MHM || {};
