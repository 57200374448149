import * as React from 'react';
import { Button, Modal, Tooltip, Upload } from "antd";
import { MenuItemIcon } from '../../../domain/Menu';
import "./MenuIconModal.scss";
import { CloseOutlined, DeleteOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { getIconUrl, getUploadedIconUrl } from '../../../util/Utils';
import { useEffect, useState } from 'react';
import { ImageTypeEnum, MemberIconDto, MemberImageDto } from '../../../gen/client';
import MemberApi from '../../../api/MemberApi';
import Storage from '../../../util/Storage';
import { useRecoilState } from 'recoil';
import { currentLocation } from '../../../store/atoms';
import { ALLOWED_IMAGE_TYPES, MAX_IMAGE_SIZE } from '../../../util/constants';
import FileTooBigModal from '../../misc/FileTooBigModal';
import FileInvalidTypeModal from '../../misc/FileInvalidTypeModal';
import { handleServerError } from '../../../util/ErrorHandler';

const menuIconsGeneral: MenuItemIcon[] = [];
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-heart', hoverText: 'Favorite', altText: 'A heart shaped menu icon indicating that this item is a favorite or best seller.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-burst', hoverText: 'Special', altText: 'A multi-point starburst menu icon indicating that this item is a special or some other significant call out.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-star', hoverText: 'Special', altText: 'A five point star menu icon to indicate this this ia a special menu item, eligible for promotion, is a favorite, or best seller.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-fire', hoverText: 'Spicy', altText: 'A flame icon within a circle used to indicate that a menu item is spicy.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-flame', hoverText: 'Spicy', altText: 'A flame icon used to indicate that a menu item is spicy.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-spicy-1', hoverText: 'Spicy', altText: 'A chili pepper icon used to indicate that a menu item is spicy.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-new-circle', hoverText: 'New Item', altText: 'A menu icon within a circle indicating that this is a new menu item.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-new-rotated', hoverText: 'New Item', altText: 'A menu icon indicating that this is a new menu item.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-raw', hoverText: 'Raw', altText: 'A menu icon indicating that this menu item is, or contains, raw ingredients.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-r-circle', hoverText: 'Raw', altText: 'A sans serif R appears in a circle indicating that this menu item is, or contains, raw ingredients.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-r', hoverText: 'Raw', altText: 'A serif font R menu icon indicates that this menu item is, or contains, raw ingredients.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-checkbox', hoverText: 'Square', altText: 'A simple square outline menu icon, commonly used as a bullet, or a check box for build your own type menu items.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-dot', hoverText: 'Circle', altText: 'A simple circle menu icon, commonly used as a bullet point.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-arrow-right', hoverText: 'Arrow', altText: 'An arrow head menu icon, commonly used as a decorative point.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-utensils-1', hoverText: 'General', altText: 'A menu icon of a fork and knife within a circle.  Refer to menu legend for use case.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-utensils-2', hoverText: 'General', altText: 'A menu icon of a fork and knife within a circle.  Refer to menu legend for use case.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-utensils-3', hoverText: 'General', altText: 'A menu icon of a fork and spoon within a heart.  Refer to menu legend for use case.' });
menuIconsGeneral.push({ type: 'svg', iconKey: 'menu-icon-utensils-4', hoverText: 'General', altText: 'A menu icon of a fork and spoon within a heart.  Refer to menu legend for use case.' });

const menuIconsDietary: MenuItemIcon[] = [];
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-vg', hoverText: 'Veg/Vegan', altText: 'The letters VG, in a serif font, indicating this menu item is vegetarian, or vegan, depending on individual establishment application.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-vg-circle', hoverText: 'Veg/Vegan', altText: 'The letters VG, in a sans serif font, within a circle indicating this menu item is vegetarian, or vegan, depending on individual establishment application.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-v', hoverText: 'Veg/Vegan', altText: 'The letter V in a serif font, indicating this menu item is vegetarian, or vegan, depending on individual establishment application.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-v-circle', hoverText: 'Veg/Vegan', altText: 'The letter V in a sans serif font, within a circle indicating this menu item is vegetarian, or vegan, depending on individual establishment application.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-v-1', hoverText: 'Veg/Vegan', altText: 'An icon of the word vegan, within a heart, indicating that this menu item is suitable for vegans.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-v-2', hoverText: 'Veg/Vegan', altText: 'An icon of the letter v, within a heart, indicating this menu item is vegetarian, or vegan, depending on the use case of a restaurant.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-v-3', hoverText: 'Veg/Vegan', altText: 'An icon of the letter v, with a plant leaf sprouting off of it, encased in a circle, indicating this menu item is vegetarian, or vegan, depending on the use case of a restaurant.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-v-4', hoverText: 'Veg/Vegan', altText: 'An icon of a single plant leaf, within a circle, indicating that this menu item contains plant based ingredients, is vegetarian, or vegan, depending on individual establishment application.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-v-5', hoverText: 'Veg/Vegan', altText: 'An icon of two plant leaves, within a circle, indicating that this menu item contains plant based ingredients, is vegetarian, or vegan, depending on individual establishment application.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-leaf', hoverText: 'Veg/Vegan', altText: 'A single plant leaf menu icon indicates this menu item contains plant based ingredients, is vegetarian, or vegan, depending on individual establishment application.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-organic', hoverText: 'Veg/Vegan', altText: 'Two plant leaves within a circle indicate this menu item contains plant based ingredients, is vegetarian, or vegan, depending on individual establishment application.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-v-8', hoverText: 'Veg/Vegan', altText: 'An icon of the word vegan, with plant leaves sprouting off the g, indicating that this menu item is suitable for vegans.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-v-9', hoverText: 'Veg/Vegan', altText: 'An icon of the word vegan with plant leaves encased in a banner type frame, indicating that this menu item is suitable for vegans.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-v-10', hoverText: 'Veg/Vegan', altText: 'An angular icon with the word vegan on it, indicating that this menu item is suitable for vegans.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-v-11', hoverText: 'Veg/Vegan', altText: 'An icon of the word meat, crossed out, within a circle, indicating that this menu item does not contain meat, and is suitable for vegetarians.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-u-circle', hoverText: 'Kosher', altText: 'The letter U, in a sans serif font, indicating that this menu item is 100% Kosher.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-halal', hoverText: 'Halal', altText: 'A Halal linework menu icon indicating that this menu item is acceptable to eat according to Islamic law.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-halal-circle', hoverText: 'Halal', altText: 'A Halal menu icon within a circle indicating that this menu item is acceptable to eat according to Islamic law.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-sugarfree-serif', hoverText: 'Sugar Free', altText: 'The letters SF, in a serif font, indicating that this menu item is sugar free.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-sugarfree-sans-serif', hoverText: 'Sugar Free', altText: 'The letters SF, in a sans serif font, indicating that this menu item is sugar free.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-sugarfree-circle', hoverText: 'Sugar Free', altText: 'The letters SF, in a sans serif font, within a circle, indicating that this menu item is sugar free.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-sugar', hoverText: 'Contains Sugar', altText: 'An image of two cubes, representing sugar, within a circle.  This icon indicates that the menu item contains sugar.' });
menuIconsDietary.push({ type: 'svg', iconKey: 'menu-icon-sugarfree', hoverText: 'Sugar Free', altText: 'An image of two cubes, representing sugar, crossed out, within a circle.  This menu icon indicates the the associated item is sugar free.' });

const menuIconsGlutenWheat: MenuItemIcon[] = [];
menuIconsGlutenWheat.push({ type: 'svg', iconKey: 'menu-icon-gluten-1', hoverText: 'Contains Gluten/Wheat', altText: 'An image of wheat within a circle, indicating that this menu item contains gluten or wheat ingredients.' });
menuIconsGlutenWheat.push({ type: 'svg', iconKey: 'menu-icon-gluten-2', hoverText: 'Contains Gluten/Wheat', altText: 'An image of wheat within a circle, indicating that this menu item contains gluten or wheat ingredients.' });
menuIconsGlutenWheat.push({ type: 'svg', iconKey: 'menu-icon-gluten-3', hoverText: 'Contains Gluten/Wheat', altText: 'An image of wheat within a circle, indicating that this menu item contains gluten or wheat ingredients.' });
menuIconsGlutenWheat.push({ type: 'svg', iconKey: 'menu-icon-gluten-4', hoverText: 'Contains Gluten/Wheat', altText: 'An image of wheat within a circle, indicating that this menu item contains gluten or wheat ingredients.' });
menuIconsGlutenWheat.push({ type: 'svg', iconKey: 'menu-icon-gluten-5', hoverText: 'Contains Gluten/Wheat', altText: 'An image of wheat within a circle, indicating that this menu item contains gluten or wheat ingredients.' });
menuIconsGlutenWheat.push({ type: 'svg', iconKey: 'menu-icon-gluten-allergen', hoverText: 'Contains Gluten/Wheat', altText: 'An icon showing a symbol of wheat, within a circle, indicating that this menu item contains gluten or wheat, depending on the application by the restaurant.' });
menuIconsGlutenWheat.push({ type: 'svg', iconKey: 'menu-icon-gf', hoverText: 'Gluten Free', altText: 'The letters GF, in a sans serif font, within a circle indicating this menu item is Gluten Free.' });
menuIconsGlutenWheat.push({ type: 'svg', iconKey: 'menu-icon-gf-1', hoverText: 'Gluten Free', altText: 'The letters GF, in a serif font, indicating that this menu item is Gluten Free.' });
menuIconsGlutenWheat.push({ type: 'svg', iconKey: 'menu-icon-gf-2', hoverText: 'Gluten/Wheat Free', altText: 'A menu icon with two stalks of wheat crossed out, within a circle.  This menu icon, depending on the usage of the establishment, could indicate a wheat free or gluten free menu item.' });
menuIconsGlutenWheat.push({ type: 'svg', iconKey: 'menu-icon-no-wheat', hoverText: 'Gluten/Wheat Free', altText: 'A menu icon showing wheat crossed out, within a circle.  This menu icon, depending on the usage of the establishment, could indicate a wheat free or gluten free menu item.' });
menuIconsGlutenWheat.push({ type: 'svg', iconKey: 'menu-icon-gf-3', hoverText: 'Gluten/Wheat Free', altText: 'An image of wheat, crossed out, within a circle, indicating that this menu item is gluten free or wheat free.' });
menuIconsGlutenWheat.push({ type: 'svg', iconKey: 'menu-icon-gf-4', hoverText: 'Gluten/Wheat Free', altText: 'An image of wheat, crossed out, within a circle, indicating that this menu item is gluten free or wheat free.' });
menuIconsGlutenWheat.push({ type: 'svg', iconKey: 'menu-icon-gf-5', hoverText: 'Gluten/Wheat Free', altText: 'An image of wheat, crossed out, within a circle, indicating that this menu item is gluten free or wheat free.' });
menuIconsGlutenWheat.push({ type: 'svg', iconKey: 'menu-icon-gf-6', hoverText: 'Gluten/Wheat Free', altText: 'An image of wheat, crossed out, within a circle, indicating that this menu item is gluten free or wheat free.' });
menuIconsGlutenWheat.push({ type: 'svg', iconKey: 'menu-icon-gf-7', hoverText: 'Gluten/Wheat Free', altText: 'An image of wheat, crossed out, within a circle, indicating that this menu item is gluten free or wheat free.' });

const menuIconsNutsSeeds: MenuItemIcon[] = [];
menuIconsNutsSeeds.push({ type: 'svg', iconKey: 'menu-icon-nuts-1', hoverText: 'Contains Nuts', altText: 'An icon of a peanut, indicating that this menu item contains nuts.' });
menuIconsNutsSeeds.push({ type: 'svg', iconKey: 'menu-icon-nuts-2', hoverText: 'Contains Nuts', altText: 'A freestanding icon of a peanut, indicating that this menu item contains nuts.' });
menuIconsNutsSeeds.push({ type: 'svg', iconKey: 'menu-icon-nuts-3', hoverText: 'Contains Nuts', altText: 'An icon of a peanut, within a circle, indicating that this menu item contains nuts.' });
menuIconsNutsSeeds.push({ type: 'svg', iconKey: 'menu-icon-nuts-4', hoverText: 'Contains Nuts', altText: 'An icon of a peanut, within a circle, indicating that this menu item contains nuts.' });
menuIconsNutsSeeds.push({ type: 'svg', iconKey: 'menu-icon-peanuts-allergen', hoverText: 'Contains Nuts', altText: 'An icon of a peanut within a circle, indicating that this menu item contains nuts.' });
menuIconsNutsSeeds.push({ type: 'svg', iconKey: 'menu-icon-nuts', hoverText: 'Contains Nuts', altText: 'An icon of an acorn, indicating that this menu item contains tree nuts.' });
menuIconsNutsSeeds.push({ type: 'svg', iconKey: 'menu-icon-tree-nuts-allergen', hoverText: 'Contains Nuts', altText: 'An icon showing tree nuts, within a circle, indicating that this menu item contains tree nuts.' });
menuIconsNutsSeeds.push({ type: 'svg', iconKey: 'menu-icon-nuts-free', hoverText: 'Nut Free', altText: 'A menu icon of a peanut, crossed out, within a circle, indicating that this menu item is nut free, or does not contain nuts.' });
menuIconsNutsSeeds.push({ type: 'svg', iconKey: 'menu-icon-no-nuts', hoverText: 'Nut Free', altText: 'An icon of a peanut, crossed out, within a circle, indicating that this menu item contains nuts.' });
menuIconsNutsSeeds.push({ type: 'svg', iconKey: 'menu-icon-nut-free-3', hoverText: 'Nut Free', altText: 'An icon of a peanut, crossed out, within a circle, indicating that this menu item contains nuts.' });
menuIconsNutsSeeds.push({ type: 'svg', iconKey: 'menu-icon-sesame', hoverText: 'Contains Sesame', altText: 'A menu icon indicating that this menu item contains sesame seeds.' });
menuIconsNutsSeeds.push({ type: 'svg', iconKey: 'menu-icon-sesame-allergen', hoverText: 'Contains Sesame', altText: 'Three small sesame seeds within a circle, indicating that this menu contains contains sesame.' });
menuIconsNutsSeeds.push({ type: 'svg', iconKey: 'menu-icon-sesame-free', hoverText: 'Sesame Free', altText: 'A menu icon indicating that this menu item is sesame-free.' });

const menuIconsSeafood: MenuItemIcon[] = [];
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-fish', hoverText: 'Contains Fish', altText: 'A fish icon, indicating that this menu item contains fish, or is good for a pescetarian diet.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-shellfish-1', hoverText: 'Contains Shellfish', altText: 'An icon of a shrimp, indicating that this menu item contains shellfish.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-shellfish-2', hoverText: 'Contains Shellfish', altText: 'An icon of a shell, indicating that this menu contains shellfish.  This menu icon may also be used for mollusks in particular, depending on its application by the establishment.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-shellfish-3', hoverText: 'Contains Shellfish', altText: 'An icon of a crab, indicating that this menu contains shellfish.  This menu icon may also be used for crustaceans in particular, depending on its application by the establishment.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-shellfish-4', hoverText: 'Contains Shellfish', altText: 'A freestanding icon of a lobster, indicating that this menu item contains shellfish.  This menu icon may also be used specifically for crustaceans, depending on its application by the establishment.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-shellfish-5', hoverText: 'Contains Shellfish', altText: 'An image of a shrimp within a circle, indicating that this menu item contains shellfish.  This menu icon may also be used specifically for mollusks, depending on its application by the establishment.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-shellfish-6', hoverText: 'Contains Shellfish', altText: 'An image of a shell within a circle, indicating that this menu item contains shellfish.  This menu icon may also be used specifically for mollusks, depending on its application by the establishment.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-shellfish-7', hoverText: 'Contains Shellfish', altText: 'An image of a crab within a circle, indicating that this menu item contains shellfish.  This menu icon may also be used specifically for mollusks, depending on its application by the establishment.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-shellfish-8', hoverText: 'Contains Shellfish', altText: 'An icon of a lobster within a circle, indicating that this menu item contains shellfish.  This menu icon may also be used specifically for crustaceans, depending on its application by the establishment.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-fish-2', hoverText: 'Contains Fin Fish', altText: 'An icon of a fish within a circle, indicating that this menu item contains fin fish, or is suitable for a pescetarian diet.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-molluscs-allergen', hoverText: 'Contains Shellfish', altText: 'An image of a shell within a circle, indicating that this menu item contains shellfish.  This menu icon may also be used specifically for mollusks, depending on its application by the establishment.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-crustaceans-allergen', hoverText: 'Contains Shellfish', altText: 'An image of a crab within a circle, indicating that this menu item contains shellfish.  This menu icon may also be used specifically for crustaceans, depending on its application by the establishment.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-fish-allergen', hoverText: 'Contains Fin Fish', altText: 'An image of a fish within a circle, indicating that this menu item contains fish, or is good for a pescetarian diet.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-shellfish-free-1', hoverText: 'Shellfish Free', altText: 'An image of a shrimp, crossed out, within a circle, indicating that this menu item is shellfish free, or does not contain shrimp.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-shellfish-free-2', hoverText: 'Shellfish Free', altText: 'An image of a shell, crossed out, within a circle, indicating that this menu item is shellfish free, or does not contain mollusks.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-shellfish-free-3', hoverText: 'Shellfish Free', altText: 'An image of a crab, crossed out, within a circle, indicating that this menu item is shellfish free, or does not contain custaceans.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-shellfish-free-4', hoverText: 'Shellfish Free', altText: 'An image of a lobster, crossed out, within a circle, indicating that this menu item is shellfish free, or does not contain custaceans.' });
menuIconsSeafood.push({ type: 'svg', iconKey: 'menu-icon-fish-free', hoverText: 'Fin Fish Free', altText: 'An icon of a fish, crossed out, within a circle, indicating that this menu item does not contain fin fish.' });

const menuIconsEggsDairy: MenuItemIcon[] = [];
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-eggs-allergen', hoverText: 'Contains Eggs', altText: 'An image of an egg, within a circle, indicating that this menu item contains eggs.' });
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-eggs-2', hoverText: 'Contains Eggs', altText: 'An image of an egg within a circle, indicating that this menu item contains eggs.' });
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-egg-free', hoverText: 'Egg Free', altText: 'An icon with an image of an egg, crossed out, within a circle, indicating that this menu item is egg free, or does not contain eggs.' });
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-egg-free-2', hoverText: 'Egg Free', altText: 'An image of an egg, crossed out, within a circle, indicating that this menu item is egg free.' });
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-df-circle', hoverText: 'Dairy Free', altText: 'The letters DF, in a sans serif font, within a circle, indicating that this menu item is dairy free.' });
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-lactose-free', hoverText: 'Lactose Free', altText: 'The letters LF, in a sans serif font, within a circle, indicating that this menu item is lactose free.' });
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-dairy-1', hoverText: 'Contains Dairy', altText: 'An image of a bottle of milk within a circle, indicating that this menu item contains dairy.' });
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-dairy-2', hoverText: 'Contains Dairy', altText: 'An image of a bottle of milk within a circle, indicating that this menu item contains dairy.' });
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-dairy-3', hoverText: 'Contains Dairy', altText: 'An image of a bottle of milk within a circle, indicating that this menu item contains dairy.' });
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-dairy-4', hoverText: 'Contains Dairy', altText: 'An image of a milk carton within a circle, indicating that this menu item contains dairy.' });
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-dairy-5', hoverText: 'Contains Dairy', altText: 'An image of a milk carton within a circle, indicating that this menu item contains dairy.' });
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-dairy-allergen', hoverText: 'Contains Dairy', altText: 'An icon showing a jug of milk within a circle, indicating that this menu item contains dairy.' });
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-dairy-free-1', hoverText: 'Dairy Free', altText: 'An image of a bottle of milk, crossed out, within a circle, indicating that this menu item is dairy free.' })
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-dairy-free-2', hoverText: 'Dairy Free', altText: 'An image of a bottle of milk, crossed out, within a circle, indicating that this menu item is dairy free.' })
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-dairy-free-3', hoverText: 'Dairy Free', altText: 'An image of a bottle of milk, crossed out, within a circle, indicating that this menu item is dairy free.' })
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-dairy-free', hoverText: 'Dairy Free', altText: 'A menu icon with a carton of milk crossed out, within a circle, indicating that this menu item is dairy free.' });
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-dairy-free-5', hoverText: 'Dairy Free', altText: 'An image of a milk carton, crossed out, within a circle, indicating that this menu item is dairy free.' });
menuIconsEggsDairy.push({ type: 'svg', iconKey: 'menu-icon-dairy-free-6', hoverText: 'Dairy Free', altText: 'An image of a milk carton, crossed out, within a circle, indicating that this menu item is dairy free.' });

const menuIconsCornSoy: MenuItemIcon[] = [];
menuIconsCornSoy.push({ type: 'svg', iconKey: 'menu-icon-corn', hoverText: 'Contains Corn', altText: 'A menu icon depicting an ear of corn, indicating that this menu icon contains corn.' });
menuIconsCornSoy.push({ type: 'svg', iconKey: 'menu-icon-corn-free', hoverText: 'Corn Free', altText: 'A menu icon of an ear of corn, crossed out, within a circle, indicating that this menu item is corn free, or does not contain corn.' });
menuIconsCornSoy.push({ type: 'svg', iconKey: 'menu-icon-soy-1', hoverText: 'Contains Soy', altText: 'An icon of a soy bean, within a circle, indicating that this menu item contains soy, or has soy-based ingredients.' });
menuIconsCornSoy.push({ type: 'svg', iconKey: 'menu-icon-soy-2', hoverText: 'Contains Soy', altText: 'An icon of a soy bean, within a circle, indicating that this menu item contains soy, or has soy-based ingredients.' });
menuIconsCornSoy.push({ type: 'svg', iconKey: 'menu-icon-soy-3', hoverText: 'Contains Soy', altText: 'An icon of a soy bean, within a circle, indicating that this menu item contains soy, or has soy-based ingredients.' });
menuIconsCornSoy.push({ type: 'svg', iconKey: 'menu-icon-soy-allergen', hoverText: 'Contains Soy', altText: 'An image of a soybean, within a circle, indicating that this menu item contains soy, or soy derived ingredients.' });
menuIconsCornSoy.push({ type: 'svg', iconKey: 'menu-icon-soy-free-1', hoverText: 'Soy Free', altText: 'An icon of a soy bean, crossed out, within a circle, indicating that this menu item is soy free.' });
menuIconsCornSoy.push({ type: 'svg', iconKey: 'menu-icon-soy-free-2', hoverText: 'Soy Free', altText: 'An icon of a soy bean, crossed out, within a circle, indicating that this menu item is soy free.' });
menuIconsCornSoy.push({ type: 'svg', iconKey: 'menu-icon-soy-free-3', hoverText: 'Soy Free', altText: 'An icon of a soy bean, crossed out, within a circle, indicating that this menu item is soy free.' });

const menuIconsKeto: MenuItemIcon[] = [];
menuIconsKeto.push({ type: 'svg', iconKey: 'menu-icon-keto-2', hoverText: 'Keto', altText: 'A menu icon of the letter K,  indicating that this item is Keto friendly' });
menuIconsKeto.push({ type: 'svg', iconKey: 'menu-icon-keto-3', hoverText: 'Keto', altText: 'A menu icon of the letter K,  indicating that this item is Keto friendly' });
menuIconsKeto.push({ type: 'svg', iconKey: 'menu-icon-keto-4', hoverText: 'Keto', altText: 'A circular menu icon, with the letter K, indicating that this item is Keto friendly' });
menuIconsKeto.push({ type: 'svg', iconKey: 'menu-icon-keto-5', hoverText: 'Keto', altText: 'A circular menu icon, with the letter K, indicating that this item is Keto friendly' });
menuIconsKeto.push({ type: 'svg', iconKey: 'menu-icon-keto-6', hoverText: 'Keto', altText: 'A circular menu icon, with the letter K, indicating that this item is Keto friendly' });
menuIconsKeto.push({ type: 'svg', iconKey: 'menu-icon-keto-7', hoverText: 'Keto', altText: 'A menu icon of the letter K,  indicating that this item is Keto friendly' });
menuIconsKeto.push({ type: 'svg', iconKey: 'menu-icon-keto-8', hoverText: 'Keto', altText: 'A circular menu icon, with the letter K, indicating that this item is Keto friendly' });
menuIconsKeto.push({ type: 'svg', iconKey: 'menu-icon-keto-9', hoverText: 'Keto', altText: 'A circular menu icon, with the letter K, indicating that this item is Keto friendly' });
menuIconsKeto.push({ type: 'svg', iconKey: 'menu-icon-keto-10', hoverText: 'Keto', altText: 'A circular menu icon, with the letter K, indicating that this item is Keto friendly' });
menuIconsKeto.push({ type: 'svg', iconKey: 'menu-icon-keto', hoverText: 'Keto', altText: 'The word KETO, indicating that this menu item is keto-friendly, or good for keto diets.' });
menuIconsKeto.push({ type: 'svg', iconKey: 'menu-icon-keto-1', hoverText: 'Keto', altText: 'A menu icon of a drumstick and an egg, or an avocado, indicating that this item is Keto friendly' });
menuIconsKeto.push({ type: 'svg', iconKey: 'menu-icon-low-carb-1', hoverText: 'Low Carb', altText: 'A menu icon of a slice of bread, crossed out, within a circle, indicating that this menu item is low carb' });
menuIconsKeto.push({ type: 'svg', iconKey: 'menu-icon-low-carb-2', hoverText: 'Low Carb', altText: 'A menu icon of a slice of bread, crossed out, within a circle, indicating that this menu item is low carb' });

const menuIconsMiscAllergens: MenuItemIcon[] = [];
menuIconsMiscAllergens.push({ type: 'svg', iconKey: 'menu-icon-celery-allergen', hoverText: 'Contains Celery', altText: 'An icon showing a symbol of celery, within a circle, indicating that this menu item contains celery.' });
menuIconsMiscAllergens.push({ type: 'svg', iconKey: 'menu-icon-sulfur-dioxide-allergen', hoverText: 'Sulfur Dioxide', altText: 'A menu icon with a chemistry beaker, within a circle, indicating that this menu item contains ingredients with Sulphur Dioxide, or SO2.' });
menuIconsMiscAllergens.push({ type: 'svg', iconKey: 'menu-icon-lupin-allergen', hoverText: 'Contains Legumes', altText: 'A menu icon of three legumes, or beans, within a circle, indicating that this menu item contains legumes.' });
menuIconsMiscAllergens.push({ type: 'svg', iconKey: 'menu-icon-mustard-allergen', hoverText: 'Mustard', altText: 'An icon showing a bottle of mustard, within a circle, indicating that this menu item contains mustard.' });

const menuIconsHappyHour: MenuItemIcon[] = [];
menuIconsHappyHour.push({ type: 'svg', iconKey: 'menu-icon-hh-beer-1', hoverText: 'Happy Hour', altText: 'An icon depicting a mug of beer, indicating that this menu item is part of Happy Hour, or is eligible for Happy Hour pricing' });
menuIconsHappyHour.push({ type: 'svg', iconKey: 'menu-icon-hh-beer-2', hoverText: 'Happy Hour', altText: 'An icon of a glass of beer, indicating that this menu item is part of Happy Hour, or is eligible for Happy Hour pricing' });
menuIconsHappyHour.push({ type: 'svg', iconKey: 'menu-icon-hh-circle', hoverText: 'Happy Hour', altText: 'The letters HH, in a sans serif font, within a circle, indicating that this menu item is part of Happy Hour, or is eligible for Happy Hour pricing' });
menuIconsHappyHour.push({ type: 'svg', iconKey: 'menu-icon-hh', hoverText: 'Happy Hour', altText: 'Two Hs, in a sans serif font, within a circle, indicate that this item is part of Happy Hour, or is eligible for Happy Hour pricing.' });
menuIconsHappyHour.push({ type: 'svg', iconKey: 'menu-icon-hh-sans-serif', hoverText: 'Happy Hour', altText: 'The letters HH, in a sans serif font, indicating that this menu item is part of Happy Hour, or is eligible for Happy Hour pricing' });
menuIconsHappyHour.push({ type: 'svg', iconKey: 'menu-icon-hh-serif', hoverText: 'Happy Hour', altText: 'The letters HH, in a serif font, indicating that this menu item is part of Happy Hour, or is eligible for Happy Hour pricing' });
menuIconsHappyHour.push({ type: 'svg', iconKey: 'menu-icon-hh-wine-1', hoverText: 'Happy Hour', altText: 'A menu icon of two wine glasses clinking together, typically used to indicate an item is eligible for Happy Hour pricing' });
menuIconsHappyHour.push({ type: 'svg', iconKey: 'menu-icon-hh-wine-2', hoverText: 'Happy Hour', altText: 'A menu icon of two wine glasses clinking together, typically used to indicate an item is eligible for Happy Hour pricing' });
menuIconsHappyHour.push({ type: 'svg', iconKey: 'menu-icon-hh-wine-3', hoverText: 'Happy Hour', altText: 'A menu icon of a standalone pinot noir wine glass, commonly used to indicate this item is eligible for Happy Hour pricing' });
menuIconsHappyHour.push({ type: 'svg', iconKey: 'menu-icon-hh-wine-4', hoverText: 'Happy Hour', altText: 'A menu icon of a pinot noir wine glass within a circle, commonly used to indicate this item is eligible for Happy Hour pricing' });
menuIconsHappyHour.push({ type: 'svg', iconKey: 'menu-icon-hh-wine-5', hoverText: 'Happy Hour', altText: 'A menu icon of a pinot noir wine glass within a circle, commonly used to indicate this item is eligible for Happy Hour pricing' });
menuIconsHappyHour.push({ type: 'svg', iconKey: 'menu-icon-hh-wine-6', hoverText: 'Happy Hour', altText: 'A menu icon of a standalone pinot noir wine glass, commonly used to indicate this item is eligible for Happy Hour pricing' });
menuIconsHappyHour.push({ type: 'svg', iconKey: 'menu-icon-hh-wine-7', hoverText: 'Happy Hour', altText: 'A menu icon of a pinot noir wine glass within a circle, commonly used to indicate this item is eligible for Happy Hour pricing' });
menuIconsHappyHour.push({ type: 'svg', iconKey: 'menu-icon-hh-wine-8', hoverText: 'Happy Hour', altText: 'A menu icon of a pinot noir wine glass within a circle, commonly used to indicate this item is eligible for Happy Hour pricing' });


interface MenuIconModalProps {
  onClose: (e: React.MouseEvent) => void;
  visible: boolean;
  index: number;
  setIndex: (index: number) => void;
  updateIcon: (icon: MenuItemIcon, index: number) => void;
  deleteIcon: (index: number) => void;
  clearIcons: () => void;
  icons: MenuItemIcon[];
  memberIcons: MemberIconDto[];
  setMemberIcons: (icons: MemberIconDto[]) => void;
  setSelectedMemberImage: (image: MemberImageDto | MemberIconDto) => void;
  activeMenuIcons: MenuItemIcon[];
  updateMenuActiveIcons: () => void;
  setAltTextModal: (visible: boolean) => void;
}

export default function MenuIconModal({ visible, index, setIndex, icons, updateIcon, deleteIcon, clearIcons, memberIcons, setMemberIcons, activeMenuIcons, updateMenuActiveIcons, setSelectedMemberImage, setAltTextModal, onClose }: MenuIconModalProps) {
  const [location] = useRecoilState(currentLocation);
  const [uploadLoading, setUploadLoading] = useState(false);

  const [showFileTooBigModal, setShowFileTooBigModal] = useState(false);
  const [showFileInvalidModal, setShowFileInvalidModal] = useState(false);

  useEffect(() => {
    if (visible) {
      MemberApi.getMemberIcons(Storage.getMemberId(), location.internalId).then(resp => {
        setMemberIcons(resp.data.sort((a, b) => parseInt(b.createdDate || '0') - parseInt(a.createdDate || '0')));
      })
    }
  }, [visible, location.internalId, setMemberIcons])

  useEffect(() => {
    if (visible) {
      updateMenuActiveIcons();
    }
  }, [visible]) // eslint-disable-line

  function deselectIcon(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const target = ev.target as HTMLElement;
    if (!target.closest('li') && !target.closest('.delete-icon')) {
      setIndex(icons.length);
    }
  }

  function setMenuIcon(icon: MenuItemIcon) {
    updateIcon(icon, index);
  }

  const beforeUpload = (file: File) => {
    if (ALLOWED_IMAGE_TYPES.indexOf(file.type) < 0) {
      setShowFileInvalidModal(true);
      return false;
    }

    const mbSize = Math.floor(file.size / (1024 * 1024));
    if (mbSize >= MAX_IMAGE_SIZE) {
      setShowFileTooBigModal(true);
      return false;
    } else {
      uploadFile(file);
      return false;
    }
  };

  function uploadFile(file: File) {
    setUploadLoading(true);

    MemberApi.uploadIcon(Storage.getMemberId(), location.internalId, ImageTypeEnum.MenuItemIcon, file)
      .then((resp) => {
        MemberApi.getMemberIcons(Storage.getMemberId(), location.internalId).then(resp2 => {
          const newIconsList = resp2.data;
          setMemberIcons(newIconsList.sort((a, b) => parseInt(b.createdDate || '0') - parseInt(a.createdDate || '0')));
          setUploadLoading(false);

          const selected = newIconsList.find(i => i.path === resp.data.path);
          setSelectedMemberImage(selected);
          window.localStorage.setItem('alttext-just-uploaded', 'true');
          setAltTextModal(true);
        }).catch(e => {
          handleServerError(e);
          setUploadLoading(false);
        });
      })
      .catch((err) => {
        handleServerError(err);
        setUploadLoading(false);
      });
  };

  function onADAClick(ev: React.MouseEvent<HTMLDivElement, MouseEvent>, i: MemberIconDto) {
    ev.stopPropagation();
    setSelectedMemberImage(i);
    setAltTextModal(true);
  }

  function getExtensionFromPath(path: string) {
    const split = path.split('.');
    return split[split.length - 1];
  }

  const menuIconsList = (iconsList: MenuItemIcon[], label: string) => {
    return (<>
      <p className="icon-section-label">{label}</p>
      <ul>
        {iconsList.map((i => {
          return (<Tooltip title={i.hoverText} placement='bottom'>
            <li key={i.iconKey} onClick={() => setMenuIcon(i)}>
              <svg width="28px" height="28px" fill="#333" className="icon-entry">
                <use xlinkHref={getIconUrl(i.iconKey)}></use>
              </svg>
            </li>
          </Tooltip>)
        }))}
      </ul>
    </>)
  }

  return (<>
    <Modal okText={'Done'} onOk={onClose} cancelButtonProps={{ style: { display: 'none' } }} visible={visible} className='menu-icon-modal' maskClosable={true} mask={true}>
      <div onClick={(ev) => deselectIcon(ev)}>
        <h2>Add menu icon</h2>
        <div className='close-btn' onClick={onClose}><CloseOutlined /></div>
        {icons.length > 0 && <>
          <div className="icon-section-label">
            This Item
            <div className='icon-actions'>
              {icons.length > 0 &&
                <Button type={'default'} className={'btn-sm btn-sec-1 clear-icon'} onClick={() => clearIcons()}>
                  Clear All
                </Button>}
              {index < icons.length &&
                <div className='delete-icon' onClick={() => deleteIcon(index)}>
                  <DeleteOutlined />
                </div>}
            </div>
          </div>
          <ul>
            {icons.map((i, thisItemIndex) => {
              return (<li key={thisItemIndex} onClick={() => setIndex(thisItemIndex)} className={index === thisItemIndex ? 'active' : ''}>
                {i.path ?
                  <img className="icon-entry" src={getUploadedIconUrl(i)} alt={i.altText}></img>
                  :
                  <svg width="28px" height="28px" fill="#333" className={`icon-entry`}>
                    <use xlinkHref={getIconUrl(i.iconKey)}></use>
                  </svg>}
              </li>)
            })}
          </ul>
          <div style={{ height: '4px', borderBottom: '1px solid #cfcfcf', borderTop: '1px solid #cfcfcf' }} />
        </>}

        <div className="scroller" style={{ height: icons.length > 0 ? '300px' : '420px' }}>
          {activeMenuIcons.length > 0 && <>
            <p className="icon-section-label">
              This Menu
            </p>
            <ul>
              {activeMenuIcons.map((i) => {
                return (<li key={i.path || i.iconKey} onClick={() => setMenuIcon(i)}>
                  {i.path ?
                    <img className="icon-entry" src={getUploadedIconUrl(i)} alt={i.altText}></img>
                    :
                    <svg width="28px" height="28px" fill="#333" className={`icon-entry`}>
                      <use xlinkHref={getIconUrl(i.iconKey)}></use>
                    </svg>}
                </li>)
              })}
            </ul>
          </>}

          <p style={{ marginTop: '15px' }}><b>Icon Library</b></p>

          <p className="icon-section-label">Uploaded Icons</p>
          <ul style={{ marginBottom: '37px', rowGap: '31px' }}>
            <li className='upload-btn'>
              {!uploadLoading && <Upload beforeUpload={beforeUpload} showUploadList={false} accept={ALLOWED_IMAGE_TYPES}>
                +
              </Upload>}
              {uploadLoading && <LoadingOutlined />}
            </li>
            {memberIcons.map((i => {
              return (<li onClick={() => setMenuIcon({ type: getExtensionFromPath(i.path), iconKey: i.path, altText: i.altText, path: i.path, hoverText: '', source: i.source } as MenuItemIcon)}>
                <img className="icon-entry" src={getUploadedIconUrl(i)} alt={i.altText}>
                </img>
                <div className="edit-icon" onClick={(ev) => onADAClick(ev, i)}>
                  <EditOutlined />
                </div>
              </li>)
            }))}
          </ul>

          {menuIconsList(menuIconsGeneral, 'General')}
          {menuIconsList(menuIconsDietary, 'Dietary Restrictions')}
          {menuIconsList(menuIconsGlutenWheat, 'Gluten & Wheat')}
          {menuIconsList(menuIconsNutsSeeds, 'Nuts & Seeds')}
          {menuIconsList(menuIconsSeafood, 'Seafood')}
          {menuIconsList(menuIconsEggsDairy, 'Eggs & Dairy')}
          {menuIconsList(menuIconsCornSoy, 'Corn & Soy')}
          {menuIconsList(menuIconsKeto, 'Keto & Low Carb')}
          {menuIconsList(menuIconsMiscAllergens, 'Miscellaneous Allergens')}
          {menuIconsList(menuIconsHappyHour, 'Happy Hour')}
        </div>
      </div>
    </Modal>
    {showFileTooBigModal && <FileTooBigModal onOk={() => setShowFileTooBigModal(false)} maxSize={MAX_IMAGE_SIZE} />}
    {showFileInvalidModal && <FileInvalidTypeModal onOk={() => setShowFileInvalidModal(false)} />}
  </>);
};
