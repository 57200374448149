import * as React from "react";
import "./UrlChangeConfirmModal.scss";
import {Button, Modal} from "antd";

interface UrlChangeConfirmModalProps {
  isVisible: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

export default function UrlChangeConfirmModal({isVisible, onCancel, onSuccess}: UrlChangeConfirmModalProps) {
  return (
    <Modal className={'url-change-confirm-modal no-footer-modal'} visible={isVisible} onCancel={onCancel}>
      <h2>Business Info Updated</h2>
      <p>This site is currently published.</p>
      <p>Changes to your business name, city or state will change the web link for your site</p>
      <footer>
        <Button className={'btn-med btn-cancel'} onClick={onCancel}>Cancel</Button>
        <Button className={'btn-med'} type={'primary'} onClick={onSuccess}>Update Site Link</Button>
      </footer>
    </Modal>
  );
}
