import * as React from 'react';
import './LinkSchedule.scss';
import moment, {Moment} from "moment";
import {Button, DatePicker} from "antd";

interface LinkScheduleProps {
  value?: Moment,
  label: string,
  onClear: () => void;
  onSelect: (t: Moment) => void;
}

function LinkSchedule({value, label, onClear, onSelect}: LinkScheduleProps) {
  return <div className={'time-wrapper'}>
    <div className={'time-actions'}>
      <span>{label}</span>
      <Button type={'link'} onClick={onClear}>Clear</Button>
    </div>
    <div className={'timepicker'}>
      <DatePicker dropdownClassName={'picker-dropdown'} inputReadOnly placeholder={'Select time'} allowClear={false} size={'large'} suffixIcon={null} value={value} disabledDate={(m: Moment) => m.clone().add(1, 'day').isBefore(moment.now()) }
                  showTime format={'MM/DD/YYYY HH:mm'} minuteStep={10} onSelect={onSelect}/>

    </div>
  </div>
}

export default LinkSchedule;
