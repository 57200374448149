export class DayInterval {
  start: string;
  end: string;

  constructor(start: string, end: string) {
    this.start = start;
    this.end = end;
  }
}

export class Day {
  open: boolean;
  hours: DayInterval[];
}

export interface BusinessHoursDto {
  monday: Day;
  tuesday: Day;
  wednesday: Day;
  thursday: Day;
  friday: Day;
  saturday: Day;
  sunday: Day;
}



export const WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export function getDayOfWeek(dof: number = new Date().getDay()): string {
  return dof > 0 ? WEEK[dof - 1] : WEEK[6];
}
