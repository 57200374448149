import * as React from "react";
import { BusinessDto } from "../../gen/client";
import "./ShareBusiness.scss";
import { Button } from "antd";
import {getBusinessPageUrl, openInNewTab} from "../../util/Utils";
import { useEffect, useState } from "react";
import { LinkOutlined, QrcodeOutlined } from "@ant-design/icons/lib/icons";
import {copyBusinessUrl} from "../../services/BusinessService";

interface BusinessUrlProps {
  business: BusinessDto;
  showShareModal: () => void;
}

export default function ShareBusiness({ business, showShareModal }: BusinessUrlProps) {
  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(getBusinessPageUrl(business) || "");
  }, [business]);

  const handleUrlClick = () => {
    openInNewTab(url);
  }

  return (
    <div className={"share-biz"}>
      <header>
        <h2>{"Share your Business Website"}</h2>
        <h4>{"shareYourWebsiteCta"}</h4>
      </header>

      <div className={"data"}>
        <div className={"lk"}>
          <div>{`${"Link"}:`}&nbsp;</div>
          <Button type={'link'} onClick={handleUrlClick} className={"url"}>{url}</Button>
        </div>
          <Button className={"copy-link-button"} icon={<LinkOutlined />} onClick={() => copyBusinessUrl(business)}>
            {"Copy Link"}
          </Button>
      </div>

      <footer>
        <Button icon={<QrcodeOutlined />} className={"right"} size={"large"}>
          {"qrCreate"}
        </Button>
        <Button className={"right"} size={"large"} type={"primary"} onClick={showShareModal}>
          {"Done"}
        </Button>
      </footer>
    </div>
  );
}
