import * as React from 'react';
import {CustomerDto, BusinessDto} from "../../../gen/client";
import {Button, Form, Input, Modal} from "antd";
import './AddCustomerModal.scss';
import CustomerApi from "../../../api/CustomerApi";
import {AxiosError} from "axios";
import {handleServerError} from "../../../util/ErrorHandler";
import {useState} from "react";

interface AddCustomerModalProps {
  isVisible: boolean;
  business: BusinessDto;
  onCancel: () => void;
  onSuccess: (dto: CustomerDto) => void;
}

export default function AddCustomerModal({isVisible, business, onCancel, onSuccess}: AddCustomerModalProps) {

  const [loading, setLoading] = useState(false);

  function onFinish(values: any) {
    setLoading(true);
    CustomerApi.createCustomer({...values, businessId: business.id}).then(resp => {
      onSuccess(resp.data);
      setLoading(false);
    }).catch((err: AxiosError) => {
      setLoading(false);
      handleServerError(err);
    });
  }

  return (
    <Modal className={`add-customer-modal`} visible={isVisible} onCancel={onCancel}>
      <header>
        <h2>{'Add customer'}</h2>
      </header>

      <main>
        <Form name="basic" onFinish={onFinish}>
          <Form.Item label={'Name'} name="name" rules={[{required: true, message: 'This field is mandatory'}]}>
            <Input size={'large'}/>
          </Form.Item>

          <Form.Item label={'Phone'} name="phone" rules={[{required: true, message: 'This field is mandatory'}]}>
            <Input size={'large'}/>
          </Form.Item>

          <Form.Item label={'Email'} name="email">
            <Input size={'large'}/>
          </Form.Item>

          <Form.Item label={'Address'} name="address">
            <Input size={'large'}/>
          </Form.Item>

          <Form.Item label={'city'} name="city">
            <Input size={'large'}/>
          </Form.Item>

          <Form.Item label={'state'} name="state">
            <Input size={'large'}/>
          </Form.Item>

          <Form.Item label={'zip'} name="zip">
            <Input size={'large'}/>
          </Form.Item>

          <footer>
            <Button size={'large'} type={'primary'} htmlType="submit" loading={loading} disabled={loading}>{'Save'}</Button>
          </footer>
        </Form>
      </main>
    </Modal>
  );
};
