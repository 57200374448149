import * as React from "react";
import {Card, Form, Input, Switch} from "antd";
import {BusinessCfg, BusinessDto} from "../../../gen/client";
import "./Ordering.scss";

interface SettingsOrderingProps {
  business: BusinessDto;
  currency: string;
  onConfigChange: (patch: Partial<BusinessCfg>) => void;
}

export default function Ordering({business, onConfigChange}: SettingsOrderingProps) {
  return (
    <div className={"split rs-set"}>
      <div>
        <Card>
          <header className={"c"}>
            <h3>{"Orders in Advance"}</h3>
            <h5>{"Allow orders in advance"}</h5>
          </header>
          <div>
            <Switch defaultChecked={business.config.ordersInAdvance} onChange={() => onConfigChange({ordersInAdvance: !business.config.ordersInAdvance})}/>
            <label style={{marginLeft: "5px"}}>
              {"Enable in advance"}
            </label>
          </div>
        </Card>

        <Card>
          <header className={"c"}>
            <h3>{"Order Tips"}</h3>
            <h5>{"Allow tips for order"}</h5>
          </header>
          <div>
            <Switch defaultChecked={business.config.ordersTips} onChange={() => onConfigChange({ordersTips: !business.config.ordersTips})}/>
            <label style={{marginLeft: "5px"}}>{"Enable tips"}</label>
          </div>
        </Card>

        <Card>
          <header className={"c"}>
            <h3>{"minOrder"}</h3>
            <h5>{"hasMinOrderExplain"}</h5>
          </header>
          <Form.Item name="minTotal">
            <Input size="large" placeholder={"minOrderDetails"} defaultValue={business.config.minTotal}/>
          </Form.Item>
        </Card>
        <Card>
          <header className={"c"}>
            <h3>{"Deliver to Table"}</h3>
            <h5>{"Allow delivery to table"}</h5>
          </header>
          <div>
            <div style={{marginTop: "15px"}}>
              <Switch
                defaultChecked={business.config.orderDeliveryToTable}
                onChange={() => onConfigChange({orderDeliveryToTable: !business.config.orderDeliveryToTable})}
              />
              <label style={{marginLeft: "5px"}}>
                {"Enable delivery to table"}
              </label>
            </div>
          </div>
        </Card>
      </div>

      <div>
        <Card>
          <header className={"c"}>
            <h3>{"Pick up"}</h3>
            <h5>{"Allow pick up"}</h5>
          </header>
          <div>
            <Switch defaultChecked={business.config.pickUpOrders} onChange={() => onConfigChange({pickUpOrders: !business.config.pickUpOrders})}/>
            <label style={{marginLeft: "5px"}}>{"Enable pick up"}</label>
          </div>

          <header className={"mg-tp c"}>
            <h3>{"pickUpTime"}</h3>
            <h5>{"hasPickUpTimeExplain"}</h5>
          </header>
          <Input min="0" size="large" placeholder={"pickupTimeDetails"} type="number" value={business.config.pickUpTime} onChange={(ev) => onConfigChange({pickUpTime: ev.target.value ? parseInt(ev.target.value) : 0})}
          />
        </Card>

        <Card>
          <header className={"c"}>
            <h3>{"Order Delivery"}</h3>
            <h5>{"Allow delivery"}</h5>
          </header>
          <div>
            <label>{"deliveryFee"}</label>
            <Form.Item name="deliveryFee" style={{margin: 0}}>
              <Input size="large" placeholder={"deliveryFee"} defaultValue={business.config.deliveryFee}/>
            </Form.Item>

            <div style={{marginTop: "15px"}}>
              <Switch defaultChecked={business.config.orderDelivery} onChange={() => onConfigChange({orderDelivery: !business.config.orderDelivery})}/>
              <label style={{marginLeft: "5px"}}>
                {"Enable delivery"}
              </label>
            </div>
          </div>

          <header className={"mg-tp c"}>
            <h3>{"deliveryTime"}</h3>
            <h5>{"hasDeliveryTimeExplain"}</h5>
          </header>
          <Input size="large" type="number" placeholder={"deliveryTimeDetails"} value={business.config.deliveryTime} onChange={(ev) => onConfigChange({deliveryTime: ev.target.value ? parseInt(ev.target.value) : 0})}
          />
        </Card>
      </div>
    </div>
  );
}
