import * as React from 'react';
import {Modal} from "antd";
import './DeleteLastMenuModal.scss';

interface DeleteLastMenuModalProps {
  onConfirm: (e: React.MouseEvent) => void;
  visible: boolean;
}

export default function DeleteLastMenuModal({onConfirm, visible}: DeleteLastMenuModalProps) {
  return (
    <Modal okText={'OK'} cancelText={null} visible={visible} onOk={onConfirm} onCancel={onConfirm} className={'last-menu-modal'}>
      <h2>
        To remove all menus, site must be unpublished.
      </h2>
    </Modal>
  );
};
