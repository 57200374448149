export function now() {
  return new Date().getTime();
}

export function secondsSince(millis: number) {
  return Math.floor((now() - millis) / 1000);
}

export function minsAsMillis(mins: number) {
  return mins * 60 * 1000;
}
