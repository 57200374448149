import React from 'react';
import DragHandleIcon, {DragHandlePosition, DragHandleSize} from './icons/DragHandleIcon';

interface DragHandleProps {
  listeners: any;
  attributes: any;
  position: DragHandlePosition;
  size: DragHandleSize;
}

export function DragHandle({listeners, attributes, position, size}: DragHandleProps) {
  return <DragHandleIcon attributes={attributes} listeners={listeners} position={position} size={size} visible={true} />;
}