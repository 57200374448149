import * as React from 'react';

export default function StopIcon() {
  return <svg id="no-logo" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <g id="Group_280" data-name="Group 280" transform="translate(-980 -385)">
      <g id="Ellipse_7" data-name="Ellipse 7" transform="translate(980 385)" fill="#fff" stroke="#777" strokeWidth="2">
        <circle cx="11" cy="11" r="11" stroke="none" />
        <circle cx="11" cy="11" r="10" fill="none" />
      </g>
      <line id="Line_35" data-name="Line 35" x1="15" y2="15" transform="translate(983.5 388.5)" fill="none" stroke="#888" strokeWidth="2" />
    </g>
  </svg>;
}
