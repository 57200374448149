import {Button, Form, Input, Modal} from "antd";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import './EditLocationModal.scss';
import StateDropdown from "../../misc/StateDropdown";
import CountryDropdown from "../../misc/CountryDropdown";
import {UnitedStates} from "../../../util/countries";
import PhoneInput from "../../misc/PhoneInput";
import FacebookIcon from "../../misc/icons/FacebookIcon";
import InstagramIcon from "../../misc/icons/InstagramIcon";
import WebsiteIcon from "../../misc/icons/WebsiteIcon";
import TwitterIcon from "../../misc/icons/TwitterIcon";
import EmailIcon from "../../misc/icons/EmailIcon";
import {handleServerError} from "../../../util/ErrorHandler";
import Loader from "../../misc/Loader";
import {LocationDto} from "../../../gen/client";
import {BusinessApi} from "../../../api/BusinessApi";
import { AutocompleteGoogleSearch } from "./AutocompleteGoogleSearch";
import {PLACEHOLDER_EMAIL, PLACEHOLDER_FACEBOOK, PLACEHOLDER_INSTAGRAM, PLACEHOLDER_TWITTER, PLACEHOLDER_WEBSITE} from "../../../util/constants";

interface EditLocationModalProps {
  memberId: number;
  locationId: number;
  visible: boolean;
  onClose: () => void;
  onSave: (location: LocationDto) => void;
  prePublish?: boolean;
}

export default function EditLocationModal({visible, onClose, onSave, memberId, locationId, prePublish}: EditLocationModalProps) {
  const [loading, setLoading] = useState(true);

  const [form] = Form.useForm();

  const [location, setLocation] = useState(null as LocationDto);

  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');

  function onPhoneChange(phone: string) {
    setPhone(phone);
    form.setFields([{name: 'phone', value: phone}]);
  }

  function onCountryChange(country: string) {
    setCountry(country);
    form.setFields([{name: 'country', value: country}]);
  }

  function onStateChange(state: string) {
    form.setFields([{name: 'state', value: state}]);
  }

  const fetchLocation = useCallback(() => {
    if (!locationId) return;

    setLoading(true);
    BusinessApi.getLocation(locationId).then(resp => {
      setLocation(resp.data);
      let locationCountry = resp.data.country;
      if (locationCountry === 'United States') {
        locationCountry = UnitedStates;
      }

      setCountry(locationCountry);
      setPhone(resp.data.phone);

      form.setFieldsValue(resp.data);

      setLoading(false);
    }).catch(e => {
      handleServerError(e);
      setLoading(false);
    });
  }, [locationId, form]);

  useEffect(() => {
    if (visible) {
      fetchLocation();
    }
  }, [visible, fetchLocation]);

  function validate(values: any) {
    if (!memberId || !locationId) return;

    setLoading(true);
    BusinessApi.updateLocation(locationId, {...location, ...values}).then(resp => {
      setLoading(false);
      onSave(resp.data);
    }).catch(e => {
      handleServerError(e);
      setLoading(false);
    });
  }

  function updateAddress(value?: string, addressComponents?: any[]) {
    if (addressComponents && addressComponents.length > 0) {
      addressComponents.forEach((addressComponent) => {
        if (addressComponent.types.find((type: string) => type === "country")) {
          const countryName =
            addressComponent.long_name === "United States"
              ? UnitedStates
              : addressComponent.long_name;
          setCountry(countryName);
          form.setFields([{name: 'country', value: countryName}]);
        }
        if (
          addressComponent.types.find(
            (type: string) => type === "administrative_area_level_1"
          )
        ) {
          form.setFields([{name: 'state', value: addressComponent.long_name}]);
        }
        if (
          addressComponent.types.find((type: string) => type === "locality")
        ) {
          form.setFields([{name: 'city', value: addressComponent.long_name}]);
        }
        if (addressComponent.types.find((type: string) => type === "route")) {
          const streetNo =
            addressComponents[0].types[0] === "street_number"
              ? addressComponents[0].long_name
              : "";
          const streetAddr = streetNo !== ""
          ? `${streetNo} ${addressComponent.long_name}`
          : addressComponent.long_name;
          console.log("streetAddr ", streetAddr);
          form.setFields([{name: 'address', value: streetAddr}]);
        }
        if (
          addressComponent.types.find((type: string) => type === "postal_code")
        ) {
          form.setFields([{name: 'zip', value: addressComponent.long_name}]);
        }
      });
    } else {
      form.setFields([{name: 'address', value: value || ""}]);
    }
  }

  function updateAddress2(value?: string, addressComponents?: any[]) {
    form.setFields([{name: 'address2', value: value || ""}]);
  }

  return <Modal wrapClassName={'edit-location-modal'} footer={null} title={prePublish ? 'Almost Ready!' : 'Update Location'} visible={visible} onCancel={onClose}
                closable={true} onOk={validate}
                width={'700px'}>

    {prePublish && <header>Please fill out the required business information before publishing.</header>}
    {!prePublish && <header>Note: Changes will reflect on any Link Pages or Online Menus for this location.</header>}

    {loading && <Loader/>}

    {!loading && <>

        <Form layout={"horizontal"} className={`form`} initialValues={{remember: true}} onFinish={validate} form={form}>
            <section>
                <div className={'section-header'}>Business</div>
                <div className={'split'}>
                    <div className={'half'}>
                        <Form.Item name={'name'} rules={[{required: true, message: 'Business name cannot be empty.'}]}>
                            <Input type={'text'} placeholder={'Business name'} maxLength={40} size={'large'} tabIndex={1}/>
                        </Form.Item>
                    </div>
                    <div className={'half'}/>
                </div>
            </section>

            <section>
                <div className={'section-header'}>Location</div>
                <div className={'split'}>
                    <div className={'half'}>
                        <Form.Item name={'address'} rules={[{required: true, message: 'Address cannot be empty.'}]}>
                          <AutocompleteGoogleSearch
                            tabIndex={2}
                            fieldValue={form.getFieldValue("address")}
                            placeholder={"Street Address"}
                            updateField={(value, addressComponents) =>
                              updateAddress(value, addressComponents)
                            }
                            />
                        </Form.Item>
                        <Form.Item name={'city'} rules={[{required: true, message: 'City cannot be empty.'}]}>
                            <Input type={'text'} placeholder={'City'} size={'large'} tabIndex={4}/>
                        </Form.Item>
                        <CountryDropdown onChange={onCountryChange} tabIndex={6} light/>
                    </div>
                    <div className={'half'}>
                        <Form.Item name={'address2'}>
                          <AutocompleteGoogleSearch
                            tabIndex={3}
                            fieldValue={form.getFieldValue("address2")}
                            placeholder={"Street Address Line 2"}
                            updateField={updateAddress2}
                          />
                        </Form.Item>
                      {country === UnitedStates && <StateDropdown onChange={onStateChange} tabIndex={5} light/>}
                      {country !== UnitedStates && <Form.Item name={'state'} rules={[{required: true, message: 'State/Province cannot be empty.'}]}>
                          <Input type={'text'} placeholder={'State/Province'} size={'large'} tabIndex={5}/>
                      </Form.Item>}
                        <Form.Item name={'zip'} rules={[{required: true, message: 'Zip cannot be empty.'}]}>
                            <Input type={'text'} placeholder={'Zip/Postal Code'} size={'large'} tabIndex={7}/>
                        </Form.Item>
                    </div>
                </div>
            </section>

            <section>
                <div className={'section-header'}>Contact Links</div>
                <div className={'split'}>
                    <div className={'half'}>
                        <Form.Item name={'website'}>
                            <Input tabIndex={8} addonBefore={<WebsiteIcon/>} placeholder={PLACEHOLDER_WEBSITE} size="large"/>
                        </Form.Item>
                        <Form.Item name={'email'}>
                            <Input tabIndex={10} addonBefore={<EmailIcon/>} placeholder={PLACEHOLDER_EMAIL} size="large"/>
                        </Form.Item>
                        <Form.Item name={'twitter'}>
                            <Input tabIndex={12} addonBefore={<TwitterIcon/>} placeholder={PLACEHOLDER_TWITTER} size="large"/>
                        </Form.Item>
                    </div>
                    <div className={'half'}>
                        <Form.Item name={'phone'}>
                            <PhoneInput tabIndex={9} value={phone} onChange={onPhoneChange} country={country} showAddon/>
                        </Form.Item>
                        <Form.Item name={'facebook'}>
                            <Input tabIndex={11} addonBefore={<FacebookIcon/>} placeholder={PLACEHOLDER_FACEBOOK} size="large"/>
                        </Form.Item>
                        <Form.Item name={'instagram'}>
                            <Input tabIndex={13} addonBefore={<InstagramIcon/>} placeholder={PLACEHOLDER_INSTAGRAM} size="large"/>
                        </Form.Item>
                    </div>
                </div>
            </section>

            <section>
                <div className={'split footer-mobile'}>
                    <div className={'half right'}>
                        <Form.Item>
                            <Button onClick={onClose}>Cancel</Button>
                        </Form.Item>
                    </div>
                    <div className={'half'}>
                        <Form.Item>
                            <Button type={'primary'} htmlType={"submit"}>Save</Button>
                        </Form.Item>
                    </div>
                </div>
            </section>
        </Form>
    </>}
  </Modal>;
}
