import * as React from 'react';
import './Loader.scss';

export interface LoaderProps {
  small?: boolean
}

export default function Loader({small}: LoaderProps) {
  return <div className={`loader ${small ? 'small' : ''}`}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>;
};

