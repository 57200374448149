import * as React from 'react';
import {RatingDto, BusinessDto} from "../../../gen/client";
import {Skeleton, Switch} from "antd";
import {BusinessApi} from "../../../api/BusinessApi";
import {useState} from "react";
import {useEffect} from "react";
import {AxiosError} from "axios";
import {handleServerError} from "../../../util/ErrorHandler";
import styles from './Reviews.module.scss';
import ReviewEntry from "./ReviewEntry";
import Stars from "../../misc/Stars";
import {getBusinessRating} from "../../../services/BusinessService";
import update from 'immutability-helper';

interface DashboardRatingsProps {
  business: BusinessDto;
  onBusinessUpdate: (business: BusinessDto) => void;
}

export default function Reviews({business, onBusinessUpdate}: DashboardRatingsProps) {

  const [rating, setRating] = useState(0);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [ratings, setRatings] = useState([] as RatingDto[]);

  useEffect(() => {
    if (!business.id) return;

    setLoading(true);
    BusinessApi.getRatings(business.id).then(resp => {
      setRatings(resp.data);
      setLoading(false);
      setTotal(resp.data.length);
      setRating(getBusinessRating(resp.data));
    }).catch((err: AxiosError) => {
      setLoading(false);
      handleServerError(err);
    });
  }, [business.id]);

  function toggleActive(active: boolean) {
    onBusinessUpdate({config: {allowFeedback: active}});
  }

  function onRemove(rating: RatingDto) {
    const idx = ratings.findIndex(it => it.id === rating.id);
    if (idx > -1) {
      const updated = update(ratings, {$splice: [[idx, 1]]});
      setRatings(updated);
    }
  }

  function onAccepted(rating: RatingDto) {
    const idx = ratings.findIndex(it => it.id === rating.id);
    if (idx > -1) {
      const updated = update(ratings, {
        [idx]: item => ({...item, approved: true})
      });
      setRatings(updated);
    }
  }

  return (<div className={styles.ratings}>
    <header>
      <div>
        <h2>{'Customer reviews'}</h2>
        <div className={styles.check}>
          <Switch defaultChecked={business.config.allowFeedback} size={"small"} onChange={toggleActive} className={'scc'}/>
          <label>{'Allow customer reviews'}</label>
        </div>
      </div>
      {total > 0 && <div className={styles.stars}>
        <Stars score={rating} visibleScore={true} size={'large'}/>
        <h3>{total} {'Reviews'}</h3>
      </div>}
    </header>
    {loading && <Skeleton active/>}

    {!loading && <section className={styles.list}>
      <header>
        <ul>
          <li>{'Name'}</li>
          <li>{'Message'}</li>
          <li>{'Rating'}</li>
          <li>{'Since'}</li>
          <li/>
        </ul>
      </header>

      {ratings.length === 0 && <p>{'No reviews yet'}</p>}

      <ul>
        {ratings.map(it =>
          <li key={it.id}>
            <ReviewEntry rating={it} businessId={business.id} onRemove={onRemove} onAccepted={onAccepted}/>
          </li>)}
      </ul>
    </section>}
  </div>)
};
