import React, {useRef, useState} from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {MenuItemIcon, MenuItemModifier, MenuNode} from '../../../domain/Menu';
import MenuItem from './MenuItem';
import {BusinessDto, MemberIconDto, MemberImageDto} from '../../../gen/client';
import './MenuItemSortable.scss';
import {DND_TRANSITION} from '../../../util/constants';
import {DragHandle} from '../../misc/DragHandle';
import {MenuItemModifierDetail} from '../../../util/types';
import {scrollToElement} from '../../../util/Utils';

interface MenuItemSortableProps {
  item: MenuNode;
  sortable: boolean;
  business: BusinessDto;
  currency: string;
  sectionId: string;
  dragging: boolean;
  modifiers: MenuItemModifierDetail[];
  showDragHandle: boolean;
  scrollToBottom: boolean;
  onModifierRemovedGlobal: (m: MenuItemModifier) => void;
  onItemUpdate: (sectionId: string, itemId: string, patch: Partial<MenuNode>) => void;
  onItemRemove: (sectionId: string, itemId: string) => void;
  onCurrencyChange: (currency: string) => void;
  editMode?: boolean;
  onClick: () => void;
  onCancel: () => void;
  memberImages: MemberImageDto[];
  setMemberImages: (images: MemberImageDto[]) => void;
  memberIcons: MemberIconDto[];
  setMemberIcons: (icons: MemberIconDto[]) => void;
  activeMenuIcons: MenuItemIcon[];
  updateMenuActiveIcons: () => void;
  setSelectedMemberImage: (image: MemberImageDto | MemberIconDto) => void;
  setAltTextModal: (visible: boolean) => void;
}

export function MenuItemSortable({
                                   item, business, currency, sectionId, sortable, dragging, modifiers, showDragHandle, scrollToBottom, onItemRemove, onItemUpdate, onCurrencyChange,
                                   editMode, onModifierRemovedGlobal, onClick, onCancel, memberImages, setMemberImages, memberIcons, setMemberIcons, activeMenuIcons, updateMenuActiveIcons, setSelectedMemberImage, setAltTextModal
                                 }: MenuItemSortableProps) {
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
    id: item.id,
    disabled: !sortable,
    transition: DND_TRANSITION
  });
  const style = {transform: CSS.Transform.toString(transform), transition};

  const bottomEl = useRef(null);
  const [scrolled, setScrolled] = useState(false);

  function handleBottomEl(el: HTMLDivElement) {
    bottomEl.current = el;
    if (!scrolled && scrollToBottom) {
      scrollToElement(bottomEl.current);
      setScrolled(true);
    }
  }

  return (
    <div ref={setNodeRef} style={style} className={`sortable-menu-item-container ${!item.available ? 'unavailable' : ''}`}>
      <div ref={handleBottomEl}/>
      {sortable && showDragHandle && <DragHandle attributes={attributes} listeners={listeners} position={'left-top'} size={'large'}/>}

      <MenuItem key={item.id} item={item} business={business} currency={currency} sectionId={sectionId} modifiers={modifiers}
                onModifierRemovedGlobal={onModifierRemovedGlobal} onItemRemove={onItemRemove} onItemUpdate={(p) => onItemUpdate(sectionId, item.id, p)}
                onCurrencyChange={onCurrencyChange} editMode={editMode} onClick={onClick} onCancel={onCancel} 
                memberImages={memberImages} setMemberImages={setMemberImages}
                memberIcons={memberIcons} setMemberIcons={setMemberIcons} activeMenuIcons={activeMenuIcons} updateMenuActiveIcons={updateMenuActiveIcons} setSelectedMemberImage={setSelectedMemberImage} setAltTextModal={setAltTextModal}/>
    </div>
  );
}
