import {
  EMPTY_SCHEDULE,
  EmptyBusiness,
  HOUR_FORMAT_24,
  HOUR_FORMAT_24_NEXT_OPEN,
  HOUR_FORMAT_24_NEXT_OPEN_SAME_DAY,
  HOUR_FORMAT_AM_PM,
  HOUR_FORMAT_AM_PM_NEXT_OPEN,
  HOUR_FORMAT_AM_PM_NEXT_OPEN_SAME_DAY, MONTHS
} from '../util/constants';
import {BusinessDto, FullRevisionDto, LocationDto, RatingDto} from "../gen/client";
import {BusinessHoursDto, Day, getDayOfWeek} from "../domain/BusinessHours";
import moment from "moment";
import {MenuNode} from "../domain/Menu";
import {clipboardCopy, fromTimestamp, getBusinessPageUrl, trailingZero} from '../util/Utils';
import {message} from 'antd';
import {ColorPair} from "../util/types";
import {AnnouncementBanner} from "../domain/AnnouncementBanner";
import {BusinessHoursCalculator} from "../util/BusinessHoursCalculator";

export function canAddItemToCart(business: BusinessDto, node: MenuNode) {
  return business.orderingEnabled && node.available && (isBusinessOpen(business) || business.config.ordersInAdvance);
}

export function hasBusinessHours(restaurant: BusinessDto): boolean {
  return restaurant?.config?.hours && restaurant?.config?.hours !== "{}";
}

export function parseHours(hours: string): BusinessHoursDto {
  try {
    return JSON.parse(hours);
  } catch (ex) {
    return {} as BusinessHoursDto;
  }
}

export function parseColorPairs(colors: string): ColorPair[] {
  try {
    return JSON.parse(colors);
  } catch (ex) {
    return [] as ColorPair[];
  }
}

function getBusinessHoursConfig(business: BusinessDto): BusinessHoursCalculator {
  const parsedHours = parseHours(business.config.hours);

  return new BusinessHoursCalculator(parsedHours);
}

export function isBusinessOpen(business: BusinessDto): boolean {
  return getBusinessHoursConfig(business).isOpen();
}

export function getIntervalIndexForNow(dayTimes: Day, checkNextDay: boolean = false) {
  const now = moment();
  return dayTimes.hours
    .sort((a, b) => a.start.localeCompare(b.start))
    .findIndex(it => {
      const start = moment(it.start, HOUR_FORMAT_24);
      const end = moment(it.end, HOUR_FORMAT_24);
      if (!checkNextDay) {
        return start.isBefore(now) && now.isBefore(end);
      } else {
        return (end.isBefore(start) && now.isBefore(end)) || (start.isBefore(now) && now.isBefore(end));
      }
    });
}

export function getFirstClosingTime(business: BusinessDto) {
  const format = business.config.use24HourFormat ? HOUR_FORMAT_24_NEXT_OPEN_SAME_DAY : HOUR_FORMAT_AM_PM_NEXT_OPEN_SAME_DAY;
  return getBusinessHoursConfig(business).nextClosingTime(format);
}

export function getNextOpenTime(business: BusinessDto) {
  const format = business.config.use24HourFormat ? HOUR_FORMAT_24_NEXT_OPEN_SAME_DAY : HOUR_FORMAT_AM_PM_NEXT_OPEN_SAME_DAY;
  const nextDayFormat = business.config.use24HourFormat ? HOUR_FORMAT_24_NEXT_OPEN : HOUR_FORMAT_AM_PM_NEXT_OPEN;

  return getBusinessHoursConfig(business).nextOpenTime(format, nextDayFormat);
}

export function hasHoursConfigured(business: BusinessDto) {
  if (!hasBusinessHours(business)) return false;

  const hours = parseHours(business.config.hours);

  return hours && !!Object.values(hours).find(it => it.open);
}

export function isRestaurantDayAvailable(hours: BusinessHoursDto, dof: number) {
  const day = getDayOfWeek(dof) as keyof BusinessHoursDto;
  const today = hours[day];
  return today && today.open;
}

export function getRestaurantDayTimes(
  hours: BusinessHoursDto,
  dof: number
): string[] {
  const day = getDayOfWeek(dof) as keyof BusinessHoursDto;
  const today = hours[day];

  const list = [] as string[];

  today.hours.forEach(({start, end}) => {
    let [hour, minute] = start.split(":").map((it) => parseInt(it));
    let [endHour, endMinute] = end.split(":").map((it) => parseInt(it));

    while (hour * 100 + minute < endHour * 100 + endMinute) {
      list.push(
        `${hour < 10 ? "0" + hour : hour}:${minute === 0 ? "00" : minute}`
      );
      minute += 30;
      if (minute >= 60) {
        minute = 0;
        hour += 1;
      }
    }
  });

  return list;
}

export function getOrderPickUpTimes(
  hours: BusinessHoursDto,
  pickUpTime: number
): string[] {
  const currentDay = moment();
  const day = getDayOfWeek(currentDay.day()) as keyof BusinessHoursDto;
  const today = hours[day];
  const list = ["ASAP"];

  today.hours.forEach(({start, end}) => {
    const [currentHour, currentMinute] = currentDay.format(HOUR_FORMAT_AM_PM).split(":").map((it: any) => parseInt(it));
    let [hour, minute] = start.split(":").map((it) => parseInt(it));
    let [endHour, endMinute] = end.split(":").map((it) => parseInt(it));
    //Add the estimated time to get lower boundry of pick up array
    hour += pickUpTime / 60;
    minute += pickUpTime % 60;
    if (minute >= 60) {
      hour += 1;
      minute -= 60;
    }
    if (currentMinute <= 30) {
      hour = currentHour + 1;
      minute = 0;
    } else {
      hour = currentHour + 2;
      minute = 0;
    }

    while (hour * 100 + minute < endHour * 100 + endMinute) {
      list.push(
        `${hour < 10 ? "0" + hour : hour}:${minute === 0 ? "00" : minute}`
      );
      minute += 30;
      if (minute >= 60) {
        minute = 0;
        hour += 1;
      }
    }
  });

  return list;
}

export function countBusinessNewRatings(ratings: RatingDto[]): number {
  if (!ratings || ratings.length === 0) return 0;
  return ratings.filter((it) => it.approved === false && it.removed === false)
    .length;
}

export function getBusinessRating(ratings: RatingDto[]): number {
  if (!ratings) return 0;

  const approved = ratings.filter((it) => it.approved);
  if (approved.length === 0) return 0;

  const sum = approved.map((it) => it.score).reduce((a, b) => a + b, 0);
  return Math.round((sum / approved.length) * 10) / 10;
}

export function businessIsEmpty(business: BusinessDto): boolean {
  return business === EmptyBusiness || !business.id;
}

export function copyBusinessUrl(business: BusinessDto) {
  clipboardCopy(getBusinessPageUrl(business));
  message.success('Copied to Clipboard!', 2);
}

export function hasValidBusiness(location: LocationDto) {
  if (!location.name || !location.city || !location.state) {
    return false;
  }
  return (!!location.name.length && !!location.city.length && !!location.state.length);
}

export function isBannerScheduled(cfg: AnnouncementBanner) {
  const schedule = getBannerSchedule(cfg);

  return !!schedule.start && !!schedule.end;
}

export function isBannerExpired(cfg: AnnouncementBanner) {
  const schedule = getBannerSchedule(cfg);

  return !schedule.start || !schedule.end || schedule.end.isBefore(moment());
}

export function getBannerSchedule(cfg: AnnouncementBanner) {
  if (cfg) {
    return {
      start: cfg.start ? fromTimestamp(cfg.start) : null,
      end: cfg.end ? fromTimestamp(cfg.end) : null
    }
  } else {
    return EMPTY_SCHEDULE;
  }
}

export function getDefaultBusinessId() {
  return localStorage.activeLocation || 'default';
}

export function getRevisionSavedDate(revision: FullRevisionDto) {
  if (revision && revision.createdDate) {
    const date = new Date(revision.createdDate);
    return `${date.getDate()} ${MONTHS[date.getMonth()]} at ${trailingZero(date.getHours())}:${trailingZero(date.getMinutes())}`;
  } else {
    return 'Unknown saved date';
  }
}
