import * as React from 'react';
import './CustomPickerHuePointer.scss';

export default function CustomPickerHuePointer() {
  return <div className={'pointer-hue'}>
    <svg>
      <rect id="box" x="0" y="0" width="50" height="10"/>
    </svg>
  </div>;
}
