import './Revisions.scss';
import {useCallback, useEffect, useState} from "react";
import {RevisionDto} from "../../gen/client";
import {BusinessApi} from "../../api/BusinessApi";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {isRestoringState, locationIdState, revisionState} from "../../store/atoms";
import {getRevisionSavedDate} from "../../services/BusinessService";
import Loader from "../misc/Loader";
import {handleServerError} from "../../util/ErrorHandler";
import {Button, message} from "antd";
import * as React from "react";
import {saveRevisionSubject} from "../../store/Subjects";

interface RevisionsProps {
  visible: boolean;
}

export default function Revisions({visible}: RevisionsProps) {
  const locationId = useRecoilValue(locationIdState);
  const [loading, setLoading] = useState(false);
  const [revisionLoading, setRevisionLoading] = useState(0);
  const [revisions, setRevisions] = useState([] as RevisionDto[]);
  const [revision, setRevision] = useRecoilState(revisionState);
  const setRestoring = useSetRecoilState(isRestoringState);

  const loadRevisions = useCallback(() => {
    if (!locationId) return;

    setLoading(true);
    BusinessApi.getRevisions(locationId).then(resp => {
      setLoading(false);
      setRevisions(resp.data.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()));
    }).catch(e => {
      handleServerError(e);
      setLoading(false);
    });
  }, [locationId]);

  useEffect(() => {
    if (visible) {
      loadRevisions();
    } else {
      setRevision({});
    }
  }, [visible, loadRevisions, setRevision]);

  const applyRevision = useCallback((revisionId: number) => {
    if (!locationId) return;

    setRestoring(true);
    setLoading(true);
    BusinessApi.applyRevision(locationId, revisionId).then(() => {
      window.location.reload();
    }).catch(e => {
      handleServerError(e);
      setLoading(false);
      message.error('Could not apply revision.', 5).then();
    });
  }, [locationId, setRestoring]);

  useEffect(() => {
    const revisionObs = saveRevisionSubject.subscribe((rev) => {
      if (rev.id) {
        applyRevision(rev.id);
      }
    });
    return () => {
      revisionObs.unsubscribe();
    };
  }, [applyRevision]);

  function fetchRevision(revisionId: number) {
    setRevisionLoading(revisionId);
    BusinessApi.getRevision(locationId, revisionId).then((resp) => {
      setRevision(resp.data);
      setRevisionLoading(0);
    }).catch(e => {
      handleServerError(e);
      setRevisionLoading(0);
    });
  }

  return <div className={'revisions'}>
    {loading && <Loader/>}

    {!loading && <>
      {!revisions.length && <span>No saved versions</span>}

      {!!revisions.length && <ul>
        {revisions.map(it => <li key={it.id}>
          <span className={`details ${revision.id === it.id ? 'selected': ''}`}>Saved {getRevisionSavedDate(it)}</span>
          <div className={'actions'}>
            {revisionLoading === it.id && <Loader small/>}

            {revisionLoading !== it.id && <>
              {revision.id !== it.id && <Button type={'primary'} onClick={() => fetchRevision(it.id)}>Preview</Button>}

              {/*{revision.id === it.id && <Popconfirm title={'Are you sure?'} okText={'Yes'} cancelText={'No'} onConfirm={() => applyRevision(it.id)}>*/}
              {/*    <Button>{'Restore'}</Button>*/}
              {/*</Popconfirm>}*/}
            </>}
          </div>
        </li>)}
      </ul>}
    </>}
  </div>
}
