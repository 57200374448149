import {AxiosPromise} from "axios";
import {ConnectedAccountDto, PaymentResourceApi} from "../gen/client";
import {apiConfig, ops} from "./ApiConfig";

const api = new PaymentResourceApi(apiConfig);

const paymentApi = {
  createPaymentIntentForOrder(orderId: string): AxiosPromise<string> {
    return api.createPaymentIntentForOrder(orderId, ops());
  },

  registerConnectedAccount(memberId: number): AxiosPromise<string> {
    return api.registerConnectedAccount(memberId, ops());
  },

  linkAccount(memberId: number): AxiosPromise<string> {
    return api.linkAccount(memberId, ops());
  },

  getConnectedAccount(memberId: number): AxiosPromise<ConnectedAccountDto> {
    return api.getConnectedAccount(memberId, ops());
  }
};

export default paymentApi;
