import {AxiosError} from "axios";
import {message} from "antd";
import {toLogin} from "./Utils";

const UNAUTHORIZED_CODE = 401;

export function handleServerError(err: AxiosError) {
  if (err.response?.data?.status === UNAUTHORIZED_CODE) {
    toLogin();
    return;
  }

  let msg = '';
  if (err.response) {
    msg = !!err.response ? err.response.data.message : err.response.data;
  }
  if (!msg) {
    msg = 'We failed to complete your last action. Please try again after a few seconds or contact us.'
  }

  message.error(msg, 3);
  console.error(err);
}
